import { getData } from './index';

export default function orders (
	state = {
		order: null,
		orderLocalVersion: 0,
		orders: null,
		ordersLocalVersion: 0,
		ordersError : null,
	}, action) {
	switch (action.type) {
		case 'ORDERS_LOADED':
			return {
				...state,
				orders: action.orders
			};
		case 'ORDERS_UPDATED':
			return {
				...state,
				orders: action.orders,
				ordersLocalVersion: state.ordersLocalVersion + 1
			};

		case 'ORDERS_ERROR': {
			return {
				...state,
				ordersError: action.orders
			};
		}
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getOrderLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "orders.orderLocalVersion", errorIfNotFound && "No order data found");
}
export function getOrders(state, errorIfNotFound = false) {
	return getData(state, "orders.orders", errorIfNotFound && "No orders data found");
}
export function getOrdersResults(state, errorIfNotFound = false) {
	return getData(state, "orders.orders.results", errorIfNotFound && "No orders data found");
}
export function getOrdersLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "orders.ordersLocalVersion", errorIfNotFound && "No orders data found");
}

export function getOrdersError(state, errorIfNotFound = false) {
	return getData(state, "orders.ordersError", errorIfNotFound && "No orders data found");
}