import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';

import CustomCard from '../../components/CustomCard';
import TemplateForm, {ToTemplateProperties} from './TemplateForm';

import { createTemplate, showTemplates } from '../../actions/templates';

const useStyles = makeStyles({
	root: {
		minWidth: '70vw',
		maxWidth: '95vw'
	},
	content: {
		minWidth: '60vw',
		maxHeight: '75vh',
		overflow: 'auto'
	}
});

const TemplateCreation = (props) => {
	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// save change
	const _createTemplate = async values => {
		const newValues = ToTemplateProperties(values);
		await dispatch(createTemplate(newValues));

		// show list
		showTemplates();
	};

	const _cancelAction = () => {
		dispatch(showTemplates());
	};


	const _submit = () => dispatch(submit('templateForm'));

	return (
		<CustomCard
			fullScreen
			title='Nouveau template '
			content={
				<TemplateForm onSubmit={_createTemplate} />
			}
			okLabel="Enregistrer"
			okAction={_submit}
			cancelAction={_cancelAction}
			contentClassName={classes.content} 
		/>
	)
}

export default TemplateCreation;