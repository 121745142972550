import React , {useEffect} from 'react';
import { reduxForm , formValueSelector , change } from 'redux-form';
import { pullAt } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';

import FormField from '../../../components/form/FormField';
import ReduxFormSelect from '../../../components/reduxFormMUIWrapper/ReduxFormSelect';
import NumberField from '../../../components/form/NumberField';
import DropFileUploader from '../../../components/form/DropFileUploader';
import ReduxFormInputColor from '../../../components/reduxFormMUIWrapper/ReduxFormInputColor';
import ReduxFormSwitch from '../../../components/reduxFormMUIWrapper/ReduxFormSwitch';
import { fonts, textAlignments } from '../../../constant';

const layerTypes = ['image', 'mask','userImage', 'userText'];

const useStyles = makeStyles({
	root: {

	},
	row: {
		composes: 'flexRow spaceAround',
		width: '100%',
	},
	containerColor:{
		position: 'absolute',
    zIndex: 100,
    right: 10,
    bottom: 130,
	},
	inputGroup:{
		composes: 'flexRow spaceBetween',
  },
  formGroup:{
		composes: 'stretchSelf',
		marginTop: 14,
	},
	field: {
		width: '49%',
	},
	inputColor: {
		marginTop: 8,
	},
	fileUploadLabel: {
		marginBottom: 6,
	},
});

/**
 * validate form
 */
const validate = values =>{
	const errors = {};
	if(!values.type){
		errors.type =  "required";
	}
	return errors;
}

const LayerCreationForm = (props) => {
	// props
	const { handleSubmit, onChangeLayerType , countLayers, heightDefault,idMask = ''  } = props;

	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selector 
	const selector = formValueSelector('layerForm');
	const layerType = useSelector(state => selector(state, 'type'));

	//on change layer type //
	const onChange =  (type) => {
		const name =  `${type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()} ${(countLayers[type] + 1)}`;
		dispatch(change('layerForm', 'name', name ));
		//------height and font size layer userText -----------//
		if (type === 'userText') {
			const fontSize = parseInt(heightDefault/10);
			const heightText = 2 * fontSize;
			dispatch(change('layerForm', 'size', fontSize ));
			dispatch(change('layerForm', 'height', heightText ));
		}
	}
	
	useEffect(()=>{
		let title =  '';
		switch (layerType) {
			case 'image':
				title = 'Image';
				break;
			case 'userText':
				title = 'Text';
				break;
			case 'userImage':
				title = 'User Image';
				break;
			case 'mask':
				title = 'Masque';
				break;
			default:
				break;
		}
		onChangeLayerType(title);
	}, [layerType, onChangeLayerType]);

	const getTypeList = () => {
			const types  = [...layerTypes];
			// remove mask type
			if (idMask!=='') {
				pullAt(types,1 );
			}
			return types;
	}

	let additionalFields;
	switch (layerType) {
			case 'image':
				additionalFields =
				<div>
					<div className={classes.inputGroup}>
							<NumberField 
								label='Rotation en °' 
								name='rotation'
								authorizeNegatif
								fullWidth 
								rootClassName={classes.field} 
							/>
					</div>
					<FormField
						labelClassName={classes.fileUploadLabel}
						label='Image'
						name='imageFile'
						component={DropFileUploader}
						fullWidth
					/>
				</div>;
			break;
			case 'mask':
				additionalFields =
					<div>
						<div className={classes.inputGroup}>
								<NumberField 
									label='Rotation en °' 
									name='rotation'
									authorizeNegatif
									fullWidth 
									rootClassName={classes.field} 
								/>
						</div>
						<FormField
							labelClassName={classes.fileUploadLabel}
							label='Image'
							name='imageFile'
							component={DropFileUploader}
							fullWidth
						/>
						<FormField
							label="Utiliser pour l'impression"
							name='usedForPrint'
							component={ReduxFormSwitch}
							valueClassName={classes.selectField}
							fullWidth
							rootClassName={classes.field}
						/>
					</div>;
				break;

		case 'userImage' : 
			additionalFields = <div className={classes.inputGroup}>
				<NumberField 
					label='Rotation en °' 
					name='rotation'
					authorizeNegatif 
					fullWidth 
					rootClassName={classes.field} 
				/>
			</div>
		break;

		case 'userText':
			additionalFields =
				<div className='flexColumn center'>
					<div className={classes.formGroup}>
						<div>Propriétés du texte</div>
						<div className={classes.inputGroup}> 
							<FormField
								label='Texte'
								name='text'
								fullWidth
								// rootClassName={classes.field}
							/>
						</div>
						<div className={classes.inputGroup}>
							<NumberField 
								label='Taille' 
								name='size' 
								fullWidth 
								rootClassName={classes.field} 
							/>

							<FormField
								label='Police'
								name='font'
								component={ReduxFormSelect}
								valueClassName={classes.selectField}
								displayEmpty
								fullWidth
								rootClassName={classes.field}
							>
								{fonts.map((type, index) => (
									<MenuItem value={type} key={index}>
										{type}
									</MenuItem>
								))}
							</FormField>
						</div>
						<div className={classes.inputGroup}>
							<FormField
								label='Alignement'
								name='alignment'
								component={ReduxFormSelect}
								valueClassName={classes.selectField}
								displayEmpty
								fullWidth
								rootClassName={classes.field}
							>
								{textAlignments.map((alignment, index) => (
									<MenuItem value={alignment.value} key={index}>
										{alignment.label}
									</MenuItem>
								))}
							</FormField>
							<FormField
								name='color'
								label='Couleur'
								component={ReduxFormInputColor}
								colorClassName={classes.containerColor}
								rootClassName={classes.inputColor}
							/>
						</div>
						<div className={classes.inputGroup}>
							<NumberField 
								label='Rotation en °' 
								name='rotation'
								authorizeNegatif
								fullWidth 
								rootClassName={classes.field} 
							/>
						</div>
					</div>
				</div>
			break;
		default:
			break;
	}

	return (
		<form onSubmit={handleSubmit}>
			<FormField
				label='Type'
				name='type'
				component={ReduxFormSelect}
				valueClassName={classes.selectField}
				onChange = {onChange}
				fullWidth
			>


				{ getTypeList().map((type, index) => (
						<MenuItem
							value={type}
							key={index}
						>
							{type}
						</MenuItem>
					))
				}
			</FormField>
			<FormField
				label='Nom'
				name='name'
				fullWidth
			/>
			<div className={classes.formGroup}>
        <div>Dimensions</div>
        <div className={classes.inputGroup}> 
          <NumberField label='largeur' name='width' fullWidth rootClassName={classes.field} />
          <NumberField label='hauteur' name='height' fullWidth rootClassName={classes.field} />
        </div>
      </div>
      <div className={classes.formGroup}>
        <div>Position</div>
        <div className={classes.inputGroup}> 
          <NumberField label='haut' name='top' fullWidth rootClassName={classes.field} />
          <NumberField label='gauche' name='left' fullWidth rootClassName={classes.field} />
        </div>
      </div>

			{additionalFields}

			<input type='submit' />
		</form>
	);
}

export default reduxForm({
	form: 'layerForm',
	validate,
	enableReinitialize: true
})(LayerCreationForm);