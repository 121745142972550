import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { formatParseObjSelectOptions } from '../../utils';

import CustomCard from '../../components/CustomCard';
import PreviewIconButton from '../../components/buttons/PreviewIconButton';
import ListIconButton from '../../components/buttons/ListIconButton';
import DeleteIconButton from '../../components/buttons/DeleteIconButton';
import ThemeForm from './ThemeForm';

import { deleteTheme, getThemeValues, showTheme, showThemes, updateTheme } from '../../actions/themes';
import { getTheme } from '../../reducers/themes';

const ThemeEdit = () => {
	// selectors
	const theme = useSelector(getTheme);

	const getInitialValues = useCallback(() => {
		const newTheme = getThemeValues(theme);
		return {
			...newTheme,
			templates: formatParseObjSelectOptions(newTheme.templates),
		};
	}, [theme]);

	// dispatch
	const dispatch = useDispatch();

	if (!theme) return null;
	const themeId = theme.id;

	// go to the preview page
	const _goToPreviewPage = (themeId) => dispatch(showTheme(themeId));

	// save change
	const _save = async (values) => {
		await dispatch(updateTheme(theme, values));
		_goToPreviewPage(themeId);
	};

	const _submit = () => {
		dispatch(submit('themeForm'));
	};

	const _goToThemeListPage = () => dispatch(showThemes());
	const _handleDelete = (theme) => {
		dispatch(deleteTheme(theme));
		_goToThemeListPage();
	};

	const actionHeaderButtons = (
		<div>
			<PreviewIconButton onShowPreview={() => _goToPreviewPage(theme)} />
			<ListIconButton onShowList={_goToThemeListPage} />
			<DeleteIconButton onDelete={() => _handleDelete(theme)} />
		</div>
	);

	return (
		<CustomCard
			
			title={'Editer thème - ' + themeId}
			content={
				<ThemeForm
					themeId={themeId}
					onSubmit={_save}
					initialValues={getInitialValues()}
				/>
			}
			okLabel="Enregistrer la modification"
			okAction={_submit}
			actionHeaderButtons={actionHeaderButtons}
		/>
	)
}

ThemeEdit.propTypes = {
	theme: PropTypes.object
};
export default ThemeEdit;