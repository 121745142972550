import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Column } from 'devextreme-react/data-grid';
import PublishIcon from '@material-ui/icons/Publish';
import { Box, Button } from '@material-ui/core';
import classNames from 'classnames';
import GetAppIcon from '@material-ui/icons/GetApp';

import { getOrderUnitCurrency, toMoment } from '../../utils';
import CustomCell from '../../components/devExpressTable/CustomCell';
import ModalDialog from '../../components/ModalDialog';
import ModalProductTable from './ModalProductTable';
import FilterForm from './FilterForm';

import { sendOrderToSwitch , sendOrderToShipping, loadOrdersWithPagination, refreshPdf } from '../../actions/orders';
import { downloadGiftMessagePdf } from '../../actions/montages';
import { getCurrentUser } from '../../reducers/app';
import { downloadFile } from '../../actions/app';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import TableButtonsAction from "../../components/devExpressTable/TableButtonsAction";

const useStyles = makeStyles({
	root: {},
	cell: {
		composes: 'flexRow stretch center',
    height: 50,
	},
	right: {
		composes: 'justifyEnd'
	},
	imageContainer: {
		width: 60, 
		height: 60,
	},
	image: {
		width: '100%',
	},
	button: {
		color: '#fff',
	},
	secondButton: {
		marginLeft: 3,
	},
});

const columns = ['number', 'paid', 'createdAt', 'nb_produit', 'total', 'data'];

const OrdersTable = (props) => {

	// props
	const { data } = props;

	// states
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [dataSource, setDataSource] = useState([]);
	const [filteredDataSource, setFilteredDataSource] = useState(null);

	const [isOpen, setIsOpen] = useState(true);

	const refRowsData = useRef([]);

	// styles
	const classes = useStyles();

	// dispatch
	const dispatch = useDispatch();
	
	// selector
	const currentUser = useSelector(getCurrentUser);

	// row click
	const onRowClick = ({ data }) => {
		const rowOrder = refRowsData.current.find(item => item.number === data.number);
		setSelectedOrder(rowOrder);
		setIsOpen(true);
	}
	
	// close dialog
	const handleCloseDialog = () => {
		setSelectedOrder(null);
	}



	// filter by paid
	const filterByPaid = (value) => {
		const paid = value ? 'Oui': 'Non';
		const filteredItems = dataSource.filter(d => d.paid === paid);
		setFilteredDataSource([...filteredItems]);
	};

	// filter by id
	const filterByNumber = (value) => {
		const filteredItems = dataSource.filter(d => d.number.includes(value));
		setFilteredDataSource([...filteredItems]);
	};

	// send selected order to Switch
	const _sendOrderToSwitch = () => {
		if (!selectedOrder?.number) return;
		dispatch(sendOrderToSwitch(selectedOrder.number));
	}

	// button to send to shipping server
	const _sendOrderToShipping =  () =>{
		if (!selectedOrder?.number) return;
		dispatch(sendOrderToShipping(selectedOrder.number));
	}

	// button to download json
	const _downloadOrderJson = () => {
		if (!selectedOrder) return null;
		const data = {
			id: selectedOrder.number,
		};

		downloadFile({ fileName: 'order.json', data, currentUser });
	}

	// button to download gift message pdf
	const onClickBtnGiftMessage = () => {
		dispatch(downloadGiftMessagePdf())
	}


	const loadOrdersData = async (loadOptions) => {
		const dataRows = await dispatch(loadOrdersWithPagination({limit: loadOptions.take, page: loadOptions.skip / loadOptions.take}));

		const data = dataRows.data.map((order) => ({
			[columns[0]]: order.number,
			[columns[1]]: order.paid ? 'Oui': 'Non',
			[columns[2]]: order.date_created,
			[columns[3]]: order.items?.length,
			[columns[4]]: order.grand_total + ' ' + getOrderUnitCurrency(order.currency),
		}));

    	refRowsData.current = dataRows.data;

		return {
			data: data,
			totalCount: dataRows.totalCount
		}
	}

	// refresh image order
	const _refreshImagePdf = async () => {
		setIsOpen(false);
		await dispatch(refreshPdf(selectedOrder.number));
	}

	// modal header action buttons
	const headerActionButtons = (
		<Box display="flex">
			{selectedOrder?.gift_message && <Button
				variant="contained"
				color="primary"
				startIcon={<GetAppIcon />}
				onClick={onClickBtnGiftMessage}
				className={classes.button}
			>
				Gift
			</Button>}
			<Button
				variant="contained"
				color="primary"
				startIcon={<GetAppIcon />}
				onClick={_downloadOrderJson}
				className={classNames(classes.button, classes.secondButton)}
			>
				Json
			</Button>		
			<Button
				variant="contained"
				color="secondary"
				startIcon={<PublishIcon />}
				onClick={_sendOrderToSwitch}
				className={classNames(classes.button, classes.secondButton)}
			>
				Switch
			</Button>	
			<Button
				variant="contained"
				color="secondary"
				startIcon={<PublishIcon />}
				onClick={_sendOrderToShipping}
				className={classNames(classes.button, classes.secondButton)}
			>
				Shipping
			</Button>
		</Box>
	);

	return (
		<div className={classes.root}>
			<Box>
				<FilterForm 
					filterByPaid={filterByPaid}
					filterByNumber={filterByNumber}
				/>
			</Box>
			{/* ----------------- Order Table ----------------- */}
			<CustomTableGrid
				// dataSource={filteredDataSource || dataSource}
				reloadDataPagination={(loadOptions) => loadOrdersData(loadOptions)}
				showColumnLines={false}
				onRowClick={onRowClick}
				actionRender={(value) => (
					<TableButtonsAction
						onRefresh={_refreshImagePdf}

					/>
				)}
			>
				<Column
					dataField={columns[0]}
					caption="Commande"
					cellRender={({ data }) => <CustomCell value={data.number} />}
				/>
				<Column
					dataField={columns[1]}
					caption="Payé"
					cellRender={({ data }) => <CustomCell value={data.paid} />}
				/>
				<Column
					dataField={columns[2]}
          dataType="date"
					caption="Date"
					cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.createdAt)).format('YYYY-MM-DD à HH:mm')} />}
				/>
				
				<Column
					dataField={columns[3]}
					caption="Nb de produit"
					alignment="right"
					cellRender={({ data }) => <CustomCell value={data.nb_produit} className={classes.right} />}
				/>
				<Column
					dataField={columns[4]}
					caption="Total" 
					alignment="right"
					cellRender={({ data, column }) => <CustomCell value={data.total} alignment={column.alignment} />}
				/>
			</CustomTableGrid>

			{/* -------------------------- Modal Editiion ------------------------ */}
			<ModalDialog
				maxWidth="md"
				title={`Liste de produits`}
				content={<ModalProductTable data={selectedOrder} />}
				isVisible={!!refRowsData.current.find(order => order.id === selectedOrder?.id) && isOpen}
				onClose={handleCloseDialog}
				labelCancel='Fermer'
				headerActionButtons={headerActionButtons}
			/>
		</div>
	)
}

OrdersTable.propTypes = {
	data: PropTypes.any,
};
export default OrdersTable;