import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { updateOrdersAssets } from '../../actions/orders';
import { getOrdersError } from '../../reducers/orders';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
	regenerateButton: {
		marginRight: 10,
		fontFamily: 'Montserrat',
		fontWeight: 600
	},

}));

const OrdersUpdateContent = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const ordersError = useSelector(getOrdersError);

	const _updateOrdersAssets = () => {
		dispatch(updateOrdersAssets());
	}
	return (
		<div className={classes.root}>
			<Box display="flex">
				<Button color="primary" variant="contained" className={classes.regenerateButton} onClick={_updateOrdersAssets}>
					Récupération des commandes
				</Button>

			</Box>
			<Box>
				<Typography variant='h6'> Commandes corrigées : </Typography>
				{ ordersError && ordersError.ordersUpdate.map((order, index) => {
					return (
						<Typography key={index}>Commande n° : {order.id} - Produit : {order.name}</Typography>
					);
				})}
				<Typography variant='h6'> Commandes non corrigées : </Typography>
				{ ordersError && ordersError.ordersErrors.map((order, index) => {
					return (
						<Typography key={index}>Commande n° : {order.id} - Produit : {order.name}</Typography>
					);
				})}
			</Box>

		</div>
	)
}

export default OrdersUpdateContent;