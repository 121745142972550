export const fonts = ['Montserrat', 'AmaticSC', 'Courgette', 'DelaGothicOne', 'Neucha', 'Parisienne', 'PlayfairDisplay'];
export const textAlignments = [
	{ label: 'Center', value: 'center'}, 
	{ label: 'Left', value: 'left'},
	{ label: 'Right', value: 'right'},
];

export const GCP_PATHNAME = 'https://storage.googleapis.com/';

export const PROD_HOSTNAME = 'bo.yourz.me';
export const PREPROD_HOSTNAME = 'bo-preprod.yourz.me';
export const LOCAL_HOSTNAME = 'localhost';
export const LOCAL_ENDPOINT_HOSTNAME= '127.0.0.1';

export const PROD_URL = 'https://' + PROD_HOSTNAME;
export const PREPROD_URL = 'https://' + PREPROD_HOSTNAME;
export const LOCAL_URL = 'http://' + LOCAL_HOSTNAME;
export const LOCAL_ENDPOINT = 'http://' + LOCAL_ENDPOINT_HOSTNAME + ':1337';

export const FORCE_PRODUCTION = false;
