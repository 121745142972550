import React from "react";
import PropTypes from "prop-types";
import { FieldArray, reduxForm } from "redux-form";
import { makeStyles } from "@material-ui/core/styles";

import FormField from "../../components/form/FormField";
import BulletedListField from "./BulletedListField";

const useStyles = makeStyles({
  root: {
    composes: "flexColumn stretch center",
  },
  selectField: {
    width: 160,
  },
});

const ProductCustomFieldsForm = (props) => {
  // props
  const { handleSubmit } = props;

  // styles
  const classes = useStyles(props);

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormField label="Titre de la bannière de promotion" name="title" fullWidth />
      <FormField label="Description de la bannière de promotion" name="desc" fullWidth />
        
      <FieldArray label="Details du produit" name="details" component={BulletedListField} />
      <FieldArray label="Expédition" name="shipping" component={BulletedListField} />
      <FieldArray label="Idéale pour" name="idealFor" component={BulletedListField} />

      <input type="submit" />
    </form>
  );
};

ProductCustomFieldsForm.propTypes = {
  onSubmit: PropTypes.func,
};
export default reduxForm({
  form: 'productCustomFieldsForm',
})(ProductCustomFieldsForm);
