import { getData } from './index';

export default function app(
	state = {
		user: null,
		loading: false,
		error: null,
		message: null,
		variant: null,
		title: null,
		displayScreen: 'desktop'
	}, action) {
	switch (action.type) {
		case 'LOADING_START':
			return {
				...state,
				loading: true
			};
		case 'LOADING_END':
			return {
				...state,
				loading: false
			};
		case 'LOGIN_SUCCESS':
			return {
				...state,
				user: action.user
			};
		case 'LOGOUT_SUCCESS':
			return {
				...state,
				user: null
			};
		case 'ERROR':
			return {
				...state,
				error: action.message,
				variant: 'error'
			};
		case 'CLOSE_ERROR':
			return {
				...state,
				error: null
			};
		case 'MESSAGE':
			return {
				...state,
				message: action.message,
				variant: action.variant || 'info'
			};
		case 'CLOSE_MESSAGE':
			return {
				...state,
				message: null
			};
		case 'DISPLAY_SCREEN_UPDATED':
			return {
				...state,
				displayScreen: action.displayScreen
			};
		default:
			return state
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getCurrentUser(state, errorIfNotFound = false) {
	return getData(state, 'app.user', errorIfNotFound && 'No current user in app');
}
export function getError(state, errorIfNotFound = false) {
	return getData(state, 'app.error', errorIfNotFound && 'No error in app');
}
export function getMessage(state, errorIfNotFound = false) {
	return getData(state, 'app.message', errorIfNotFound && 'No message in app');
}
export function getMessageVariant(state, errorIfNotFound = false) {
	return getData(state, 'app.variant', errorIfNotFound && 'No message in app');
}
export function getLoading(state, errorIfNotFound = false) {
	return getData(state, 'app.loading', errorIfNotFound && 'No action with loader in app');
}
export function getTitle(state, errorIfNotFound = false) {
	return getData(state, 'app.title', errorIfNotFound && 'No title in app');
}
export function getDisplayScreen(state, errorIfNotFound = false) {
	return getData(state, 'app.displayScreen', errorIfNotFound && 'Unknown screen device');
}