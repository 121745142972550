import React from 'react';

import CustomCard from '../../components/CustomCard';

import TestContent from './TestContent';

const Test = () => {

	return (
			<CustomCard
				title='Test'
				content={<TestContent />}
				withActionButtons={false}
				fullScreen
			/>
	)
}

export default Test;