import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FormField from '../../components/form/FormField';
import NumberField from '../../components/form/NumberField';
import DropFileUploader from '../../components/form/DropFileUploader';

import { updateBackgroundPropertiesValues } from '../../actions/backgrounds';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch',
  },
  section: {
    width: '100%',
  },
  row: {
    composes: 'flexRow spaceAround',
    width: '100%',
    marginLeft: -5,
    marginRight: -5
  },
  formContainer: {
    composes: 'flexColumn spaceBetween',
    //width: '50%'
  },
  rowContainer: {
    padding : 10
  },
  dimensions: {
    width:100,
  },
  field: {
    marginLeft: 5,
    marginRight: 5
	},
  greenCircle: {
    width: 15,
    border: '1px solid #34c924',
    height: 15,
    background: '#34c924',
    borderRadius: '50%',
    marginRight: 10
  },
  blueCircle: {
    width: 15,
    border: '1px solid #4af',
    height: 15,
    background: '#4af',
    borderRadius: '50%',
    marginRight: 10
  },
  titleContainer: {
    composes: 'flexRow center'
  }
});

const BackgroundPropertiesForm = (props) => {
  // props
  const { handleSubmit } = props;

  // styles
  const classes = useStyles(props);

  //---- render ----//
  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <div className={classes.rowContainer}>
        <div>Taille total</div>
        <div className={classes.formContainer}>
          <div className={classes.row}>
            <NumberField
              label='largeur'
              name='width'
              rootClassName={classes.field} 
            />
            <NumberField
              label='hauteur'
              name='height'
              rootClassName={classes.field} 
            />
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.blueCircle}></div>
          <span>Contenu</span>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.row}>
            <NumberField 
              label='largeur' 
              name='content.width' 
              rootClassName={classes.field} 
            />
            <NumberField 
              label='hauteur'
              name='content.height'
              rootClassName={classes.field} 
            />
          </div>
          <div className={classes.row}>
            <NumberField 
              label='haut'
              name='content.top'
              rootClassName={classes.field} 
            />
            <NumberField
              label='gauche'
              name='content.left'
              rootClassName={classes.field} 
            />
          </div>
          <div className={classes.row}>
            <FormField
              label='Masque'
              name='content.maskImageFile'
              component={DropFileUploader}
              fullWidth
            />
          </div>
        </div>
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.titleContainer}>
          <div className={classes.greenCircle}></div>
          <span>Image de fond</span>
        </div>
        <div className={classes.formContainer}>
          <div className={classes.row}>
            <NumberField
              label='largeur'
              name='background.width'
              rootClassName={classes.field} 
            />
            <NumberField
              label='hauteur'
              name='background.height'
              rootClassName={classes.field} 
            />
          </div>
          <div className={classes.row}>
            <NumberField
              label='haut'
              name='background.top'
              rootClassName={classes.field} 
            />
            <NumberField
              label='gauche'
              name='background.left'
              rootClassName={classes.field} 
            />
          </div>
          <div className={classes.row}>
            <FormField
              label='Image'
              name='background.imageFile'
              component={DropFileUploader}
              fullWidth
            />
          </div>
        </div>
      </div>

      <input type='submit' />
    </form>
  );
};

BackgroundPropertiesForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
export default reduxForm({
  form: 'backgroundPropertiesForm',
  enableReinitialize: true,
  onChange: (values, dispatch) => {

    //update backgroundPropertiesValues
    dispatch(updateBackgroundPropertiesValues(values));

  }
})(BackgroundPropertiesForm);



