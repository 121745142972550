import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Divider, Drawer, IconButton, List, useTheme } from '@material-ui/core';

import RootMenuItem from './RootMenuItem';

import { logout, showHome, showTest } from '../../actions/app';
import { showTemplates } from '../../actions/templates';
import { showMontages } from '../../actions/montages';
import { showBackgrounds } from '../../actions/backgrounds';
import { showProducts } from '../../actions/products';
import { showOrders, showUpdateOrderAssets } from '../../actions/orders';
import { getCurrentUser } from '../../reducers/app';
import { showPromoBanners } from '../../actions/promoBanners';

export const drawerWidthOpen = 240;

const useStyles = makeStyles(theme => ({
	menuButton: {
		composes: 'flexRow',
		'&:hover': {
			backgroundColor: 'transparent',
		}
	},
	menuButtonOpen: {
		justifyContent: 'flex-end',
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidthOpen,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidthOpen,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: theme.spacing(7) + 1,
	},
	content: {
		composes: 'flexColumn spaceBetween flex1',
	},
	list: {
		composes: 'stretchSelf',
		padding: 0,
	}
}));

const Menu = (props) => {
	// state
  const [open, setOpen] = useState(false);

	// props
	const { pathName } = props;

	// styles
	const classes = useStyles(props);

	const theme = useTheme();
	const dispatch = useDispatch();
	const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
	};
	
	const _showMenu = menuName => {
		switch (menuName) {
			case 'templates':
				showTemplates();
				break;

			case 'montages':
				showMontages();
				break;

			case 'backgrounds':
				showBackgrounds();
				break;

			case 'products':
				showProducts();
				break;

			case 'orders':
				showOrders();
				break;

			case 'promoBanners':
				showPromoBanners();
				break;
		
			case 'test':
				showTest();
				break;

			case 'updateOrderAssets':
				showUpdateOrderAssets();
				break;

			case 'edition':
				break;
				
			default:
				showHome();
				break;
		}
	};

	const _logout = () => {
		dispatch(logout());
	};

	const user = useSelector(getCurrentUser);

	const MenuItem = ({name, label, selectedPathName}) => {
		return (
			<RootMenuItem
				name={name}
				label={label}
				onClick={_showMenu}
				selected={pathName === selectedPathName}
			/>
		)
	};

	return (
		<Drawer
			variant="permanent"
			className={classNames(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: classNames({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			{/* -------------- Toggle Menu  -------------- */}
			<IconButton
				color="inherit"
				aria-label="open drawer"
				onClick={handleDrawerOpen}
				className={classNames(classes.menuButton, {[classes.hide]: open})}
			>
				<MenuIcon />
			</IconButton>
			<IconButton
				onClick={handleDrawerClose}
				className={classNames(classes.menuButton, classes.menuButtonOpen, {[classes.hide]: !open},open)}
			>
				{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
			</IconButton>
			<Divider />
			<div className={classes.content}>
				<List className={classes.list}>
					<MenuItem
						name="user"
						label={user.get('username')}
					/>
					<Divider />

					<MenuItem
						name='templates'
						label='Templates'
						selectedPathName='/templates'
					/>
					<MenuItem
						name='montages'
						label='Montages'
						selectedPathName='/montages'
					/>
					<MenuItem
						name='backgrounds'
						label='Fonds'
						selectedPathName='/backgrounds'
					/>
					<MenuItem
						name='products'
						label='Produits'
						selectedPathName='/products'
					/>
					<MenuItem
						name='orders'
						label='Commandes'
						selectedPathName='/orders'
					/>
					<MenuItem
						name='promoBanners'
						label='Bannière Promotion'
						selectedPathName='/promoBanners'
					/>
	
					<MenuItem
						name='test'
						label='Test'
						selectedPathName='/test'
					/>

					<MenuItem
						name='updateOrderAssets'
						label='Divers'
						selectedPathName='/updateOrderAssets'
					/>

				</List>
				<List className={classes.list}>
					<Divider />
					{/*---- Logout ----*/}
					<RootMenuItem
						name='logout'
						label="Déconnexion"
						onClick={_logout}
					/>
				</List>
			</div>
		</Drawer>
	);
};

Menu.propTypes = {
	pathname: PropTypes.string,
};

export default Menu;