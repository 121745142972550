import React, { useEffect, useState } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';

import CustomCard from '../../components/CustomCard';
import { getObjectType, getImageUrlTest } from '../../actions/test';

const useStyles = makeStyles({
	header:{
		paddingBottom: 10
	},
	card: {
		maxWidth: '92vw',
		overflow: 'auto',
	},
});

const EndPointTest = (props) => {
	const imageId = props.params.imageId;
	// classes
	const classes = useStyles();

	const [imagePreview, setImagePreview] = useState(null);

	// useEffect
	useEffect(() => {
		const getImagePreviewTest = async () => {
			const objectType = getObjectType(imageId);
			let imageType;
			switch(objectType) {
				case 'background':
					imageType = 'backgroundImage';
				break;
				default:
					imageType = 'montagePreview';
			}
			// google storage takes time to upload the image so we need to add timeout before display its
			setTimeout(async () => {
				const imageUrl = await getImageUrlTest({ imageId, imageType });
				setImagePreview(imageUrl);
			}, 2000);
		}
		getImagePreviewTest();
	}, []);

	const content = (
		<Box>
			<Box className={classes.header}>
				<Typography variant='h5' >
						ImageId : {props.params.imageId}
				</Typography>
			</Box>
			<Box>
				<img src={imagePreview} alt="" />
			</Box>
		</Box>

	);
	return (
		<CustomCard
			fullScreen
			content={content}
			withActionButtons={false}
			contentClassName={classes.card}
		/>
	);
}

export default EndPointTest;