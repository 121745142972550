import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import CustomCard from '../../components/CustomCard';

import { saveMontagePdf, showMontages, getSignedUrlMontagePreview, refreshImages } from '../../actions/montages';
import { getMontage } from '../../reducers/montages';
import { useZoom } from '../../hooks/useZoom';

import CloseIconButton from '../../components/buttons/CloseIconButton';
import RefreshButton from '../../components/buttons/RefreshButton';
import ZoomPreview from '../../components/ZoomPreview';

const useStyles = makeStyles(theme => ({
	mainContent: {
		composes: 'flexColumn center',
	},
	saveButton: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	linkTemplate: {
		marginRight: theme.spacing(2),
		textDecoration: 'none',
		color: '#0097a7'
	},
	containerActions: {
		composes: 'flexRow center',
	},
	card: {
		maxWidth: '92vw',
		overflow: 'initial',
	},
	header: {
    paddingBottom: 10,
  },
	zoomPreview: {
		width: 'initial',
		height: '90vh !important',
		border: 'none',
	},
	zoomButtons: {
		marginLeft: theme.spacing(2),
	},
}));

const MontagePreview = () => {

	// style
	const classes = useStyles();

	// state
	const [imagePreview,setImagePrewiew] = useState(null);
	
	// selector
	const montage = useSelector(getMontage);

	// zoom and borderWidth
	const { zoom, setZoom } = useZoom(montage);

	// dispatch
	const dispatch = useDispatch();

	useEffect(() => {
    // Run! Like go get some data from an API.
		const url = dispatch(getSignedUrlMontagePreview(montage.id));
		url.then((value) => setImagePrewiew(value));
  }, [dispatch, montage])

	if (!montage) return null;
	const montageId = montage.id;

	const _goToMontageListPage = () => dispatch(showMontages());

	const _printMontage = () => {
		if (!montageId) return null;
		dispatch(saveMontagePdf(montageId));
	};

	// refresh image preview
	const _refreshImages = async () => {
		const imageUrl = await dispatch(refreshImages(montageId));
		// await image found in gcs after refresh
		setTimeout(() => setImagePrewiew(imageUrl),5000);

	}

	const urlTemplate = montage.has('template') ? `/template-${montage.get('template').id}` : '#';

	const title = (
		<Typography variant='h5'>
			{'montage ' + montageId}
		</Typography>
	);

	const actionButtons = (
		<Box className={classes.containerActions}>
			<a className={classes.linkTemplate} href={urlTemplate} target="_blank" >
				<Typography variant='h6'>Vers template</Typography>
			</a>
			<RefreshButton onRefresh={_refreshImages} />
			<Button variant="contained" color="primary" onClick={_printMontage} className={classes.saveButton}>
				Save as PDF
			</Button>
			<CloseIconButton onClose={_goToMontageListPage} />
		</Box>
	);

	const cardContent = (
		montage.has('imageId')
			? (
				<ZoomPreview
					headerTitle={title}
					headerActions={actionButtons}
					rootClassName={classes.zoomPreview}
					headerClassName={classes.header}
					buttonsClassName={classes.zoomButtons}
					zoom={zoom}
					onZoomChange={setZoom }
				>
					<img src={imagePreview} alt="" />
				</ZoomPreview>
			)
			:(
				<div className={classes.mainContent}>
					{ actionButtons }
					<Typography>Il n'y a pas d'image pour ce montage</Typography>
				</div>
			)
	);

	return (
		<CustomCard
			fullScreen
			content={cardContent}
			cancelLabel="Retourner à la liste"
			withActionButtons={false}
			contentClassName={classes.card}
		/>
	)
}

export default MontagePreview;