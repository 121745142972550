import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import AddIconButton from '../../components/buttons/AddIconButton';
import ModalDialog from '../../components/ModalDialog';

import BackgroundForm, {ToBackgroundProperties} from './BackgroundForm';

import { createBackground } from '../../actions/backgrounds';
import BackgroundsTable from './BackgroundsTable';
import { validateBackground } from '../../validation';

const initialValues = {
  width: 1000,
  height: 500,
};

const Backgrounds = () => {
  
	// state
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  
  // dispatch
  const dispatch = useDispatch();

	// dialog actions
  const _openDialog = () => setOpen(true);
	const _closeDialog = () => setOpen(false);

  	// save change
	const _createBackground = async(values) => {
	  validateBackground(values);

    const  newBackgroundValues = ToBackgroundProperties(values);
		// create new montages
		await dispatch(createBackground(newBackgroundValues)).then(()=> {
			setReload(!!!reload);
		});
		_closeDialog();
	};
	
	// form submit
  const _submit = () => dispatch(submit('backgroundForm'));
  
  return (
    <>
      <CustomCard
        title='Liste des fonds'
        actionHeaderButtons={ <AddIconButton onAdd={_openDialog} />}
				content={<BackgroundsTable isReloaded={reload} onReload={setReload} />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
				title="Ajouter Nouveau fond"
        content={
          <BackgroundForm 
            onSubmit={_createBackground} 
            initialValues={initialValues}
          />
        }
				isVisible={open}
				onConfirm={_submit}
				onClose={_closeDialog}
			/>
    </>
  );
};

Backgrounds.propTypes = {
  backgrounds: PropTypes.array,
};
export default Backgrounds;
