import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReduxFormTextField = (props) => {
	// props
	const {
		input: { name, value , onChange },
		meta: { error, touched },
		multiline = false, rowsMax,
		type = 'text',
		placeholder, fullWidth, readOnly, disabled
	} = props;
	const text = value || '';

	const _onChange = (event) => {
		onChange(event.target.value);
	};

	return (
		<div className="width100">
			<TextField
				name={name}
				value={text}
				onChange={_onChange}
				multiline={multiline}
				rowsMax={multiline && rowsMax ? rowsMax : ''}
				type={type}
				placeholder={placeholder}
				fullWidth={fullWidth}
				inputProps={{
					readOnly
				}}
				disabled={disabled}
			/>
			{ touched && error && <FormHelperText error>{ error }</FormHelperText>	}
		</div>
	)
};

export default ReduxFormTextField;