import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { submit, change } from 'redux-form';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button/Button';
import classNames from 'classnames';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { getBackgroundValues, updateBackgroundEditor, showBackgrounds } from '../../actions/backgrounds';
import { getBackground, getBackgroundPropertiesValues } from '../../reducers/backgrounds';
import { useZoom } from '../../hooks/useZoom';

import BackgroundPropertiesForm from './BackgroundPropertiesForm';
import BackgroundLayerPreview from './BackgroundLayerPreview';
import ZoomPreview from '../../components/ZoomPreview';

const useStyles = makeStyles((theme) => ({
  root: {
    composes: 'flexColumn stretch',
    width: '100%',
    minHeight: 'calc(98vh - 40px)',
    padding: '0px 20px',
  },
  header: {
    fontSize: 26,
  },
  container: {
    composes: 'flexRow ',
		height:'calc(96vh - 40px)',
    backgroundColor: theme.background.blue[568],
    border: theme.border.grey[645],
	},
  leftContainer: {
    composes: 'flexColumn stretch',
    border: theme.border.grey[800],
    borderWidth: 2,
		maxWidth: 300,
		height: '100%',
  },
  rightContainer: {
    composes: 'flex1 flexColumn stretch',
    border: theme.border.grey[600],
    //borderLeft: theme.border.grey[645],
    height: '90vh',
    overflow: 'auto',
		width : 700
  },
	buttonHeader: {
		composes: 'flexRow spaceBetween'
	},
  preview: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
		overflow:'auto',
		position : 'relative'
	},
  formContainer: {
    maxHeight:'100vh',
    overflow:'auto',
  },
  actions: {
    borderTop: theme.border.grey[620],
    backgroundColor: theme.background.grey[505],
    width: '100%',
    padding: '4px 10px',
    justifyContent: 'flex-end',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  containerLayerPreview: {
    backgroundColor: '#fff',
    overflow: 'hidden'
  }
}));


const BackgroundEditor = (props) => {

	// styles
  const classes = useStyles(props);

  // background and backgroundValues via store
  const background = useSelector(getBackground);
  const backgroundValues = getBackgroundValues(background);

  // background form values ( redux form )
  const backgroundFormValues = useSelector(getBackgroundPropertiesValues);

  // dispatch 
  const dispatch = useDispatch();

  // ------------------------------------------------------------------ //
  // ----------------- INIT STATE ------------------------------------- //
  // ------------------------------------------------------------------ //

  // init default layers editor preview
  // -> (container, image, content) 

  const [backgroundContainer, setBackgroundContainer] = useState(backgroundValues);
  const [imageLayer, setImageLayer] = useState(backgroundValues.background);
  const [contentLayer, setContentLayer] = useState(backgroundValues.content);

  // update background values editor preview after change properties form 
  useEffect(() => {
    if (!backgroundFormValues) return;
    setBackgroundContainer(backgroundFormValues);
    setImageLayer(backgroundFormValues.background);
    setContentLayer(backgroundFormValues.content); 
  }, [backgroundFormValues]);


  //-----------------------------------------------------//
	//----------- zoom and borderWidth --------------------//
	//-----------------------------------------------------//

  // set default BorderWidth to 1 for background editor
	const { zoom, setZoom, borderWidth } = useZoom(background, 1);
  
  // update background values form properties after resize and drag layer preview
  const updateFormBackgroundProperties = (type, values) => {

    if (!values) return;
    batch(() => {
			dispatch(change('backgroundPropertiesForm', `${type}.width`, values.width));
			dispatch(change('backgroundPropertiesForm', `${type}.height`, values.height));
			dispatch(change('backgroundPropertiesForm', `${type}.left`, values.left));
			dispatch(change('backgroundPropertiesForm', `${type}.top`, values.top));
		})
  }

  // save background 
	const _saveBackgroundProperties = async (values) => {
    background.set('editor', {zoom: zoom});
		await dispatch(updateBackgroundEditor(background, values));
	};

  // submit form backgroundProperties
  const _submitLayer = () => {
		dispatch(submit('backgroundPropertiesForm'));
	};

  const _cancel = () => {
		showBackgrounds();
	};

	return (
		<div className={classes.root}>
      <div className={classes.container}>
				{/*---- left bloc ----*/}
				<div className={classes.leftContainer}>
          <div className={classes.formContainer}>
            <BackgroundPropertiesForm 
              initialValues={backgroundValues}
              onSubmit={_saveBackgroundProperties}
            />
          </div>
          <div>
            <CardActions classes={{ root: classNames(classes.actions) }}>
              <div className={classes.buttons}>
                <Button color='secondary' onClick={_cancel}>
                  Annuler
                </Button>

                <Button
                  color='primary'
                  onClick={_submitLayer}
                  classes={{ root: classes.okBtn }}
                  
                >
                  Enregistrer
                </Button>
              </div>
            </CardActions>
          </div>
        </div>
        	{/*---- right bloc ----*/}
        <ZoomPreview
          style={{
            width: backgroundContainer.width,
            height: backgroundContainer.height,
            backgroundColor: 'white',
          }}
          zoom={zoom}
          onZoomChange={setZoom}
        >
          <BackgroundLayerPreview
            layer={imageLayer}
            maxWidth={backgroundContainer.width} 
            maxHeight={backgroundContainer.height}
            zoom={borderWidth}
            backgroundId={background.id}
            onMoveableLayerPreview={updateFormBackgroundProperties}
          />
          
          <BackgroundLayerPreview
            layer={contentLayer}
            maxWidth={backgroundContainer.width} 
            maxHeight={backgroundContainer.height}
            zoom={borderWidth}
            backgroundId={background.id}
            onMoveableLayerPreview={updateFormBackgroundProperties}
            type="content"
          />              
        </ZoomPreview>
      </div>
		</div>
	);
};

BackgroundEditor.propTypes = {
	template: PropTypes.object
};
export default BackgroundEditor;