import { getData } from './index';

export default function backgrounds (
	state = {
		background: null,
		backgroundLocalVersion: 0,
		backgrounds: [],
		backgroundsLocalVersion: 0,
		count: 0,
		backgroundPropertiesValues: null
	}, action) {
	switch (action.type) {
		case 'BACKGROUND_LOADED':
			return {
				...state,
				background: action.background
			};
		case 'REMOVE_BACKGROUND':
			return {
				...state,
				background: null
			};
		case 'BACKGROUND_UPDATED':
			return {
				...state,
				background: action.background,
				backgroundLocalVersion: state.backgroundLocalVersion + 1
			};
		case 'BACKGROUNDS_LOADED':
			return {
				...state,
				backgrounds: action.backgrounds,
				count: action.count
			};
		case 'BACKGROUNDS_UPDATED':
			return {
				...state,
				backgrounds: action.backgrounds,
				backgroundsLocalVersion: state.backgroundsLocalVersion + 1
			};
		case 'BACKGROUND_PROPERTIES_UPDATED':
			return {
				...state,
				backgroundPropertiesValues: action.backgroundPropertiesValues
			};
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getBackground(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.background", errorIfNotFound && "No background data found");
}
export function getBackgroundLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.backgroundLocalVersion", errorIfNotFound && "No background data found");
}
export function getBackgrounds(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.backgrounds", errorIfNotFound && "No backgrounds data found");
}
export function getBackgroundPropertiesValues(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.backgroundPropertiesValues", errorIfNotFound && "No backgrounds data found");
}
export function getBackgroundsLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.backgroundsLocalVersion", errorIfNotFound && "No backgrounds data found");
}
export function getBackgroundCount(state, errorIfNotFound = false) {
	return getData(state, "backgrounds.count", errorIfNotFound && "No backgrounds data found");
}