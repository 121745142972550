import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import { validateTheme } from '../../validation';

import CustomCard from '../../components/CustomCard';
import AddIconButton from '../../components/buttons/AddIconButton';
import ModalDialog from '../../components/ModalDialog';

import ThemesTable from './ThemesTable';
import ThemeForm from './ThemeForm';

import { createTheme } from '../../actions/themes';

import { getThemes } from '../../reducers/themes';

const Themes = () => {

	const [openDialog, setOpenDialog] =  useState(false);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const themes = useSelector(getThemes) || [];

	// dialog actions
	const _openDialog = () => setOpenDialog(true);
	const _closeDialog = () => setOpenDialog(false);

	// create new theme
	const _createTheme = async(values) => {
		validateTheme(values);
		await dispatch(createTheme(values));
		setOpenDialog(false);
	};

	const _submit =  ()=>{
		dispatch(submit('themeForm'));
	}

	return (
		<>
		<CustomCard
			title='Liste des thèmes'
			content={<ThemesTable rows={themes} />}
			withActionButtons={false}
			actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
			fullScreen
		/>
		<ModalDialog
				title="Ajouter nouveau thème"
				content={<ThemeForm onSubmit={_createTheme} />}
				isVisible={openDialog}
				onConfirm={_submit}
				onClose={_closeDialog}
			/>
		</>
	)
}

Themes.propTypes = {
	themes: PropTypes.array
};
export default Themes;