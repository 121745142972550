import React, {  useEffect, useMemo, useState } from 'react';
import Parse from 'parse';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, batch } from 'react-redux';
import { submit, change } from 'redux-form';
import uid from 'uid'; 
import { omit, fill, findIndex, nth, pullAt } from 'lodash';
import { useUpdate  } from 'react-use';
import arrayMove from 'array-move';
import classNames from 'classnames';

import { IconButton, Box } from "@material-ui/core";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button/Button';
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import ModalDialog from '../../components/ModalDialog';
import ZoomPreview from '../../components/ZoomPreview';

import LayerForm from './layers/LayerCreationForm';
import LayersList from './layers/LayersList';
import LayerPreview from './layers/LayerPreview';
import MaskPreview from './MaskPreview';
import PropertiesForm from './layers/LayerForm';
import TemplateDetails from './TemplateDetails';

import { countLayersByType, saveTemplateEditor, regenerateImages, showTemplates, updateTemplate, copyTemplateToProd } from '../../actions/templates';

import { getTemplate , getLayer } from '../../reducers/templates';
import { useFileDefault } from '../../hooks/useFileDefault';
import { useZoom } from '../../hooks/useZoom';

import { validateTemplateLayer } from '../../validation';


const useStyles = makeStyles((theme) => ({
  root: {
    composes: 'flexColumn stretch',
    width: '100%',
    minHeight: 'calc(98vh - 40px)',
    padding: '0px 20px',
  },
  header: {
    fontSize: 26,
  },
  container: {
    composes: 'flexRow ',
		height:'calc(96vh - 55px)',
    backgroundColor: theme.background.blue[568],
    border: theme.border.grey[645],
	},
	
	templateContainer:{
		composes: 'flexColumn stretch',
		border: theme.border.grey[600],
		height: '80vh',
		maxWidth: 350
	},
  leftContainer: {
    composes: 'flexColumn stretch',
    border: theme.border.grey[800],
    borderWidth: 2,
		maxWidth: 300,
		height: '100%',
  },
  rightContainer: {
    composes: 'flex1 flexColumn stretch',
    border: theme.border.grey[600],
    //borderLeft: theme.border.grey[645],
    height: '90vh',
    overflow: 'auto',
		width : 700
  },
	buttonHeader: {
		composes: 'flexRow spaceBetween'
	},
	regenerateButton: {
		marginRight: 10,
		fontFamily: 'Montserrat',
		fontWeight: 600
	},
  actions: {
    borderTop: theme.border.grey[620],
    backgroundColor: theme.background.grey[505],
    width: '100%',
    padding: '4px 10px',
    justifyContent: 'flex-end',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  btn: {
    minWidth: 180,
  },
  okBtn: {
    composes: '$btn',
    marginLeft: 15,
  },
  layerPropertiesTitle: {
		composes: 'flexRow spaceBetween center'
  },
	toggleIcon: {
		position: 'absolute',
		top: 5,
		right: 17 // to prevent the scrollbar overlap
	},
	layerContainer: {
  	position: 'relative', // for the absolute child
		borderTop: theme.border.grey[610],
		maxHeight:'30vh',
		overflow: 'hidden',
	},
  layerContainerHidden: {
		maxHeight: '6vh',
		paddingTop: 5, paddingBottom: 5,
		height: 60
  },
	layerProperties: {
		height: '100%',
		overflow: 'auto',
		padding: 15, paddingRight: 0,
	},
	layerPropertiesHidden: {
  	overflow: 'hidden' // to remove the scrollbar
	}
}));


const TemplateEditor = (props) => {
	// state
	const [isOpenCreationModal, setIsOpenCreationModal] = useState(false);
	const [layers, setLayers] = useState([]);
	const [layerDialogTitle, setLayerDialogTitle] = useState('');
	const [countLayerName, setCountLayerName] = useState({ image:0, userText:0, userImage:0, mask:0 });

	const [hasChanged, setHasChanged] = useState(false);
	const [toggleLayerForm, setTogleLayerForm] = useState(false);


	 
	// styles
	const classes = useStyles(props);

	const update = useUpdate();

	// dispatch
	const dispatch = useDispatch();

	// state preview editor 
	const [selectedLayer, setSelectedLayer] = useState(null);
	const [selectedMaskItem, setSelectedMaskItem] = useState(null);
	const [selectedMaskIndex, setSelectedMaskIndex] = useState(-1);
	const [idMaskActive, setIdMaskActive] = useState('');

	// get default file 
	const defaultImageLayer = useFileDefault();
	const defaultMaskLayer = useFileDefault('masque.png');
	const defaultUserImage = useFileDefault('userImage.jpg');

	//-------------------------------------------//
	//----------- Template cloning --------------//
	//-------------------------------------------//
	const template = useSelector(getTemplate);

	useEffect(() => {
		const originalLayers = template.get('layers') || [];
		setLayers([...originalLayers]);
		/**
		 * set count default name layers
		 */
		const cnt= countLayersByType(originalLayers);
		setCountLayerName	(cnt);
	}, [template]);

	//-----------------------------------------------------//
	//----------- zoom and borderWidth --------------------//
	//-----------------------------------------------------//
	const { zoom, setZoom, borderWidth } = useZoom(template);

	// need the old layers when the modification is canceled
	//const originalLayers = template.get('layers') || [];
	// const oldLayers = useMemo(() => {
	// 	const clonedLayers = cloneDeep(originalLayers);
	// 	return clonedLayers
	// }, [originalLayers]);

	// update layers after change values from form  properties
	const layerEdit =  useSelector(getLayer);

	/**
	 * update layers list after change layer properties  
	 * @param {object} newLayer 
	 * @returns 
	 */
	const updateLayersWithSelected = (newLayer) => {
		const prevLayers = [...layers];
		const index = findIndex(prevLayers, l => l === selectedLayer);

		if (index !== -1) {
			fill(prevLayers, newLayer, index, index + 1);
			setLayers(prevLayers);
			setSelectedLayer(newLayer);
			setHasChanged(true);
			return; 
		}

		setSelectedLayer(null);
	}

	useEffect(() => {
		if (!layerEdit) return;
		// ---------- selected items mask --------------------- //
		const prevLayers = [...layers];

		if (selectedMaskIndex !== -1) {
			const oldMask = nth(prevLayers, selectedMaskIndex);
			const layerIndex = findIndex(oldMask.layers, l => l === selectedMaskItem);

			if (layerIndex !== -1) {
				fill(oldMask.layers, layerEdit, layerIndex, layerIndex+1);
				fill(prevLayers, oldMask, selectedMaskIndex, selectedMaskIndex+1);
				setSelectedMaskItem(layerEdit);
				setLayers(prevLayers);
				setHasChanged(true);
				update();
			}
			return ;
		}

		// ------------ selected layer container ------------------------- //
		updateLayersWithSelected(layerEdit);
	},[layerEdit]);

	const layersOrderChanged = (sourceIndex, destinationIndex) => {
		const orderedLayers = [...layers];
		arrayMove.mutate(orderedLayers, sourceIndex, destinationIndex);
		setLayers([...orderedLayers]);
		setHasChanged(true);
		update();
	};

	const widthInPx = template.getWidthInPx();
	const heightInPx = template.getHeightInPx();

	const _setCountLayerName =  (type) => {
		setCountLayerName((state)=>{
			const newCount = state;
			newCount[type] = newCount[type]+1;
			return newCount; 
		});
	};

	// random name
	const getRandomName = type => {
		return `${type.charAt(0).toUpperCase() + type.substring(1).toLowerCase()} ${(countLayerName[type]+1)}`;
	}

	// the form component doesn't accept a function as initialValues,
	// so we have to use useMemo
	const layerInitialValues = useMemo(() => {
		const layer = selectedLayer ? selectedLayer : (selectedMaskItem ?? {}) ;
		if (layer.usedForPrint == null) {
			layer.usedForPrint = true;
		}
		return layer;
	}, [selectedLayer, selectedMaskItem]);

	const _saveLayerProperties = async values => {
		for (const [key, value] of Object.entries(values)) {
			selectedLayer[key] = value;
		}
	};

	const _saveTemplate = async () => {
		template.set('editor', {zoom: zoom});

		dispatch(saveTemplateEditor(template, layers));
		setHasChanged(false);
	};

	const _cancel = () => {
		showTemplates();
	};

	const _showCreationModal = (idMasque = '' ) => {
		setIsOpenCreationModal(true);
		setIdMaskActive(idMasque);
	};

	const _closeCreationModal = () => {
		setIsOpenCreationModal(false);
	};

	// add selectedLayer
	const _addLayer = async (values)  => {
		validateTemplateLayer(values);

		// set count random name 
		_setCountLayerName(values.type);

		let newValues = values;

		// remove the initial values for userText and mask
		if (values.type !== 'userText') {
			const userTextFields = ['alignment', 'font', 'color', 'size', 'text'];
			newValues = omit(values, userTextFields);
		} else if (values.type !== 'mask') {
			const maskFields = ['usedForPrint'];
			newValues = omit(values, maskFields);
		}

		const layer = {
			id: uid(10),
			...newValues,
		};

		// set  default image 
		if (layer.type === 'image' && !layer.imageFile) {
			layer.imageFile = defaultImageLayer;
		}
		
    if ( layer.type === 'mask' && !layer.imageFile) {
			layer.imageFile = defaultMaskLayer;
	  }

		if (layer.type === 'userImage' && !layer.imageFile) {
			layer.imageFile = defaultUserImage;
	  }

		// set parse file
		if (layer.imageFile) {
			layer.imageTemp = layer.imageFile;
		}

		// init layers list mask 
		if (layer.type === 'mask') {
				layer.layers = [];
		}

		//set file to Parse.File
		if ( layer.imageFile ) {
			const testType = layer.imageFile instanceof Parse.File;
			if(!testType){
				const parseFile = new Parse.File(layer.id, layer.imageFile);
				layer.imageFile = parseFile;
			}
		}

		// add new mask items
		if (idMaskActive !== '') {
				const prevLayers = [...layers];
				const indexActiveMask = findIndex(prevLayers, l => l.id === idMaskActive);

				const maskItem = prevLayers[indexActiveMask];

				if (!maskItem.layers) {
					 maskItem.layers = [];
				}
				maskItem.layers.push(layer);
				fill(prevLayers, maskItem, indexActiveMask, indexActiveMask + 1);
				setLayers(prevLayers);
				
		} else {
			setLayers([...layers, layer])
		}
		_closeCreationModal();
		setHasChanged(true);
		update();
		// reset indexActiveMasque to -1 
		setIdMaskActive('');
	};

	const _submitLayer = () => {
		dispatch(submit('layerForm'));
	};

	const deleteLayer = layerIndex => {
		// remove selectedLayer after remove
		const item = nth(layers, layerIndex);
		if(item === selectedLayer){
			setSelectedLayer(null);
		}
		pullAt(layers, layerIndex);
		setLayers([...layers]);
		setHasChanged(true);
		update();
	};

	// checked the checkbox in mask layer
	const handleChangeUsedForPrint = (selectedLayer, value) => {
		const prevLayers = [...layers];

		const newLayers = prevLayers.map(layer => {
			const newLayer = { ...layer };
			if (layer.id === selectedLayer.id) {
				newLayer.usedForPrint = value;
			}

			return newLayer;
		});

		setHasChanged(true);
		setLayers([...newLayers]);
	};

	// set title dialog layer
	const handleSetTitleLayerDialog =  value => setLayerDialogTitle(value);

	const onZoom = value => {
		setZoom(value);
		setHasChanged(true);
	}
	const _regenerateImages = () => {
		dispatch(regenerateImages(template.id));
	};

	const _copyTemplateToProd = () => {
		dispatch(copyTemplateToProd(template.id));
	}

	const deselectLayer = () => {
		setSelectedLayer(null);
		setSelectedMaskItem(null);
		setSelectedMaskIndex(-1);
	};

	const _saveTemplateProperties = async (values) => {
		await dispatch(updateTemplate({template}, values, false));
		setHasChanged(true);
		update();
	};

	// change fields values (width,height,top, left) //
	const setInitValuesLayerForm  = values => {
		batch(() => {
			dispatch(change('propertiesForm', 'width', values.width));
			dispatch(change('propertiesForm', 'height', values.height));
			dispatch(change('propertiesForm', 'top', values.top));
			dispatch(change('propertiesForm', 'left', values.left));
		});
	};

	// ----show btn save + change properties form after resize  or drag layer ----- //
	const handleUpdateLayerPreview = (newLayer)=> {
		if (selectedLayer) {
			setInitValuesLayerForm(newLayer);
			setHasChanged(true);
		}
	};

	// -------------------- handle Mask layers ------------------------- //
	const findItemInLayers = (id) => {
		const indexSearch=findIndex(layers, l => l.id === id);

		return nth(layers,indexSearch);
	};

	const handleDeleteMaskChildreen =  (maskIndex, removeIndex) => { 
			const prevLayers = [...layers];
			const oldMask = nth(prevLayers,maskIndex);
			pullAt(oldMask.layers, removeIndex);
			fill(prevLayers, oldMask, maskIndex, maskIndex+1);
			setLayers(prevLayers);
			setHasChanged(true);
			update();
	};

	const layersOrdersMaskChanged = (maskIndex, sourceIndex, destinationIndex) => {
		const prevLayers = [...layers];
		const oldMask = nth(prevLayers,maskIndex);
		arrayMove.mutate(oldMask.layers, sourceIndex, destinationIndex);
		fill(prevLayers, oldMask, maskIndex, maskIndex+1);
		setLayers(prevLayers);
		setHasChanged(true);
		update();
	};

	const selectMaskItems = (maskIndex, maskItem) => {
		setSelectedMaskIndex(maskIndex);
		setSelectedMaskItem(maskItem);
		setSelectedLayer(null);
	};

	const handleLayerSelected = (item) => { 
		setSelectedLayer(item);
		setSelectedMaskIndex(-1);
		setSelectedMaskItem(null);
	};

	const onUpdateItemMaskPreview = (newItem) => {
		setInitValuesLayerForm(newItem);
		setHasChanged(true);
	};

	// initial values create layer 
	const initValuesLayer = () => {
		// default layer width and height
		let width = widthInPx;
		let height = heightInPx;
		
		if (idMaskActive !== '') {
			const mask = findItemInLayers(idMaskActive);
			width = mask.width;
			height = mask.height;
		}

		return {
			type: 'image',
			name: getRandomName('image'),
			width: width,
			height: height,
			top: 0,
			left: 0,
			// default values for text layer
			size: 18,
			font: 'Montserrat',
			color: '#A9A9A9',
			alignment: 'center',
			text: 'Text',
			// default values for mask layer
			usedForPrint: true,
		}
	};

	const _toggleLayerForm = (value) => {
		setTogleLayerForm(value);
	};

	const handleToggleVisibilityLayer = (layer,value) => {
		layer.hide = value;
		setHasChanged(true);
		update();
	};

	const headerActionButtons = (
		<Box>
			<Button color="primary" variant="contained" className={classes.regenerateButton} onClick={_regenerateImages}>
				Regénérer les images
			</Button>
			<Button color="primary" variant="contained" className={classes.regenerateButton} onClick={_copyTemplateToProd}>
				Copier vers Prod
			</Button>
		</Box>
	);

	const loadLayerPropertiesForm = () => {
		return (
			<div className={classNames(classes.layerContainer, toggleLayerForm && classes.layerContainerHidden)}>
				<div className={classes.toggleIcon}>
					{toggleLayerForm ?
						<IconButton
							aria-label="arrowUp"
							onClick={() => _toggleLayerForm(false)}
						>
							<ArrowUpward />
						</IconButton>
						: <IconButton
							aria-label="arrowDown"
							onClick={() => _toggleLayerForm(true)}
						>
							<ArrowDownward />
						</IconButton>
					}
				</div>
				<div className={classNames(classes.layerProperties, toggleLayerForm && classes.layerPropertiesHidden)}>
					<div className={classes.layerPropertiesTitle}>
						Propriétés du calque sélectionné
					</div>
					<PropertiesForm
						onSubmit={_saveLayerProperties}
						initialValues={layerInitialValues}
					/>
				</div>
			</div>
		);

	}

	const LayerPropertiesForm = useMemo(() => {
		if (selectedLayer || selectedMaskItem) {
			return loadLayerPropertiesForm();
		}
		return null;

	}, [selectedLayer, selectedMaskItem, toggleLayerForm]);

	return (
		<div className={classes.root}>
			<div className={classes.container}>
				{/*---- left bloc ----*/}
				<div className={classes.leftContainer}>
					<TemplateDetails 
						template={template}
						onSave={_saveTemplateProperties}
					/>
					<LayersList
						layers={layers}
						onOpenAddDialog={_showCreationModal}
						onOrderChange={layersOrderChanged}
						selectedLayer={selectedLayer}
						onSelection={handleLayerSelected}
						onDelete={deleteLayer}
						onDeselection={deselectLayer}
						onSelectItemsMask={selectMaskItems}
						onOrderItemsMask={layersOrdersMaskChanged}
						onRemoveItemMask={handleDeleteMaskChildreen}
						selectedItemsMask={selectedMaskItem}
						toggleVisibility={handleToggleVisibilityLayer}
						onChangeUsedForPrint={handleChangeUsedForPrint}
					/>
				</div>

				<ZoomPreview
					style={{
						width: widthInPx,
						height: heightInPx,
						backgroundColor: template.isTransparent() ? '#ffffff' : template.get('backgroundColor'),
					}}
					headerActions={headerActionButtons}
					footer={LayerPropertiesForm}
					zoom={zoom}
					onZoomChange={onZoom}
				>
					{layers && layers.map((layer, index) => {
						if (layer.type !== 'mask' && !layer.hide) {
							return (
								<LayerPreview
									layer={layer}
									key={index}
									selected={selectedLayer === layer}
									templateId={template.id}
									maxWidth={widthInPx}
									maxHeight={heightInPx}
									scale={zoom}
									border={borderWidth}
									onUpdatePreview={handleUpdateLayerPreview}
									onSelection={handleLayerSelected}
								/>
							)
						}
						// mask preview
						return (
							(!layer.hide &&
								<MaskPreview
									mask={layer}
									templateId={template.id}
									selected={selectedLayer === layer}
									selectedItem={selectedMaskItem}
									childrenSelected={selectedMaskIndex===index }
									onUpdateItemMaskPreview={onUpdateItemMaskPreview}
									maxWidth={widthInPx}
									maxHeight={heightInPx}
									key={index}
									scale={zoom}
									border={borderWidth}
									onSelection={handleLayerSelected}
								/>
							)
						)
					})}
				</ZoomPreview>
			</div>

			<CardActions classes={{ root: classNames(classes.actions) }}>
				<div className={classes.buttons}>
					<Button color='secondary' onClick={_cancel}>
						Annuler
					</Button>

					<Button
						color='primary'
						onClick={_saveTemplate}
						classes={{ root: classes.okBtn }}
						disabled={!hasChanged}
					>
						Enregistrer
					</Button>
				</div>
			</CardActions>

			<ModalDialog
				title={`Nouveau calque ${layerDialogTitle}` }
				isVisible={isOpenCreationModal}
				content={
					<LayerForm  
						onChangeLayerType={handleSetTitleLayerDialog}
						onSubmit={_addLayer}
						idMask={idMaskActive}
						initialValues={initValuesLayer()} 
						countLayers={countLayerName}
						heightDefault={initValuesLayer().height}
					/>
				}
				onClose={_closeCreationModal}
				labelCancel='Fermer'
				onConfirm={_submitLayer}
				labelConfirm='Ajouter'
			/>
		</div>
	);
};

TemplateEditor.propTypes = {
	template: PropTypes.object
};
export default TemplateEditor;