import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp'
import { Column } from 'devextreme-react/data-grid';
import { formatPrice } from '../../utils';

import Table from '../../components/devExpressTable/Table';
import CustomCell from '../../components/devExpressTable/CustomCell';
import { downloadMontagePdf } from '../../actions/montages';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles({
	root: {},
	cell: {
		composes: 'flexRow stretch center',
    height: 50,
	},
	right: {
		composes: 'justifyEnd'
	},
	imageContainer: {
		width: 60, 
		height: 60,
	},
	image: {
		width: '100%',
	}
});

const columns = ['montage', 'name', 'price', 'data'];

const ModalProductTable = ({ data }) => {
  const dispatch = useDispatch();

	const onRowClick = (value) => {
    	dispatch(downloadMontagePdf(value))
	}
	
	// styles
	const classes = useStyles();

	// table data sources
	const getDataSource = useCallback(() =>
		data?.items.map((item) => ({
			[columns[0]]: {orderId: data.number, productId: item.id, name: item.product.name},
			[columns[1]]: item.product.name,
			[columns[2]]: formatPrice(item.product.price, item.product.currency),
		}))
	, [data]);

	return (
		<div className={classes.root}>
			<Table
				dataSource={getDataSource()}
				showColumnLines={false}
				invisibleInputSearch={true}
				actionRender={(value) => (
					<IconButton
						aria-label='download'
						onClick={() => onRowClick(value.data.montage)}
					>
						<GetAppIcon />
					</IconButton> 
				)}
			>
				<Column
					dataField={columns[1]}
					caption="Nom"
					cellRender={({ data }) => <CustomCell value={data.name} />}
				/>
				<Column
					dataField={columns[2]}
					caption="Prix"
					cellRender={({ data }) => <CustomCell value={data.price} />}
				/>
			</Table>
		</div>
	)
}

ModalProductTable.propTypes = {
	data: PropTypes.any,
};
export default ModalProductTable;