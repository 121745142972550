import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

const useDebouncedEffect = (func, deps, delay = 250) => {
	const callback = useCallback(func, deps);
	useEffect(() => {
		const handler = setTimeout(() => {
			callback();
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [ callback, delay ]);
};

const ImagePreview = (props) => {
	// state
	const [ currentSrc, setCurrentSrc ] = useState(props.placeholder);
	const [ loading, setLoading ] = useState(true);

	// props
	const { src, alt = '', className = '', loadingClassName } = props;

	useDebouncedEffect(() => {
		setCurrentSrc(src)
		setLoading(false);
	}, [ src ], 1000);

	return (
		<img
			className={classNames(className, {[loadingClassName]: loading})}
			src={currentSrc}
			alt={alt}
			draggable={false}
		/>
	);
}

export default ImagePreview;