import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import PreviewIconButton from '../../components/buttons/PreviewIconButton';
import ListIconButton from '../../components/buttons/ListIconButton';
import DeleteIconButton from '../../components/buttons/DeleteIconButton';
import MontageForm from './MontageForm';

import { deleteMontage, getMontageValues, showMontage, showMontages, updateMontage } from '../../actions/montages';
import { getMontage } from '../../reducers/montages';
import { formatParseObjSelectOption } from '../../utils';

const MontageEdit = () => {
	// selectors
	const montage = useSelector(getMontage);

	// dispatch
	const dispatch = useDispatch();

	const getInitialValues = useCallback(() => {
		const newMontage = getMontageValues(montage);
		return {
			...newMontage,
			template: formatParseObjSelectOption(newMontage.template),
		};
	}, [montage]);

	if (!montage) return null;
	const montageId = montage.id;

	// save change
	const _save = async (values) => {
		await dispatch(updateMontage(montage, values));
		dispatch(showMontages());
	};
	
	const _submit = () => dispatch(submit('montageForm'));
	
	const handleDelete = (montage) => {
		dispatch(deleteMontage(montage));
		showMontages();
	};

	const actionHeaderButtons = (
		<div>
			<PreviewIconButton onShowPreview={() => showMontage(montage)} />
			<ListIconButton onShowList={showMontages} />
			<DeleteIconButton onDelete={() => handleDelete(montage)} />
		</div>
	);

	return (
		<CustomCard
			fullScreen
			title={'Editer le montage ' + montageId}
			content={
				<MontageForm
					montageId={montageId}
					onSubmit={_save}
					initialValues={getInitialValues()}
				/>
			}
			okLabel="Enregistrer la modification"
			okAction={_submit}
			actionHeaderButtons={actionHeaderButtons}
		/>

	)
}

MontageEdit.propTypes = {
	montage: PropTypes.object
};
export default MontageEdit;