import Parse from 'parse';

import { formatSavedParseObjSelectOptions } from '../utils';
import { getValues, setValues } from '../parseUtils';
import { onEnter, showParseObj, actionWithLoader, push, getPhotoAppURL } from './utils';
import { showHome } from './app';

import { getTheme, getThemes } from '../reducers/themes';

//--------------------------------------------------------//
//------------------ Parse <=> Object --------------------//
//--------------------------------------------------------//
const Theme = Parse.Object.extend('Theme');

const THEME_PROPERTIES = new Set([
	'name', 'templates','imageFile'
]);

/**
 * get values for current theme
 * @param theme
 * @returns {Object}
 */
export function getThemeValues(theme) {
	return getValues(theme, THEME_PROPERTIES);
}
/**
 * set theme values
 * @param theme
 * @param values
 */
export function setThemeValues(theme, values) {
	setValues(theme, values, THEME_PROPERTIES);
}
//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create new theme
 * @param values
 * @returns {*}
 */
export function createTheme(values) {
	const newValues = formatSavedParseObjSelectOptions(values, 'templates');

	return actionWithLoader(async (dispatch, getState) => {
		const themes = getThemes(getState());
		const theme = new Theme();
		
		setThemeValues(theme, newValues);
		await theme.save();

		dispatch({
			type: 'THEME_LOADED',
			theme: theme
		});
		dispatch({
			type: 'THEMES_UPDATED',
			themes: [theme, ...themes]
		});
	});
}

/**
 * update current theme
 * @param theme
 * @param values
 * @returns {*}
 */
export function updateTheme(theme, values) {

	return actionWithLoader(async (dispatch, getState) => {
		const newValues = { ...values };
		let newTemplates = [];

		// get the parse object selected value instead of string value
		for (const template of values.templates) {
			newTemplates.push(template.parseObj);
		}

		if (newTemplates.length > 0) {
			newValues.templates = newTemplates;
		} 

		setThemeValues(theme, newValues);
		await updateThemeThunk(theme)(dispatch, getState);
	});
}

/**
 * delete current theme
 * @param theme
 * @returns {*}
 */
export function deleteTheme(theme) {
	return actionWithLoader(async (dispatch, getState) => {
		const themes = getThemes(getState());
		const newThemes = themes.filter((m) => m !== theme);

		theme.set('deleted', true);
		await theme.save();
		dispatch({
			type: 'THEMES_UPDATED', // used in themes list
			themes: newThemes
		});

	});
}


/**
 * saves and updates theme
 * @param {Object} theme
 */
export function updateThemeThunk(theme) {
	return async (dispatch) => {
		await theme.save();
		dispatch({
			type: 'THEME_UPDATED',
			theme
		});
	}
}

/**
 * load all templates
 * @returns {Function}
 */
export function loadThemesThunk() {
	return async (dispatch) => {
		const themes = await new Parse.Query('Theme')
			.include('templates')
			.notEqualTo('deleted', true)
			.limit(1000)
			.find();

		if (themes && Array.isArray(themes)) {
			dispatch({
				type: 'THEMES_LOADED',
				themes
			});
		}
		return themes;
	}
}



/**
 * load themes of a template
 * @returns {Array}
 */
 export const getThemesByTemplate = async (template) => {
		const themes = await new Parse.Query('Theme')
			.equalTo('templates', template)
			.limit(1000)
			.find();

		return themes;
}

/**
 * load themes with pagination
 * @returns {Function}
 */
 export function loadThemesPerPage(limit=10, skip=0) {
	return async () => {
		const themes = await new Parse.Query('Theme')
			.include('templates')
			.notEqualTo('deleted', true)
			.limit(limit)
			.skip(skip)
			.withCount(true)
			.find();

		return {
			data: themes.results,
			totalCount: themes.count
		};
	}
}

/**
 * onEnter themes
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterThemes(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				const themes = await loadThemesThunk()(dispatch, getState);
				if (!themes) {
					showHome();
				}
			}
		}
	});
}

//--------------------------------------------------------//
//------------------ loading template --------------------//
//--------------------------------------------------------//
/**
 * onEnter template preview or edit page
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export function onEnterTheme(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				const themeId = params.themeId;
				const theme = await loadThemeThunk(themeId)(dispatch, getState);
				if (!theme) {
					// template not found
					showHome();
				}
			}
		}
	});
}

/**
 * load theme into redux
 * @param themeId
 * @returns {function(*, *): Promise<*>}
 */
export function loadThemeThunk(themeId) {
	return async (dispatch, getState) => {
		const currentTheme = getTheme(getState());
		if (!currentTheme || currentTheme.id !== themeId) {
			// loading template
			const theme = await new Parse.Query('Theme')
				.include('templates')
				.equalTo('objectId', themeId)
				.first();

			dispatch({
				type: 'THEME_LOADED',
				theme
			});
			return theme;
		}

		return currentTheme;
	}
}

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show theme
 * @param themeId
 * @param fromNewTab
 */
export function showTheme(themeId, fromNewTab= false) {
	if (fromNewTab) {
		const url = getPhotoAppURL() + '/theme-' + themeId
		window.open(url);
		return;
	}
	return showParseObj('theme', themeId);
}

export function showThemeCreation() {
	return push('/themeCreation');
}

export function showThemes() {
	return push('/themes');
}
export function showThemeEdit(themeId) {
	return push('/themeEdit-' + themeId)
}