import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import AddIconButton from '../../components/buttons/AddIconButton';
import ModalDialog from '../../components/ModalDialog';

import TemplatesTable from './TemplatesTable';
import TemplateForm, {ToTemplateProperties} from './TemplateForm';

import { createTemplate } from '../../actions/templates';
import { getTemplates } from '../../reducers/templates';

const initialValues = {
  backgroundColor: '#ffffff',
  transparent: true
}
const Templates = () => {
  
  useEffect(() => {

  }, []);
  
	// state
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  // selectors
  const templates = useSelector(getTemplates) || [];

	// dialog actions
  const _openDialog = () => setOpen(true);
	const _closeDialog = () => setOpen(false);

	// save change
	const _createTemplate = async values => {
		const newValues = ToTemplateProperties(values);
		await dispatch(createTemplate(newValues)).then(() => {
			setReload(!!!reload);
		});
		_closeDialog();
	};
	
	// form submit
  const _submit = () => {
    dispatch(submit('templateForm'))
  };

  return (
    <>
      <CustomCard
        title='Liste des templates'
        content={<TemplatesTable isReloaded={reload} onReload={setReload} rows={templates} />}
        actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
        withActionButtons={false}
        fullScreen
      />
      <ModalDialog
				title="Ajouter Nouveau template"
        content={
          <TemplateForm 
            onSubmit={_createTemplate} 
            initialValues={initialValues}
          />
        }
				isVisible={open}
				onConfirm={_submit}
				onClose={_closeDialog}
			/>
      
    </>
  );
};

Templates.propTypes = {
  templates: PropTypes.array,
};
export default Templates;
