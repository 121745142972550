import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import AddIconButton from '../../components/buttons/AddIconButton';
import ModalDialog from '../../components/ModalDialog';
import PromoBannersTable from './PromoBannersTable';
import PromoBannerForm from './PromoBannerForm';
import { getPromoBanners } from '../../reducers/promoBanners';
import { createOrUpdatePromoBanner } from '../../actions/promoBanners';
import { validatePromoBanner } from '../../validation';

const PromoBanners = () => {
	// state
	const [open, setOpen] = useState(false);

	// dispatch
	const dispatch = useDispatch();
	
	// selectors
	const promoBanners = useSelector(getPromoBanners) || [];
	
	// dialog actions
	const _openDialog = () => setOpen(true);
	const _closeDialog = () => setOpen(false);
	
	// save change
	const _createPromoBanner = async (values) => {
		validatePromoBanner(values);

		// create new promo banner
		await dispatch(createOrUpdatePromoBanner(values));
		_closeDialog();
	};
	
	// form submit
	const _submit = () => dispatch(submit('promoBannerForm'));

	return (
		<>
			<CustomCard
				title='Liste des bannières de promotion'
				content={<PromoBannersTable data={promoBanners} />}
				withActionButtons={false}
				actionHeaderButtons={<AddIconButton onAdd={_openDialog} />}
				fullScreen
			/>
			<ModalDialog
				title="Ajouter Nouveau bannière de promotion"
				content={<PromoBannerForm onSubmit={_createPromoBanner} />}
				isVisible={open}
				onConfirm={_submit}
				onClose={_closeDialog}
			/>		
		</>

	)
}

export default PromoBanners;