import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import ListIconButton from '../../components/buttons/ListIconButton';
import ThemeForm from './ThemeForm';

import { createTheme, showThemes } from '../../actions/themes';

const ThemeCreation = () => {
	// dispatch
	const dispatch = useDispatch();

	// show themes list
	const _goToThemeListPage = () => {
		dispatch(showThemes());
	};

	// save change
	const _createTheme = async(values) => {
		// create new theme
		await dispatch(createTheme(values));
		_goToThemeListPage();
	};

	const _submit = () => {
		dispatch(submit('themeForm'));
	};

	return (
		<CustomCard
			fullScreen
			title='Nouveau thème'
			content={
				<ThemeForm
					onSubmit={_createTheme}
				/>
			}
			okLabel="Enregistrer"
			okAction={_submit}
			actionHeaderButtons={
				<ListIconButton
					onShowList={_goToThemeListPage}
				/>
			}
		/>
	)
}

export default ThemeCreation;