import React from 'react';
import PropTypes from 'prop-types';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IconButton } from '@material-ui/core';

const PreviewIconButton = (props) => {
	// props
	const { onShowPreview } = props;

	return (
		<IconButton
			aria-label='preview'
			onClick={onShowPreview}
		>
			<ChevronRightIcon />
		</IconButton>
	);
}

PreviewIconButton.propTypes = {
	onShowPreview: PropTypes.func
}
export default PreviewIconButton;