import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import MenuItemIcon from './MenuItemIcon';

const useStyles = makeStyles({
	selected: {
		backgroundColor: 'rgba(0,0,0,0.08)',
		fontWeight: 'bold'
	},
});

const RootMenuItem = (props) => {
	// props
	const { label, name, selected, onClick } = props;

	// styles
	const classes = useStyles();

	const _onClick = () => {
		onClick && onClick(name);
	};

	return (
		<ListItem 
			button 
			key={label} 
			onClick={_onClick}
			className={selected ? classes.selected : null}
		>
			<ListItemIcon>
				<MenuItemIcon name={name} />
			</ListItemIcon>
			<ListItemText primary={label} />
		</ListItem>
	)
};

RootMenuItem.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	onClick: PropTypes.func,
	isVisible: PropTypes.bool,
	selected: PropTypes.bool
};
export default RootMenuItem;