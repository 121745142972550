import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import CodeEditor from './CodeEditor';

const BlockCode = (props) => {

  // props
  const { data, title, type = 'request' } = props;

  return (
    <Box flex={1} mr={type === 'request' ? 3 : 0} ml={type === 'response' ? 3 : 0}>

      {/* ----------------------- title ----------------------- */}
      {title && (
        <Box mb={1}>
          <Typography variant="h6">
            {title}
          </Typography>
        </Box>
      )}

      {/* ----------------------- url ----------------------- */}
      {data?.code[type].url && (
        <Box mb={1}>
          <Typography variant="subtitle1" gutterBottom>
            <a href={data.code[type].url}>{data.code[type].url}</a>
          </Typography>
        </Box>
      )}
      
      {/* ----------------------- editor ----------------------- */}
      {data && (
        <Box>
          <CodeEditor
            value={data.code[type].value}
            language={data.code[type].language || type === 'request' ? 'js' : 'json'}
            scrollable={data.code[type].scrollable}
          />
        </Box>
      )}
    </Box>
  );
}

BlockCode.propTypes = {
  data: PropTypes.any,
  type: PropTypes.oneOf(['request', 'response']),
  title: PropTypes.any,
};

export default BlockCode;