import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import ZoomButtons from './ZoomButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    composes: 'flex1 flexColumn stretch',
    border: theme.border.grey[600],
    height: '90vh',
    overflow: 'auto',
		// width : 700
  },
  zoomPreview: {
      maxWidth: '78vw',
		overflow: 'auto',
	},
	buttonHeader: {
		composes: 'flexRow spaceBetween',
	},
  preview: {
    paddingLeft: 10,
    paddingRight: 10,
    flex: 1,
		overflow:'auto',
		position : 'relative'
	},
}));

const ZoomPreview = ({ 
  children, headerActions, style, 
  headerTitle, rootClassName, 
  previewClassName, contentClassName,
  headerClassName, zoom, onZoomChange,
  buttonsClassName, footer
}) => {

  const classes = useStyles();

	return (
    <div className={classNames(rootClassName, classes.root)}>
      <Box display="flex" justifyContent="space-between" className={headerClassName}>
        <Box display="flex" alignItems="center">
          {headerTitle}
          <ZoomButtons
            onChange={onZoomChange}
            value={zoom}
            className={classNames(buttonsClassName, classes.buttonHeader)}
          />
        </Box>

        {headerActions && (
          <Box diplay="flex">
            {headerActions}
          </Box>
        )}
      </Box>

      <div className={classNames(previewClassName, classes.zoomPreview)}>
        <div
          style={{ transform: `scale(${zoom})`, ...style }}
          className={contentClassName}
        >
          {children}
        </div>
      </div>
        {footer}
    </div>
	);
};

ZoomPreview.propTypes = {
	children: PropTypes.node.isRequired,
	headerActions: PropTypes.node,
	headertitle: PropTypes.node,
    footer: PropTypes.node,
	style: PropTypes.any,
	rootClassName: PropTypes.string,
	previewClassName: PropTypes.string,
	contentClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  buttonsClassName: PropTypes.string,
	zoom: PropTypes.number,
	onZoomChange: PropTypes.func,

};

export default ZoomPreview;