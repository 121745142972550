import React from 'react';
import uid from 'uid';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReduxFormSelect = props => {
	const {
		input: { value, onChange },
		meta: { error },
		children, multiple, className,
		readOnly = false, renderValue,
		...otherProps
	} = props;
	const selected = value || [];

	const _onChange = event => {
		onChange(event.target.value);
	};

	return (
		<div className="width100">
			<Select
				value={selected} onChange={_onChange}
				input={<Input id={'select_' + uid(4)} readOnly={readOnly} />}
				children={children}
				multiple={multiple}
				classes={{select: className }}
				renderValue={renderValue}
				{...otherProps}
			>
				{children}
			</Select>
			{error && <FormHelperText error>{error}</FormHelperText>}
		</div>
	);
};

export default ReduxFormSelect;