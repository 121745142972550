import React from 'react';
import PropTypes from 'prop-types';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButton } from '@material-ui/core';

const ZoomOutButton = ({ onRemove, color = 'primary', className }) => (
		<IconButton
			aria-label='add'
			onClick={onRemove}
			color={color}
			className={className}
		>
			<RemoveIcon />
		</IconButton>
)

ZoomOutButton.propTypes = {
	onRemove: PropTypes.func,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	className: PropTypes.string
}
export default ZoomOutButton;