import React from 'react';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';
import { IconButton } from '@material-ui/core';

const RefreshButton = ({ onRefresh, color = 'primary', className }) => (
    <IconButton
        aria-label='refresh'
        onClick={onRefresh}
        color={color}
        className={className}
    >
        <RefreshIcon />
    </IconButton>
);

RefreshButton.propTypes = {
    onRefresh: PropTypes.func,
    color: PropTypes.oneOf(['default', 'primary', 'secondary']),
    className: PropTypes.string
}
export default RefreshButton;