import React, { useState } from 'react';
import { createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import dedent from 'dedent-tabs';
import TabPanel from './TabPanel';
import BlockCode from './BlockCode';
import LinksTest from './LinksTest';

const tests = [
	{
		label: 'Test',
		code: {
			request: {
				value: 'test'
			}
		}
	},
  /** --------------------------- 1 ------------------------------- */
  {
    label: 'Parse Initialization',
    code: {
      request: {
        value: dedent`
            // installation
            npm install parse --save

            // initialization
            import Parse from 'parse/react-native';
            import AsyncStorage from '@react-native-community/async-storage';

            Parse.setAsyncStorage(AsyncStorage);
            
            Parse.initialize('iota');
            Parse.serverURL = 'https://bo.yourz.me/parse';

            // serverURL for prod :https://bo-preprod.yourz.me//parse
          `,
      },
    },
  },

  /** --------------------------- 2 ------------------------------- */
  {
    label: "Liste des thèmes et templates pour un produit",
    code: {
      request: {
        value: dedent`
          const productId = '5ff48d1cfa85272ce29eb20c';
  
          // all themes fetching
          const allThemes = await new Parse.Query("Theme")
            .include("templates")
            .find();
  
          // filtering to keep only the themes with the right templates 
          const themes = [];
          allThemes.forEach(theme => {
            const templatesWithProduct = new Set();
    
            theme
              .get("templates")
              .filter(template => template.has("products"))
              .forEach(template => {

                // test if the template has the current product
                const index = template
                  .get("products")
                  .findIndex((product) => product.id === productId);

                if (index >= 0) {
                  templatesWithProduct.add(template);
                }
              });
  
            if (templatesWithProduct.size) {
              themes.push({ theme: theme, templates: templatesWithProduct });
            }
          })
    
          console.log("themes: ", themes);
          `,
      },
      response: {
        value: dedent`
          [
            {
              "theme": {
                "templates": [
                  {
                    "products": [
                      {
                        "id": "5fc0f7f0071d831145ae876a",
                        "name": "Lot de 5 grands magnets"
                      }
                    ],
                    "themes": [
                      {
                        "templates": [
                          {
                            "__type": "Pointer",
                            "className": "Template",
                            "objectId": "fAhK6wbqpT"
                          },
                          {
                            "products": [
                              {
                                "id": "5fc0fadf69c6b00e0b7582c7",
                                "name": "Magnet 9x13"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "height": 543,
                            "width": 600,
                            "name": "template T2",
                            "createdAt": "2021-01-06T18:46:55.099Z",
                            "updatedAt": "2021-01-06T18:46:57.601Z",
                            "objectId": "0IkZCO2xdf",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff491e4ef9ee12e7859a35d",
                                "name": "Lot de 4 sets de table classiques"
                              },
                              {
                                "id": "5fc0fae069c6b00e0b758bd5",
                                "name": "Manique"
                              }
                            ],
                            "themes": [
                              {
                                "name": "Theme 1",
                                "createdAt": "2020-12-17T13:25:36.545Z",
                                "updatedAt": "2021-01-06T18:58:25.625Z",
                                "templates": [
                                  {
                                    "products": [
                                      {
                                        "id": "5fbea8fb5a56903bf182168e",
                                        "name": "Magnet large 25x40"
                                      }
                                    ],
                                    "themes": [
                                      {
                                        "__type": "Pointer",
                                        "className": "Theme",
                                        "objectId": "2gRMxXzTUV"
                                      }
                                    ],
                                    "name": "template t4",
                                    "width": 800,
                                    "height": 680,
                                    "createdAt": "2021-01-06T18:58:24.333Z",
                                    "updatedAt": "2021-01-06T18:58:26.584Z",
                                    "objectId": "nHBynClNGF",
                                    "__type": "Object",
                                    "className": "Template"
                                  },
                                  {
                                    "__type": "Pointer",
                                    "className": "Template",
                                    "objectId": "kgHpLivzNG"
                                  }
                                ],
                                "objectId": "2gRMxXzTUV",
                                "__type": "Object",
                                "className": "Theme"
                              },
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template T1",
                            "width": 345,
                            "height": 253.97,
                            "createdAt": "2021-01-06T18:41:19.817Z",
                            "updatedAt": "2021-01-06T18:41:23.244Z",
                            "objectId": "kgHpLivzNG",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff582d8bee80d0c84da0849",
                                "name": "Set de table textile"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template 1",
                            "width": 1000,
                            "height": 600,
                            "createdAt": "2021-01-06T14:54:45.782Z",
                            "updatedAt": "2021-01-06T14:54:48.126Z",
                            "objectId": "1FYxQBDGAB",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff48d1cfa85272ce29eb20c",
                                "name": "Set de table classique"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "7wiKLQKA6r"
                              }
                            ],
                            "name": "Template 01",
                            "width": 500,
                            "height": 300,
                            "createdAt": "2021-01-06T11:58:22.674Z",
                            "updatedAt": "2021-01-06T14:54:05.492Z",
                            "layers": [
                              {
                                "id": "q6ehtf0uzx",
                                "content": "fond",
                                "name": "fond",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "left": 147,
                                "top": 107,
                                "zIndex": 4,
                                "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                              },
                              {
                                "id": "5z8pwvfd5u",
                                "content": "User Image",
                                "name": "User Image",
                                "type": "userImage",
                                "width": 200,
                                "height": 150,
                                "top": 162,
                                "left": 69,
                                "zIndex": 3
                              },
                              {
                                "id": "qlvi2wyvgy",
                                "content": "fond 02",
                                "name": "fond 02",
                                "type": "image",
                                "width": 300,
                                "height": 200,
                                "top": 50,
                                "left": 50,
                                "zIndex": 2,
                                "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                              },
                              {
                                "id": "tryof56yc9",
                                "name": "Test",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "top": 20,
                                "left": 50,
                                "zIndex": 1,
                                "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                              }
                            ],
                            "objectId": "GHFM0bfK0Y",
                            "__type": "Object",
                            "className": "Template"
                          }
                        ],
                        "name": "Theme Hiver",
                        "createdAt": "2021-01-06T14:52:24.502Z",
                        "updatedAt": "2021-01-06T18:47:53.834Z",
                        "objectId": "QIvMbANGdd",
                        "__type": "Object",
                        "className": "Theme"
                      }
                    ],
                    "name": "template t3",
                    "width": 747,
                    "height": 638,
                    "createdAt": "2021-01-06T18:47:52.439Z",
                    "updatedAt": "2021-01-06T18:47:54.775Z",
                    "objectId": "fAhK6wbqpT",
                    "__type": "Object",
                    "className": "Template"
                  },
                  {
                    "products": [
                      {
                        "id": "5fc0fadf69c6b00e0b7582c7",
                        "name": "Magnet 9x13"
                      }
                    ],
                    "themes": [
                      {
                        "templates": [
                          {
                            "products": [
                              {
                                "id": "5fc0f7f0071d831145ae876a",
                                "name": "Lot de 5 grands magnets"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "template t3",
                            "width": 747,
                            "height": 638,
                            "createdAt": "2021-01-06T18:47:52.439Z",
                            "updatedAt": "2021-01-06T18:47:54.775Z",
                            "objectId": "fAhK6wbqpT",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "__type": "Pointer",
                            "className": "Template",
                            "objectId": "0IkZCO2xdf"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff491e4ef9ee12e7859a35d",
                                "name": "Lot de 4 sets de table classiques"
                              },
                              {
                                "id": "5fc0fae069c6b00e0b758bd5",
                                "name": "Manique"
                              }
                            ],
                            "themes": [
                              {
                                "name": "Theme 1",
                                "createdAt": "2020-12-17T13:25:36.545Z",
                                "updatedAt": "2021-01-06T18:58:25.625Z",
                                "templates": [
                                  {
                                    "products": [
                                      {
                                        "id": "5fbea8fb5a56903bf182168e",
                                        "name": "Magnet large 25x40"
                                      }
                                    ],
                                    "themes": [
                                      {
                                        "__type": "Pointer",
                                        "className": "Theme",
                                        "objectId": "2gRMxXzTUV"
                                      }
                                    ],
                                    "name": "template t4",
                                    "width": 800,
                                    "height": 680,
                                    "createdAt": "2021-01-06T18:58:24.333Z",
                                    "updatedAt": "2021-01-06T18:58:26.584Z",
                                    "objectId": "nHBynClNGF",
                                    "__type": "Object",
                                    "className": "Template"
                                  },
                                  {
                                    "__type": "Pointer",
                                    "className": "Template",
                                    "objectId": "kgHpLivzNG"
                                  }
                                ],
                                "objectId": "2gRMxXzTUV",
                                "__type": "Object",
                                "className": "Theme"
                              },
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template T1",
                            "width": 345,
                            "height": 253.97,
                            "createdAt": "2021-01-06T18:41:19.817Z",
                            "updatedAt": "2021-01-06T18:41:23.244Z",
                            "objectId": "kgHpLivzNG",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff582d8bee80d0c84da0849",
                                "name": "Set de table textile"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template 1",
                            "width": 1000,
                            "height": 600,
                            "createdAt": "2021-01-06T14:54:45.782Z",
                            "updatedAt": "2021-01-06T14:54:48.126Z",
                            "objectId": "1FYxQBDGAB",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff48d1cfa85272ce29eb20c",
                                "name": "Set de table classique"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "7wiKLQKA6r"
                              }
                            ],
                            "name": "Template 01",
                            "width": 500,
                            "height": 300,
                            "createdAt": "2021-01-06T11:58:22.674Z",
                            "updatedAt": "2021-01-06T14:54:05.492Z",
                            "layers": [
                              {
                                "id": "q6ehtf0uzx",
                                "content": "fond",
                                "name": "fond",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "left": 147,
                                "top": 107,
                                "zIndex": 4,
                                "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                              },
                              {
                                "id": "5z8pwvfd5u",
                                "content": "User Image",
                                "name": "User Image",
                                "type": "userImage",
                                "width": 200,
                                "height": 150,
                                "top": 162,
                                "left": 69,
                                "zIndex": 3
                              },
                              {
                                "id": "qlvi2wyvgy",
                                "content": "fond 02",
                                "name": "fond 02",
                                "type": "image",
                                "width": 300,
                                "height": 200,
                                "top": 50,
                                "left": 50,
                                "zIndex": 2,
                                "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                              },
                              {
                                "id": "tryof56yc9",
                                "name": "Test",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "top": 20,
                                "left": 50,
                                "zIndex": 1,
                                "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                              }
                            ],
                            "objectId": "GHFM0bfK0Y",
                            "__type": "Object",
                            "className": "Template"
                          }
                        ],
                        "name": "Theme Hiver",
                        "createdAt": "2021-01-06T14:52:24.502Z",
                        "updatedAt": "2021-01-06T18:47:53.834Z",
                        "objectId": "QIvMbANGdd",
                        "__type": "Object",
                        "className": "Theme"
                      }
                    ],
                    "height": 543,
                    "width": 600,
                    "name": "template T2",
                    "createdAt": "2021-01-06T18:46:55.099Z",
                    "updatedAt": "2021-01-06T18:46:57.601Z",
                    "objectId": "0IkZCO2xdf",
                    "__type": "Object",
                    "className": "Template"
                  },
                  {
                    "products": [
                      {
                        "id": "5ff491e4ef9ee12e7859a35d",
                        "name": "Lot de 4 sets de table classiques"
                      },
                      {
                        "id": "5fc0fae069c6b00e0b758bd5",
                        "name": "Manique"
                      }
                    ],
                    "themes": [
                      {
                        "name": "Theme 1",
                        "createdAt": "2020-12-17T13:25:36.545Z",
                        "updatedAt": "2021-01-06T18:58:25.625Z",
                        "templates": [
                          {
                            "products": [
                              {
                                "id": "5fbea8fb5a56903bf182168e",
                                "name": "Magnet large 25x40"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "2gRMxXzTUV"
                              }
                            ],
                            "name": "template t4",
                            "width": 800,
                            "height": 680,
                            "createdAt": "2021-01-06T18:58:24.333Z",
                            "updatedAt": "2021-01-06T18:58:26.584Z",
                            "objectId": "nHBynClNGF",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "__type": "Pointer",
                            "className": "Template",
                            "objectId": "kgHpLivzNG"
                          }
                        ],
                        "objectId": "2gRMxXzTUV",
                        "__type": "Object",
                        "className": "Theme"
                      },
                      {
                        "templates": [
                          {
                            "products": [
                              {
                                "id": "5fc0f7f0071d831145ae876a",
                                "name": "Lot de 5 grands magnets"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "template t3",
                            "width": 747,
                            "height": 638,
                            "createdAt": "2021-01-06T18:47:52.439Z",
                            "updatedAt": "2021-01-06T18:47:54.775Z",
                            "objectId": "fAhK6wbqpT",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5fc0fadf69c6b00e0b7582c7",
                                "name": "Magnet 9x13"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "height": 543,
                            "width": 600,
                            "name": "template T2",
                            "createdAt": "2021-01-06T18:46:55.099Z",
                            "updatedAt": "2021-01-06T18:46:57.601Z",
                            "objectId": "0IkZCO2xdf",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "__type": "Pointer",
                            "className": "Template",
                            "objectId": "kgHpLivzNG"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff582d8bee80d0c84da0849",
                                "name": "Set de table textile"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template 1",
                            "width": 1000,
                            "height": 600,
                            "createdAt": "2021-01-06T14:54:45.782Z",
                            "updatedAt": "2021-01-06T14:54:48.126Z",
                            "objectId": "1FYxQBDGAB",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff48d1cfa85272ce29eb20c",
                                "name": "Set de table classique"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "7wiKLQKA6r"
                              }
                            ],
                            "name": "Template 01",
                            "width": 500,
                            "height": 300,
                            "createdAt": "2021-01-06T11:58:22.674Z",
                            "updatedAt": "2021-01-06T14:54:05.492Z",
                            "layers": [
                              {
                                "id": "q6ehtf0uzx",
                                "content": "fond",
                                "name": "fond",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "left": 147,
                                "top": 107,
                                "zIndex": 4,
                                "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                              },
                              {
                                "id": "5z8pwvfd5u",
                                "content": "User Image",
                                "name": "User Image",
                                "type": "userImage",
                                "width": 200,
                                "height": 150,
                                "top": 162,
                                "left": 69,
                                "zIndex": 3
                              },
                              {
                                "id": "qlvi2wyvgy",
                                "content": "fond 02",
                                "name": "fond 02",
                                "type": "image",
                                "width": 300,
                                "height": 200,
                                "top": 50,
                                "left": 50,
                                "zIndex": 2,
                                "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                              },
                              {
                                "id": "tryof56yc9",
                                "name": "Test",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "top": 20,
                                "left": 50,
                                "zIndex": 1,
                                "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                              }
                            ],
                            "objectId": "GHFM0bfK0Y",
                            "__type": "Object",
                            "className": "Template"
                          }
                        ],
                        "name": "Theme Hiver",
                        "createdAt": "2021-01-06T14:52:24.502Z",
                        "updatedAt": "2021-01-06T18:47:53.834Z",
                        "objectId": "QIvMbANGdd",
                        "__type": "Object",
                        "className": "Theme"
                      }
                    ],
                    "name": "Template T1",
                    "width": 345,
                    "height": 253.97,
                    "createdAt": "2021-01-06T18:41:19.817Z",
                    "updatedAt": "2021-01-06T18:41:23.244Z",
                    "objectId": "kgHpLivzNG",
                    "__type": "Object",
                    "className": "Template"
                  },
                  {
                    "products": [
                      {
                        "id": "5ff582d8bee80d0c84da0849",
                        "name": "Set de table textile"
                      }
                    ],
                    "themes": [
                      {
                        "templates": [
                          {
                            "products": [
                              {
                                "id": "5fc0f7f0071d831145ae876a",
                                "name": "Lot de 5 grands magnets"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "template t3",
                            "width": 747,
                            "height": 638,
                            "createdAt": "2021-01-06T18:47:52.439Z",
                            "updatedAt": "2021-01-06T18:47:54.775Z",
                            "objectId": "fAhK6wbqpT",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5fc0fadf69c6b00e0b7582c7",
                                "name": "Magnet 9x13"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "height": 543,
                            "width": 600,
                            "name": "template T2",
                            "createdAt": "2021-01-06T18:46:55.099Z",
                            "updatedAt": "2021-01-06T18:46:57.601Z",
                            "objectId": "0IkZCO2xdf",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff491e4ef9ee12e7859a35d",
                                "name": "Lot de 4 sets de table classiques"
                              },
                              {
                                "id": "5fc0fae069c6b00e0b758bd5",
                                "name": "Manique"
                              }
                            ],
                            "themes": [
                              {
                                "name": "Theme 1",
                                "createdAt": "2020-12-17T13:25:36.545Z",
                                "updatedAt": "2021-01-06T18:58:25.625Z",
                                "templates": [
                                  {
                                    "products": [
                                      {
                                        "id": "5fbea8fb5a56903bf182168e",
                                        "name": "Magnet large 25x40"
                                      }
                                    ],
                                    "themes": [
                                      {
                                        "__type": "Pointer",
                                        "className": "Theme",
                                        "objectId": "2gRMxXzTUV"
                                      }
                                    ],
                                    "name": "template t4",
                                    "width": 800,
                                    "height": 680,
                                    "createdAt": "2021-01-06T18:58:24.333Z",
                                    "updatedAt": "2021-01-06T18:58:26.584Z",
                                    "objectId": "nHBynClNGF",
                                    "__type": "Object",
                                    "className": "Template"
                                  },
                                  {
                                    "__type": "Pointer",
                                    "className": "Template",
                                    "objectId": "kgHpLivzNG"
                                  }
                                ],
                                "objectId": "2gRMxXzTUV",
                                "__type": "Object",
                                "className": "Theme"
                              },
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "QIvMbANGdd"
                              }
                            ],
                            "name": "Template T1",
                            "width": 345,
                            "height": 253.97,
                            "createdAt": "2021-01-06T18:41:19.817Z",
                            "updatedAt": "2021-01-06T18:41:23.244Z",
                            "objectId": "kgHpLivzNG",
                            "__type": "Object",
                            "className": "Template"
                          },
                          {
                            "__type": "Pointer",
                            "className": "Template",
                            "objectId": "1FYxQBDGAB"
                          },
                          {
                            "products": [
                              {
                                "id": "5ff48d1cfa85272ce29eb20c",
                                "name": "Set de table classique"
                              }
                            ],
                            "themes": [
                              {
                                "__type": "Pointer",
                                "className": "Theme",
                                "objectId": "7wiKLQKA6r"
                              }
                            ],
                            "name": "Template 01",
                            "width": 500,
                            "height": 300,
                            "createdAt": "2021-01-06T11:58:22.674Z",
                            "updatedAt": "2021-01-06T14:54:05.492Z",
                            "layers": [
                              {
                                "id": "q6ehtf0uzx",
                                "content": "fond",
                                "name": "fond",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "left": 147,
                                "top": 107,
                                "zIndex": 4,
                                "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                              },
                              {
                                "id": "5z8pwvfd5u",
                                "content": "User Image",
                                "name": "User Image",
                                "type": "userImage",
                                "width": 200,
                                "height": 150,
                                "top": 162,
                                "left": 69,
                                "zIndex": 3
                              },
                              {
                                "id": "qlvi2wyvgy",
                                "content": "fond 02",
                                "name": "fond 02",
                                "type": "image",
                                "width": 300,
                                "height": 200,
                                "top": 50,
                                "left": 50,
                                "zIndex": 2,
                                "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                              },
                              {
                                "id": "tryof56yc9",
                                "name": "Test",
                                "type": "image",
                                "width": 200,
                                "height": 200,
                                "top": 20,
                                "left": 50,
                                "zIndex": 1,
                                "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                              }
                            ],
                            "objectId": "GHFM0bfK0Y",
                            "__type": "Object",
                            "className": "Template"
                          }
                        ],
                        "name": "Theme Hiver",
                        "createdAt": "2021-01-06T14:52:24.502Z",
                        "updatedAt": "2021-01-06T18:47:53.834Z",
                        "objectId": "QIvMbANGdd",
                        "__type": "Object",
                        "className": "Theme"
                      }
                    ],
                    "name": "Template 1",
                    "width": 1000,
                    "height": 600,
                    "createdAt": "2021-01-06T14:54:45.782Z",
                    "updatedAt": "2021-01-06T14:54:48.126Z",
                    "objectId": "1FYxQBDGAB",
                    "__type": "Object",
                    "className": "Template"
                  },
                  {
                    "products": [
                      {
                        "id": "5ff48d1cfa85272ce29eb20c",
                        "name": "Set de table classique"
                      }
                    ],
                    "themes": [
                      {
                        "__type": "Pointer",
                        "className": "Theme",
                        "objectId": "7wiKLQKA6r"
                      }
                    ],
                    "name": "Template 01",
                    "width": 500,
                    "height": 300,
                    "createdAt": "2021-01-06T11:58:22.674Z",
                    "updatedAt": "2021-01-06T14:54:05.492Z",
                    "layers": [
                      {
                        "id": "q6ehtf0uzx",
                        "content": "fond",
                        "name": "fond",
                        "type": "image",
                        "width": 200,
                        "height": 200,
                        "left": 147,
                        "top": 107,
                        "zIndex": 4,
                        "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                      },
                      {
                        "id": "5z8pwvfd5u",
                        "content": "User Image",
                        "name": "User Image",
                        "type": "userImage",
                        "width": 200,
                        "height": 150,
                        "top": 162,
                        "left": 69,
                        "zIndex": 3
                      },
                      {
                        "id": "qlvi2wyvgy",
                        "content": "fond 02",
                        "name": "fond 02",
                        "type": "image",
                        "width": 300,
                        "height": 200,
                        "top": 50,
                        "left": 50,
                        "zIndex": 2,
                        "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                      },
                      {
                        "id": "tryof56yc9",
                        "name": "Test",
                        "type": "image",
                        "width": 200,
                        "height": 200,
                        "top": 20,
                        "left": 50,
                        "zIndex": 1,
                        "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                      }
                    ],
                    "objectId": "GHFM0bfK0Y",
                    "__type": "Object",
                    "className": "Template"
                  }
                ],
                "name": "Theme Hiver",
                "createdAt": "2021-01-06T14:52:24.502Z",
                "updatedAt": "2021-01-06T18:47:53.834Z",
                "objectId": "QIvMbANGdd"
              },
              "templates": [
                {
                  "products": [
                    {
                      "id": "5ff48d1cfa85272ce29eb20c",
                      "name": "Set de table classique"
                    }
                  ],
                  "themes": [
                    {
                      "__type": "Pointer",
                      "className": "Theme",
                      "objectId": "7wiKLQKA6r"
                    }
                  ],
                  "name": "Template 01",
                  "width": 500,
                  "height": 300,
                  "createdAt": "2021-01-06T11:58:22.674Z",
                  "updatedAt": "2021-01-06T14:54:05.492Z",
                  "layers": [
                    {
                      "id": "q6ehtf0uzx",
                      "content": "fond",
                      "name": "fond",
                      "type": "image",
                      "width": 200,
                      "height": 200,
                      "left": 147,
                      "top": 107,
                      "zIndex": 4,
                      "imageId": "5254ff3abd25326ca075a9ca86845f28_q6ehtf0uzx.jpeg"
                    },
                    {
                      "id": "5z8pwvfd5u",
                      "content": "User Image",
                      "name": "User Image",
                      "type": "userImage",
                      "width": 200,
                      "height": 150,
                      "top": 162,
                      "left": 69,
                      "zIndex": 3
                    },
                    {
                      "id": "qlvi2wyvgy",
                      "content": "fond 02",
                      "name": "fond 02",
                      "type": "image",
                      "width": 300,
                      "height": 200,
                      "top": 50,
                      "left": 50,
                      "zIndex": 2,
                      "imageId": "6d67023fa4377ef1146db114ad1d3a6a_qlvi2wyvgy.jpeg"
                    },
                    {
                      "id": "tryof56yc9",
                      "name": "Test",
                      "type": "image",
                      "width": 200,
                      "height": 200,
                      "top": 20,
                      "left": 50,
                      "zIndex": 1,
                      "imageId": "f1434b15fd7ca3ae28b44d9cdab1db87_tryof56yc9.jpeg"
                    }
                  ],
                  "objectId": "GHFM0bfK0Y"
                }
              ]
            }
          ]
          `,
      },
    },
  },

  /** --------------------------- 3 ------------------------------- */
  {
    label: "Login as Anonymous User",
    code: {
      request: {
        value: dedent`
          // ----------------------------------------------------------------------------------------- //
          // STEP 1: the user should be logged in before any montage creation if not already logged in //
          // ----------------------------------------------------------------------------------------- //
          const anonymousUser = await Parse.AnonymousUtils.logIn();
          await anonymousUser.save();
        `,
      },
    },
  },

  /** --------------------------- 4 ------------------------------- */
  {
    label: "Upload des photos",
    code: {
      request: {
        url:
          "https://tech-blog.maddyzone.com/reactnative/how-to-upload-image-in-react-native-app",
        scrollable: false,
        language: "jsx",
        value: dedent`
              const type = 'userImage';
              // shoud be the swellUserId if the user is logged in / signed up
              const currentUser = Parse.User.current();
              const userId = currentUser.has('swellId') ? currentUser.get('swellId') : currentUser.id;
              /* 
                const file peut être
                  . un objet de type File ou Blog
                  . un objet "normal" de la forme, par exemple : {
                    uri: ...,
                    name: 'image.jpg',
                    type: 'image/jpeg'
                  }
                */

              const formData = new FormData();
              formData.append('file', file);
              formData.append('type', type);
              formData.append('userId', userId);

              const result = await fetch('https://bo-preprod.yourz.me/uploadImage', {
                method: 'POST',
                body: formData,
              });
        
              const data = await result.json();
              console.log(data.imageId);
          `,
      },
      response: {
        value: dedent`
          {
            "imageId": "montage-test_pp074ocuwy5z.png"
          }
          `,
      },
    },
  },

  /** --------------------------- 5 ------------------------------- */
  {
    label: "Création montage",
    executable: true,
    code: {
      request: {
        value: dedent`
            // -------------------------------------------------- //
            // the current anonymous user can create its montages //
            // -------------------------------------------------- //
            const templateId = 'YOUR_TEMPLATE_ID';

            const Montage = Parse.Object.extend('Montage');

            const template = await new Parse.Query('Template').get(templateId);
      
            const montage = new Montage();
      
            montage.set('template', template);

            // --------------------------------------------------------- //
            // -------------------- template layers -------------------- //
            // --------------------------------------------------------- //
            if (template.has('layers')) {
              const montageLayers = [];
        
              for (const layer of template.get('layers')) {
              
                // --------------------------------------------------------- //
                // -------------------- userImage layer -------------------- //
                // --------------------------------------------------------- //
                if (layer.type === 'userImage') {
                  const montageLayer = await setUserImageLayer({ 
                    layer, 
                    userId: user.id,
                    height: 500,
                    width: 600,
                    top: 0,
                    left: 0,
                    file: 'Some File',
                  });
                  montageLayers.push(montageLayer);
                }
          
                // --------------------------------------------------------- //
                // --------------------- userText layer -------------------- //
                // --------------------------------------------------------- //
                if (layer.type === 'userText') {
                  montageLayers.push({
                    id: layer.id,
                    text: 'Some montage test in mask',
                    font: 'Montserrat',
                    color: '#000',
                    size: 32,
                    type: layer.type,
                  })		
                }
              
                // --------------------------------------------------------- //
                // ----------------------- mask layer ---------------------- //
                // --------------------------------------------------------- //
                if (layer.type === 'mask') {
                  const maskLayer = { id: layer.id, type: layer.type, layers: [] };
          
                  for (const childLayer of layer.layers) {

                    // --------------------------------------------------------- //
                    // --------------- userImage mask child layer -------------- //
                    // --------------------------------------------------------- //
                    if (childLayer.type === 'userImage') {
                      const montageLayer = await setUserImageLayer({ 
                        layer: childLayer, 
                        userId: user.id
                        height: 400,
                        width: 400,
                        top: 20,
                        left: 90,
                        file: 'Some File',
                      });
                      maskLayer.layers.push(montageLayer);
                    }
          
                    // --------------------------------------------------------- //
                    // ---------------- userText mask child layer -------------- //
                    // --------------------------------------------------------- //
                    if (childLayer.type === 'userText') {
                      maskLayer.layers.push({
                        id: childLayer.id,
                        text: 'Some montage test in mask',
                        font: 'Arial',
                        color: '#eee',
                        size: 22,
                        type: childLayer.type,
                      })		
                    }
                  }
                  montageLayers.push(maskLayer);
                }
              }	
              montage.set('layers', montageLayers);
            }

            await montage.save();


          // --------------------------------------------------------- //
          // -------------------- userImage upload ------------------- //
          // --------------------------------------------------------- //
          /**
           * set montage user image layer
           * @param layer {Object}
           * @param userId {string}
           * @param file {File}
           * @param top {Number}
           * @param left {Number}
           * @param width {Number}
           * @param height {Number}
           */
            const setUserImageLayer = async ({ layer, userId, file, top, left, width, height }) => {
              try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('type', 'userImage');
                formData.append('userId', userId);
              
                const result = await fetch('https://bo-preprod.yourz.me/uploadImage', {
                  method: 'POST',
                  body: formData,
                });
              
                const data = await result.json();

                if (!data) {
                  throw new Error('No image id');
                }
              
                return {
                  id: layer.id,
                  imageId: data.imageId,
                  imageTop: top,
                  imageLeft: left,
                  imageHeight: height,
                  imageWidth: width,
                  type: layer.type,
                };
              } catch (error) {
                console.log('error: ', error);
              }
            }
            `,
      },
      response: {
        value: dedent`
              {
                "objectId": "pCEbWxyvFO",
                "swellUserId": "8DHPKYJoW2xHKU6O2E7DgoIip",
                "imageId": "montage-preprod-8dhpkyjow2xhku6o2e7dgoiip_oa4qwys5j8cm.png",
                "createdAt": "2021-03-23T08:50:59.091Z",
                "updatedAt": "2021-03-23T08:50:59.091Z",
                "layers": [
                    {
                        "id": "mum58h6tyr",
                        "type": "mask",
                        "layers": [
                            {
                                "id": "b9pjiwahyj",
                                "text": "Some montage test in mask",
                                "font": "Arial",
                                "color": "#24ce14",
                                "size": 30,
                                "type": "userText"
                            },
                            {
                                "id": "ezzoutgv8f",
                                "imageId": "montage-preprod-xgp3tz1nyx_3wlwh7jzaumr.png",
                                "imageTop": 300,
                                "imageLeft": 0,
                                "imageHeight": 200,
                                "imageWidth": 500,
                                "type": "userImage"
                            }
                        ]
                    },
                    {
                        "id": "r32v59ghdw",
                        "text": "Some montage test",
                        "font": "Arial",
                        "color": "#2ee439",
                        "size": 30,
                        "type": "userText"
                    },
                    {
                        "id": "co1vvq7qvw",
                        "imageId": "montage-preprod-xgp3tz1nyx_oinwzydri8l5.png",
                        "imageTop": 815,
                        "imageLeft": 506,
                        "imageHeight": 200,
                        "imageWidth": 500,
                        "type": "userImage"
                    }
                ],
                "template": {
                    "products": [
                        {
                            "id": "603f99e218c220492b56c29a",
                            "name": "Coussin 17x17"
                        }
                    ],
                    "themes": [
                        {
                            "__type": "Pointer",
                            "className": "Theme",
                            "objectId": "ONgOUIy0dc"
                        }
                    ],
                    "layers": [
                        {
                            "id": "mum58h6tyr",
                            "type": "mask",
                            "name": "Mask 1",
                            "width": 500,
                            "height": 500,
                            "top": 0,
                            "left": 0,
                            "layers": [
                                {
                                    "id": "ma3o8xy9h3",
                                    "type": "image",
                                    "name": "Image 1",
                                    "width": 250,
                                    "height": 500,
                                    "top": 0,
                                    "left": 0,
                                    "imageId": "template-preprod-swqdalgow9_db6df219804fd30c2b4a2b0646e7f310_ma3o8xy9h3.jpeg"
                                },
                                {
                                    "id": "p5rcoaraw0",
                                    "type": "image",
                                    "name": "Image 2",
                                    "width": 250,
                                    "height": 500,
                                    "top": 0,
                                    "left": 250,
                                    "imageId": "template-preprod-swqdalgow9_8689e43a931497fea2fe77c94b37cd3f_p5rcoaraw0.jpeg"
                                },
                                {
                                    "id": "b9pjiwahyj",
                                    "type": "userText",
                                    "name": "Usertext 1",
                                    "width": 200,
                                    "height": 100,
                                    "top": 201,
                                    "left": 159,
                                    "size": 30,
                                    "font": "Arial",
                                    "color": "#24ce14",
                                    "alignment": "center",
                                    "text": "Some text"
                                },
                                {
                                    "id": "ezzoutgv8f",
                                    "type": "userImage",
                                    "name": "Userimage 2",
                                    "width": 500,
                                    "height": 200,
                                    "top": 300,
                                    "left": 0,
                                    "imageId": "template-preprod-swqdalgow9_50fab19020ea4d8f04192c1a559395fb_ezzoutgv8f.jpeg"
                                }
                            ],
                            "imageId": "template-preprod-swqdalgow9_69b8e97d3bc785a5bf4695d172d16c3f_mum58h6tyr.png"
                        },
                        {
                            "id": "t5nb3syu08",
                            "type": "image",
                            "name": "Image 1",
                            "width": 329,
                            "height": 342,
                            "top": 708,
                            "left": 0,
                            "imageId": "template-preprod-swqdalgow9_7a55b67cf5b22ce746b7fccbb3f12049_t5nb3syu08.jpeg"
                        },
                        {
                            "id": "r32v59ghdw",
                            "type": "userText",
                            "name": "Usertext 1",
                            "width": 500,
                            "height": 210,
                            "top": 500,
                            "left": 250,
                            "size": 30,
                            "font": "Arial",
                            "color": "#2ee439",
                            "alignment": "center",
                            "text": "some text outside a mask"
                        },
                        {
                            "id": "co1vvq7qvw",
                            "type": "userImage",
                            "name": "Userimage 1",
                            "width": 500,
                            "height": 200,
                            "top": 815,
                            "left": 506,
                            "imageId": "template-preprod-swqdalgow9_daf31e44d2cbf835700bc6ab55f22ea4_co1vvq7qvw.jpeg"
                        }
                    ],
                    "backgroundColor": "#b91717",
                    "width": 20,
                    "height": 20,
                    "name": "Test-210319",
                    "unit": "cm",
                    "dpi": 150,
                    "createdAt": "2021-03-19T08:55:39.822Z",
                    "updatedAt": "2021-03-22T07:26:04.375Z",
                    "editor": {
                        "scale": 1,
                        "border": 1
                    },
                    "objectId": "swQDAlgOW9",
                    "__type": "Object",
                    "className": "Template"
                },
                "user": {
                    "username": "photoapp",
                    "swellId": "8DHPKYJoW2xHKU6O2E7DgoIip",
                    "email": "photoapp",
                    "firstName": "photoapp",
                    "lastName": " ",
                    "createdAt": "2020-12-11T12:06:49.888Z",
                    "updatedAt": "2020-12-11T12:06:49.888Z",
                    "ACL": {
                        "XGp3TZ1nYx": {
                            "read": true,
                            "write": true
                        },
                        "role:Administrator": {
                            "write": true
                        }
                    },
                    "sessionToken": "r:33ff17a22af2d2583cf987d4c9350b50",
                    "objectId": "XGp3TZ1nYx",
                    "__type": "Object",
                    "className": "_User"
                }
            }
            `,
      },
    },
  },

  /** --------------------------- 6 ------------------------------- */
  {
    label: "Endpoint image ",
    code: {
      request: {
        value: dedent`
          import Parse from "parse";
          import axios from "axios";

          const currentUser = Parse.User.current();
          const currentSession = Parse.Session.current();

          const dataConfig = {
            params: {
              imageId : 'template-preprod-gxjhxiqxwi_fda9d1119922e654daa3aadf7e69ec8e_i1bqf9sbwm.jpeg',
              imageType:'templateImage', //templateImage, userImage, montagePreview or backgroundImage
              width: 1000
            },
            headers: {
              'X-Yourz-User':  currentUser.has('swellId') ? currentUser.get('swellId') : currentUser.id,
              'X-Parse-Session-Token': currentSession.get('sessionToken')
            }
          }

          const response = await axios.get('https://bo-preprod.yourz.me/imageUrl', dataConfig });
          console.log(response.data);
          `,
      },
      response: {
        value: dedent`
            
              {
                url: 'https://storage.googleapis.com/template-preprod-gxjhxiqxwi/1000_template-preprod-gxjhxiqxwi_fda9d1119922e654daa3aadf7e69ec8e_i1bqf9sbwm.jpeg',
                success: true
              }
            
          `,
      },
    },
  },

   /** --------------------------- 6 ------------------------------- */
   {
    label: "Récupérer les fonds",
    code: {
      request: {
        value: dedent`
            const backgrounds = await new Parse.Query('Background').find();
            console.log(backgrounds);
          `,
      },
      response: {
        value: dedent`
        {
          "background": {
              "image": "background-preprod-lmuwibribo_f2592b3986b23291215c077459e26b70_1614604711138.jpeg",
              "width": 947,
              "height": 471,
              "left": 34,
              "top": 15
          },
          "width": 1000,
          "height": 500,
          "template": {
              "__type": "Pointer",
              "className": "Template",
              "objectId": "rVlV7E5qBe"
          },
          "createdAt": "2021-04-14T11:19:26.386Z",
          "updatedAt": "2021-04-21T12:55:29.191Z",
          "content": {
              "width": 250,
              "height": 296,
              "left": 320,
              "top": 97,
              "imageMask": "background-preprod-lmuwibribo_6a6b4e058483e2c1cbffa2ce239deb28_1618234905759.png"
          },
          "objectId": "LMuWIbriBO"
      }
            
          `,
      },
    },
   },
  /** --------------------------- 7 ------------------------------- */
  {
    label: "Liste des montages d'un user",
    code: {
      request: {
        value: dedent`
            const swellUserId = 'xdfjsdm';
      const query = await new Parse.Query('Montage')
              .include('template');

      if (anonymousUserId) {
        query.equalTo('objectId', anonymousUserId);
      } else {
        query.equalTo('swellUserId', swellUserId);
      }
    
      const montages = query.find();

      console.log('montages: ', montages);
          `,
      },
      response: {
        value: dedent`        
          [
            {
              "swellUserId": "xdfjsdm",
              "template": {
                "products": [
                  {
                    "id": "5ff582d8bee80d0c84da0849",
                    "name": "Set de table textile"
                  }
                ],
                "themes": [
                  {
                    "__type": "Pointer",
                    "className": "Theme",
                    "objectId": "QIvMbANGdd"
                  }
                ],
                "name": "Template 1",
                "width": 1000,
                "height": 600,
                "createdAt": "2021-01-06T14:54:45.782Z",
                "updatedAt": "2021-01-06T14:54:48.126Z",
                "objectId": "1FYxQBDGAB",
                "__type": "Object",
                "className": "Template"
              },
              "createdAt": "2021-01-07T13:17:13.477Z",
              "updatedAt": "2021-01-07T13:17:13.477Z",
              "objectId": "4dlNAdehkW"
            },
            {
              "swellUserId": "xdfjsdm",
              "template": {
                "products": [
                  {
                    "id": "5fc0f7f0071d831145ae876a",
                    "name": "Lot de 5 grands magnets"
                  }
                ],
                "themes": [
                  {
                    "__type": "Pointer",
                    "className": "Theme",
                    "objectId": "QIvMbANGdd"
                  }
                ],
                "name": "template t3",
                "width": 747,
                "height": 638,
                "createdAt": "2021-01-06T18:47:52.439Z",
                "updatedAt": "2021-01-06T18:47:54.775Z",
                "objectId": "fAhK6wbqpT",
                "__type": "Object",
                "className": "Template"
              },
              "createdAt": "2021-01-07T13:22:34.474Z",
              "updatedAt": "2021-01-07T13:22:34.474Z",
              "objectId": "h9vo2Eojzo"
            }
          ]
          `,
      },
    },
  },

  /** --------------------------- 8 ------------------------------- */
  {
    label: "Login",
    code: {
      request: {
        value: dedent`
          import swell from 'swell-js';
          import Parse from 'parse/react-native';

          //---------------get cookie -----------------//
          const getCookie = (name) => {
            const matches = document.cookie.match(
              new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
            );
            return matches ? decodeURIComponent(matches[1]) : undefined
          }
        
          //-------------- login swell ------------//

          swell.init('levea','pk_OATOzQArckpKRJ2I5b3A5VbgM9ZZLixw');
          const email = 'test@admin.com';
          const password = 'password';
          const userSwell = await swell.account.login(email, password);
          const swellUserId = userSwell.id;
          const swellSessionToken = getCookie('swell-session'); 
          
          // ---------------------------------------------------------------- //
          // --------------------- when login or signup --------------------- //
          // ------ link anonymous user to signed up or logged in user ------ //
          // ---------------------------------------------------------------- //
          const user = new Parse.User();
          const currentAnonymousUser = Parse.User.current();
          
          try {
            const myAuthData = {
              id: swellUserId,
              session : swellSessionToken,
              anonymousId : currentAnonymousUser.id
            };

            const loggedInUser = await user.linkWith('yourz', { authData: myAuthData });
           
            // link the logged in user with the previous anonymous user
            await Parse.AnonymousUtils.link(loggedInUser);
          } catch (e) {
            console.log(e);
          }
        `,
      },
    },
  },
];
const AntTabs = withStyles({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: "#1890ff",
  },
})(Tabs);

const AntTab = withStyles((theme) =>
  createStyles({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(4),
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      "&:hover": {
        color: "#40a9ff",
        opacity: 1,
      },
      "&$selected": {
        color: "#1890ff",
        fontWeight: theme.typography.fontWeightMedium,
      },
      "&:focus": {
        color: "#40a9ff",
      },
    },
    selected: {},
  })
)((props) => <Tab disableRipple {...props} />);

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
}));

const TestContent = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  return (
    <div className={classes.root}>
      <div className={classes.demo1}>
        <AntTabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
        >
          {tests.map((test, index) => (
            <AntTab label={test.label} {...a11yProps(index)} key={index} />
          ))}
        </AntTabs>
      </div>
      {tests.map((test, index) => (
        <TabPanel value={value} index={index} key={index}>
          <Box display="flex">
	          {test.code.request.value === 'test' && <LinksTest />}
            {test.code.request.value !== 'test' && !test.code.response && <BlockCode data={test} />}

            {/* ----------------- Request ----------------- */}
            {test.code.request.value !== 'test' && test.code.request && test.code.response && (
              <BlockCode data={test} title="Exemple d'extrait de code :" />
            )}

            {/* ----------------- Response ----------------- */}
            {test.code.response && (
              <BlockCode data={test} type="response" title="Réponse :" />
            )}
          </Box>
        </TabPanel>
      ))}
    </div>
  );
};

export default TestContent;
