import React from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Editor } from "react-draft-wysiwyg";
import { makeStyles } from '@material-ui/core';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const useStyles = makeStyles({
  wrapper: {
    border: '1px solid #949494',
		marginTop: 10,
		borderRadius: 3,
  },
	editor: {
		height: 220,
	},
});

const ReduxFormTextEditor = props => {

	const classes = useStyles();

	const { input: { value, onChange }, meta: { error } } = props;

	const _onChange = (value) => {
		onChange(value);
	}

	return (
		<>
      <Editor
        editorState={value}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        onEditorStateChange={_onChange}
				toolbar={{
					options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history']
				}}
      />
			{ error && <FormHelperText error>{ error }</FormHelperText>	}
		</>
	)
};

export default ReduxFormTextEditor;