import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

import LoadingButton from './LoadingButton';

const useStyles = makeStyles(theme => ({
	dialogContainer: {
		minWidth: 600,
	},
	dialogTitle: {
		composes: 'flexRow spaceBetween',
		background: 'red'
	},
	dialogContent: {
		composes: 'flexColumn stretch',
	},
	dialogAction: {
		margin: '13px 30px',
	},
	button: {
		minHeight: 32,
	},
}));

const ModalDialog = (props) => {
	// props
	const {
		title, content, bsClass, isVisible = false,
		headerActionButtons, maxWidth = 'sm',
		onClose, onExitedDialog,labelCancel = 'Annuler',
		onConfirm, labelConfirm = 'Valider',
		otherAction, labelOtherAction = '', loading = false,
		className, greenBtn = false, inBtnAction = false,
	} = props;

	// styles
	const classes = useStyles(props);

	return (
		<Dialog
			classes={{paper: className ? className : classes.dialogContainer}}
			open={isVisible}
			onClose={onClose}
			onExited={onExitedDialog}
			maxWidth={maxWidth}
		>
			{ !!title && (
				<DialogTitle>
					<Box 
						display="flex" 
						justifyContent="space-between" 
						alignItems={headerActionButtons ? 'center': 'flex-start'}
					>
						{title}
						{headerActionButtons}
					</Box>
				</DialogTitle>
			) }

			<DialogContent classes={{root: bsClass || classes.dialogContent}}>
				{content}
			</DialogContent>

			{ (!!labelCancel || !!labelConfirm || !!labelOtherAction) &&
				<DialogActions classes={{root: inBtnAction ? classes.dialogAction : undefined}}>
					{ onClose && labelCancel && !greenBtn &&
						<Button onClick={onClose} classes={{root: classes.button}}>
							{labelCancel}
						</Button>
					}
					{ otherAction && labelOtherAction &&
						<Button onClick={otherAction} classes={{root: classes.button}}>
							{labelOtherAction}
						</Button>
					}
					{ onConfirm && labelConfirm && !loading && !greenBtn &&
						<Button onClick={onConfirm} color='primary' classes={{root: classes.button}}>
							{labelConfirm}
						</Button>
					}
					{ onConfirm && labelConfirm && loading &&
						<LoadingButton
							onClick={onConfirm}
							color='primary'
							variant='contained'
							disabled={loading}
							loading={loading}
							className={classes.button}
						>
							{labelConfirm}
						</LoadingButton>
					}
				</DialogActions>
			}
		</Dialog>
	);
}

ModalDialog.propTypes = {
	title: PropTypes.string,
	content: PropTypes.any,
	bsClass: PropTypes.string,
	inBtnAction: PropTypes.bool,
	isVisible: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
	labelConfirm: PropTypes.string,
	labelCancel: PropTypes.string,
	otherAction: PropTypes.func,
	labelOtherAction: PropTypes.string,
	className: PropTypes.string,
	loading: PropTypes.bool,
	headerActionButtons: PropTypes.node,
	maxWidth: PropTypes.oneOf(['sm', 'md', 'lg', 'xs', 'xl', false]),
};
export default ModalDialog;