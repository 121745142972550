import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
	imageContainer: {
		width: 60, 
		height: 50,
		composes: 'flexRow center',
		overflow: 'hidden',
	},
	image: {
		width: '100%',
	}
});

const CustomImageCell = (props) => {
	// props
	const { value, className, children } = props;

	// styles
	const classes = useStyles();

	return (
		<div className={classNames(classes.imageContainer, className)}>
			{ value 
			? (
				<img 
					className={classes.image} 
					alt="" 
					src={value} 
				/>
			)
			: children
		}
		</div>
	)
}

CustomImageCell.propTypes = {
	value: PropTypes.any,
	className: PropTypes.string,
	children: PropTypes.node,
};
export default CustomImageCell;