import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";

const CloseIconButton = (props) => {
  // props
  const { onClose, color = "default", className } = props;

  return (
    <IconButton
      aria-label="close"
      onClick={onClose}
      color={color}
      className={className}
    >
      <CloseIcon />
    </IconButton>
  );
};

CloseIconButton.propTypes = {
  onClose: PropTypes.func,
  color: PropTypes.oneOf(["default", "primary", "secondary"]),
  className: PropTypes.string,
};
export default CloseIconButton;
