import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';


const ReduxFormCheckbox = props => {

	const { input: { value, onChange },labelCheckbox='', meta: { error } } = props;
	const checked = value || false;
	const _onChange = (event, checked) => {
		onChange(checked);
	};	
	return (
		<div>
			<FormControlLabel
            control={<Checkbox checked={checked} onChange={_onChange}  />}
            label={labelCheckbox}
      />
			{ error && <FormHelperText error>{ error }</FormHelperText>	}
		</div>
	)
};

export default ReduxFormCheckbox;