import { browserHistory } from "react-router";
import equal from "fast-deep-equal";
import { toId } from "../parseUtils";

import { showError } from "./app";
import { LOCAL_HOSTNAME, PREPROD_URL, PROD_URL, LOCAL_ENDPOINT} from "../constant";

const { push: routerPush, goBack: goBackInRouter } = browserHistory;

/**
 * returns a thunk
 * @param thunkOrPromise (signature if thunk: (dispatch : func, getState : func) : Promise)
 * @returns {function(*=, *=): Promise<void>}
 */
export function actionWithLoader(thunkOrPromise) {
  return async (dispatch, getState) => {
    dispatch({
      type: "LOADING_START",
    });
    try {
      if (typeof thunkOrPromise === "function") {
        const response = await thunkOrPromise(dispatch, getState);
        return response;
      } else {
        await thunkOrPromise;
      }
    } catch (error) {
      showError(error)(dispatch);
    } finally {
      dispatch({
        type: "LOADING_END",
      });
    }
  };
}

export function onEnter({
  store,
  actionThunk,
  getReplacingPath,
  withLoader = true,
}) {
  return async (nextState, replace, callback) => {
    try {
      if (getReplacingPath) {
        const replacingPath = await getReplacingPath(store.getState);
        if (replacingPath) {
          replace(replacingPath);
          callback();
          return;
        }
      }

      //---- actual call ----//
      const dispatchingFunction = actionThunk(nextState.params);
      let result;
      if (withLoader) {
        result = actionWithLoader(dispatchingFunction)(
          store.dispatch,
          store.getState
        );
      } else {
        result = dispatchingFunction(store.dispatch, store.getState);
      }
      if (result && result.then) {
        await result;
      }

      callback();
    } catch (error) {
      console.error(error);
      callback(error);
    }
  };
}

export function goBack() {
  goBackInRouter();
}

export function routerLocation(pathname, returnPath) {
  if (typeof returnPath !== "string") {
    // can be an Event (eg: onClick={showxxx})
    returnPath = undefined;
  }
  return {
    pathname,
    state: {
      returnPath,
    },
  };
}

export function push(pathname, returnPath) {
  // pathname might already be a location object
  const location = returnPath ? routerLocation(pathname, returnPath) : pathname;
  routerPush(location);
}

export function showParseObj(urlPrefix, parseObjOrId, subPage) {
  const id = toId(parseObjOrId);
  return push("/" + urlPrefix + "-" + id + (subPage ? "/" + subPage : ""));
}
export function editParseObj(urlPrefix, parseObjOrId) {
  const id = toId(parseObjOrId);
  return push("/" + urlPrefix + "Edit-" + id);
}

export function saveParseObj(
  parseObject,
  { actionType, goBack = false, callback }
) {
  return actionWithLoader(async (dispatch) => {
    await parseObject.save();
    //---- resultActionType ----//
    actionType &&
      dispatch({
        type: actionType,
      });
    //---- goBackAfterwards ----//
    goBack && dispatch(goBackInRouter());
    //---- callback ----//
    callback && callback();
  });
}

export function setValueParseObj(parseObject, values) {
  const oldJson = parseObject.toJSON();
  Object.entries(values).forEach(([key, value]) => {
    const oldValue = oldJson[key];
    if (!equal(oldValue, value)) {
      parseObject.set(key, value);
    }
  });
}

export function getValueParseObj(parseObject) {
  return parseObject.toJSON();
}

/**
 * get url for bo photo-app
 * @param {Boolean} isEndPoint
 * @returns {String}
 */
export function getPhotoAppURL(isEndPoint = false) {
	const location = window.location;

  if (location.hostname.includes('localhost')) {
    //---- LOCAL ----//
    return isEndPoint ? LOCAL_ENDPOINT : `http://${LOCAL_HOSTNAME}:${location.port}`;
  } 
  if (location.hostname.includes('preprod')) {
    //---- PREPROD ----//
    return PREPROD_URL;
  }
  //---- PROD ----//
  return PROD_URL;
}

// export function getPhotoAppURL(isEndPoint = false) {
//   if (window.LOCAL) {
//     //---- LOCAL ----//
//     return isEndPoint ? LOCAL_ENDPOINT : `http://${LOCAL_HOSTNAME}:${window.location.port}`;
//   } else if (window.PRODUCTION) {
//     //---- PROD ----//
//     return PROD_URL;
//   } else {
//     //---- PREPROD ----//
//     return PREPROD_URL;
//   }
// }

