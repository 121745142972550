import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import FormField from '../../components/form/FormField';

import { loadTemplatesThunk } from '../../actions/templates';
import { getTemplates } from '../../reducers/templates';
import ReduxFormReactSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactSelect';
import { formatParseObjSelectOptions } from '../../utils';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch center',
  },
  selectField: {
		width: 160
  },
});

const MontageForm = (props) => {
	// props
	const { handleSubmit } = props;

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const templates = useSelector(getTemplates);

	// styles
	const classes = useStyles(props);

	// load the templates   
	useEffect(() => {
		dispatch(loadTemplatesThunk());
	}, [dispatch]);

	return (
		<form className={classes.root} onSubmit={handleSubmit}>
			<FormField
				label='Template'
				name='template'
				fullWidth
				isClearable
				component={ReduxFormReactSelect}
				options={formatParseObjSelectOptions(templates)}
			/>
			<input type='submit' />
		</form>
	);
};

MontageForm.propTypes = {
	onSubmit: PropTypes.func,
	resetForm: PropTypes.bool,
};
export default reduxForm({
  form: 'montageForm',
})(MontageForm);
