import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Rnd } from 'react-rnd';
import classnames from 'classnames';
import { useUpdate } from 'react-use';
import { motion } from "framer-motion";

import { getPathImageGCP } from '../../../utils';




const useStyles = makeStyles({
	rootNotSelected: {
		pointerEvents: 'none' // so that we can drag/resize it even when it's under other layers
	},
	rnd :{
		pointerEvents: 'auto'
	},
	borderContainer: {
		//overflow: 'hidden',
		position: 'absolute',
		top: 0, left: 0, 
		width: '100%', height: '100%',
	
	},
  selected: {
		border: 'dashed 2px #999'
	},
	notSelected: {
		border: 'solid 2px transparent'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'cover'
	},
	imgMask : {
		width: '100%',
		height: '100%',
	}
});

const getTextAlignment = (value) => {
	switch (value) {
		case 'centerleft':
			return { alignItems: 'center' };
		case 'centerright':
			return { justifyContent: 'flex-end', alignItems: 'center' };
		default:
			return { justifyContent: 'center', alignItems: 'center' };
	}
}

const getTransformLayer =  (layer) => {
	if (layer.rotation) {
		return { transform : `rotate(${layer.rotation}deg)` };
	}
}

//border layer
const getBorderLayer = (border,selected) => {
	if (selected) {
			return { border: `dashed ${border}px #999`, marginTop: `${-border}px`, marginLeft: `${-border}px` };
	}
	return { border: `dashed ${border}px transparent`, marginTop: `${-border}px`, marginLeft: `${-border}px` };
}

const variants = {
  out: {
   opacity: 0,
  },
  in: {
    opacity: 1,
    transition: {
      type: 'spring',
      duration: 2
    }
  }
};

const LayerPreview = (props) => {
	const { 
		layer, 
		
		maxWidth=0, 
		maxHeight=0, 
		templateId = '', 
		selected = false, 
		childrenSelected = false,
		scale, 
		border,
		onUpdatePreview,
		children,
		maskPath = '',
		onSelection,
	} = props;

	const pathDefaultImage = `${layer.type}.jpg`;

	//path file GCP
	const pathImageGCP = layer.imageId ? getPathImageGCP(templateId, layer.imageId): `${process.env.PUBLIC_URL}/${pathDefaultImage}`;
	
	const update = useUpdate();

	//style
	const classes = useStyles(props);
	//paint image preview 
  const renderImage = ()=>{
		let urlImage = layer?.imageTemp ? URL.createObjectURL(layer.imageTemp) : pathImageGCP;
		if(!urlImage) return;
		return (
			<motion.img
				initial="out"
				whileInView="in"
				viewport={{ once: true, amount: 0.8 }}
				variants={variants}

				className={classes.img}
				style={{...getTransformLayer(layer)}} 
				src={urlImage}
				alt=""
				draggable={false}
			/>
		);
	}
	//--------------------------//
	//--------layer mask-------// 
	//------------------------//
	const renderMask = () => {
		//show mask image when selected
		if (maskPath !=='') {
			return (
				<img
					src={maskPath}
					alt={maskPath}
					style={{...getTransformLayer(layer)}} 
					className={classes.imgMask} 
					draggable={false}
				/>
			);
		}
	}

	const handleLayerSelection = (selectedLayer) => {
		if (!onSelection) return;
		onSelection(selectedLayer);
	}
	
  return (
    <Rnd
			size={{ width: layer.width, height: layer.height  }}
			className={classnames(classes.rnd, !selected && classes.rootNotSelected)}
			style={{ 
				zIndex: selected || childrenSelected ? 1000 : layer.zIndex, 
				transform: `scale(${scale})` ,
			}}
			scale = {scale}
      position={{
        x: layer.left ?? 0,
        y: layer.top ?? 0,
      }}
      onDragStop={(e, d) => {
				layer.left = d.x;
				layer.top = d.y;
				onUpdatePreview({...layer, top:d.y, left:d.x});
				update();
			
      }}
      onResize={(e, direction, ref, delta, position) => {
				//----------------------------------------------------------------------------//
				//-------------Calculate new left and Top positon after resize ---------------//
				//----------------------------------------------------------------------------//
				let newLeft = layer.left;
				let newTop = layer.top;
				if (direction==='topLeft') {
					newLeft =  layer.left + (layer.width - ref.offsetWidth);
					newTop = layer.top + (layer.height - ref.offsetHeight);
				}
				if (direction==='left') {
					newLeft =  layer.left + (layer.width - ref.offsetWidth);
				}
				if (direction==='top') {
					newTop = layer.top + (layer.height - ref.offsetHeight);
				}
				layer.left = newLeft;
				layer.top =newTop;
      	layer.width = ref.offsetWidth;
				layer.height = ref.offsetHeight;
				onUpdatePreview({...layer, width:ref.offsetWidth, height:ref.offsetHeight, top:newTop, left:newLeft});
				update();
			}}
			maxWidth={maxWidth - layer.left}
      maxHeight={maxHeight - layer.top }
      bounds='parent'
			disableDragging={!selected}
			enableResizing={selected}
    >
			<div
				className={classes.borderContainer}
				onClick={() => handleLayerSelection(layer)}
				style={{
					// . so that the actual image width and height don't change with the border width
					// . can't be in the borderContainer css class
					boxSizing: 'content-box',
					...getBorderLayer(border, (selected || childrenSelected))
				}}
			>
					{layer.type === 'userText' && (
						<div
							style={{
								color: layer.color,
								fontSize: layer.size,
								fontFamily: layer.font,
								height: '100%',
								display: 'flex',
								...getTextAlignment(layer.alignment),
								...getTransformLayer(layer)
							}}
						>
							{layer.text}
						</div>
					)}
					{(layer.type === 'image' || layer.type === 'userImage') && renderImage()}
					{layer.type === 'mask' && renderMask()}
					{children}
			</div>
    </Rnd>
  );
};

export default LayerPreview;