import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { PhotoshopPicker } from 'react-color';
import classNames from 'classnames';

const useStyles = makeStyles({
  containerInput: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    
  },
  colorPickerContainer: {
    position: 'absolute',
    zIndex: 100,
  },
  colorPickerCover: {
    position: 'fixed',
    cursor:'pointer',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cardColor: {
    width: 35,
    height: 35,
    border: '1px solid grey',
    marginRight: 5,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 3,
    fontSize: '1rem',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    marginBottom: 3,
  },
  input: {
    flex: 1,
    background: 'none',
    border: 'none',
    borderBottom: '1px solid #999',
    height: 35,
    '&:focus': {
      outline: 'none',
    }
  },
  container: {
    width: 210,
  },
  fullWidth: {
    width: '100%',
  },
});

const ReduxFormInputColor = (props) => {
	// props
	const {
		input: { name, value , onChange },
    colorClassName, rootClassName,
    isDisabled,
    fullWidth,
	} = props;

  const classes = useStyles(props);

  const [colorPicker, setColorPicker] = useState({
    displayColor: false,
  });

  const onHandleShowColorPicker = () => {
    setColorPicker((state)=>({...state, displayColor: true}));
  };

  const onHandleCloseColorPicker = () => {
    setColorPicker((state)=>({...state, displayColor: false}));
  };

	const onChangeColor = (color) => {
    onChange(color.hex);
  };

	return (
		<div className={classNames(rootClassName, { [classes.fullWidth]: fullWidth})}>
			<div className={classes.containerInput}>
				<div
					className={classes.cardColor}
					style={{ backgroundColor: value }}
				/>
				<input
					type='text'
					name={name}
					className={classes.input}
					value={value}
          onClick={() => onHandleShowColorPicker()}
          onChange={onChangeColor}
          disabled={isDisabled}
				/>
			</div>
			{colorPicker.displayColor && (
				<div className={colorClassName}>
					<div
						className={classes.colorPickerCover}
						onClick={() => onHandleCloseColorPicker()}
					/>
					<PhotoshopPicker 
						onChange={onChangeColor}
						color={value}
					/>
				</div>
			)}
		</div>
	)
};

export default ReduxFormInputColor;