import React from 'react';
import Switch from '@material-ui/core/Switch';
import classNames from 'classnames';
import FormHelperText from '@material-ui/core/FormHelperText';

const ReduxFormSwitch = props => {
	const { input: { value, onChange }, meta: { error }, inputclassname } = props;
	const checked = value || false;

	const _onChange = (event, checked) => {
		onChange(checked);
	};

	return (
		<div className={classNames(inputclassname, 'width100')}>
			<Switch 
				checked={checked}
				onChange={_onChange}
			/>
			{ error && <FormHelperText error>{ error }</FormHelperText>	}
		</div>
	);
};

export default ReduxFormSwitch;