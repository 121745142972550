import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { formatParseObjSelectOption, formatParseObjSelectOptions  } from '../../utils';


import FormField from '../../components/form/FormField';
import ReduxFormReactSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactSelect';
import DropFileUploader from '../../components/form/DropFileUploader';
import { getTemplates } from '../../reducers/templates';
import { loadTemplatesThunk } from '../../actions/templates';
import { getBackgroundValues } from '../../actions/backgrounds';
import NumberField from '../../components/form/NumberField';

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn stretch'
	},
	row: {
		composes: 'flexRow spaceAround',
		width: '100%'
	},
});

const BackgroundForm = (props) => {
	// state
	
	// props
	const { handleSubmit } = props;

	// dispatch
	const dispatch = useDispatch();

	// styles
	const classes = useStyles(props);

	// selectors
	const backgrounds = useSelector(getTemplates);

	// load the backgrounds   
	useEffect(() => {
		dispatch(loadTemplatesThunk());
	}, [dispatch]);
	

	return (
		<form className={classes.root} onSubmit={handleSubmit}>
				<NumberField label='Largeur' name='width' />
				<NumberField label='Hauteur' name='height' />
				<FormField
					label='Template'
					name='template'
					fullWidth
					isClearable
					component={ReduxFormReactSelect}
					options={formatParseObjSelectOptions(backgrounds)}
				/>
				<FormField
					label='Image'
					name='imageFile'
					component={DropFileUploader}
					fullWidth
				/>
			<input type='submit'/>
		</form>
	);
};

BackgroundForm.propTypes = {
	onSubmit: PropTypes.func,
	resetForm: PropTypes.bool
};
export default reduxForm({
	form: 'backgroundForm',
})(BackgroundForm);


//---------------------------------------------------------------------//
//--------------------- From and to background ------------------------//
//---------------------------------------------------------------------//

export function valuesFromBackground(background) {
	const backgroundValues = getBackgroundValues(background);

	return {
		...backgroundValues,
		template: formatParseObjSelectOption(backgroundValues.template),
	};
}

export function ToBackgroundProperties(values) {
	const newValues = { ...values };
	if (values?.template?.parseObj) {
		newValues.template = values.template.parseObj;
	}
	return newValues;
}