import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import CustomCard from '../../components/CustomCard';
import ListIconButton from '../../components/buttons/ListIconButton';
import EditIconButton from '../../components/buttons/EditIconButton';

import { showThemeEdit, showThemes } from '../../actions/themes';
import { getTheme } from '../../reducers/themes';

const useStyles = makeStyles(theme => ({
	root: {

	},
	list: {
		composes: 'flexRow flexEnd',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	value: {
		marginLeft: theme.spacing(2),
		fontWeight: 700,
	}
}));

const ThemePreview = (props) => {
	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const theme = useSelector(getTheme);

	if (!theme) return null;
	const themeId = theme.id;

	const _goToThemeListPage = () => dispatch(showThemes());
	const _goToEditPage = () => dispatch(showThemeEdit(themeId));

	const actionHeaderButtons = (
		<div>
			<ListIconButton onShowList={_goToThemeListPage} />
			<EditIconButton onEdit={_goToEditPage} />
		</div>
	);
	const cardContent = (
		<div>
			<div className={classes.list}>
				<span>Nom: </span>
				<span className={classes.value}>{theme.get('name')}</span>
			</div>
	  </div>
	);

	return (
			<CustomCard
				title={'Thème - ' + themeId}
				content={cardContent}
				actionHeaderButtons={actionHeaderButtons}
				cancelAction={_goToThemeListPage}
				cancelLabel="Retourner à la liste"
				withActionButtons={false}
				fullScreen
			/>
	)
}

ThemePreview.propTypes = {
	theme: PropTypes.object
};

export default ThemePreview;