import React from 'react';
import { Router, Route, IndexRoute } from 'react-router';
import { useStore } from 'react-redux';

import App from './containers/App';
import Home from './containers/Home';

import Login from './containers/login/Login';

import Templates from './containers/templates/Templates';
import TemplateCreation from './containers/templates/TemplateCreation';
import TemplateEditor from './containers/templateEditor/TemplateEditor';

import Montages from './containers/montages/Montages';
import MontageEdit from './containers/montages/MontageEdit';
import MontageCreation from './containers/montages/MontageCreation';
import MontagePreview from './containers/montages/MontagePreview';

import Products from './containers/products/Products';

import Test from './containers/test/Test';

import ThemeCreation from './containers/themes/ThemeCreation';
import Themes from './containers/themes/Themes';
import ThemeEdit from './containers/themes/ThemeEdit';
import ThemePreview from './containers/themes/ThemePreview';

import { onEnterApp, onEnterHome, onEnterUnknownRoute } from './actions/app';
import { onEnterTemplate } from './actions/templates';
import { onEnterMontage } from './actions/montages';

import { onEnterThemes, onEnterTheme } from './actions/themes';

import Orders from './containers/orders/Orders';

import PromoBanners from './containers/promoBanners/PromoBanners';
import { onEnterPromoBanners } from './actions/promoBanners';
import { onEnterBackground } from './actions/backgrounds';
import BackgroundEdition from './containers/backgrounds/BackgroundEdition';
import Backgrounds from './containers/backgrounds/Backgrounds';
import BackgroundCreation from './containers/backgrounds/BackgroundCreation';
import BackgroundEditor from './containers/backgroundEditor/BackgroundEditor';
import EndPointTest from './containers/test/EndPointTest';
import { onEnterEndPointTest } from './actions/test';
import OrdersUpdate from './containers/orders/OrdersUpdate';

const Routes = (props) => {
	const store = useStore();
	const { history } = props;

	return (
		<Router history={history}>
			<Route path='/' component={App} onEnter={onEnterApp(store)} >
				<IndexRoute component={Home} onEnter={onEnterHome(store)} />
				<Route path='login' component={Login} />

				<Route path="templates" component={Templates} />
				<Route path="templateCreation" component={TemplateCreation} />
				<Route path="template-:templateId" component={TemplateEditor} onEnter={onEnterTemplate(store)} />

				<Route path="montageCreation" component={MontageCreation} />
				<Route path="montages" component={Montages} />
				<Route path="montageEdit-:montageId" component={MontageEdit} onEnter={onEnterMontage(store)} />
				<Route path="montage-:montageId" component={MontagePreview} onEnter={onEnterMontage(store)} />

				<Route path="themeCreation" component={ThemeCreation} />
				<Route path="themes" component={Themes} onEnter={onEnterThemes(store)} />
				<Route path="themeEdit-:themeId" component={ThemeEdit} onEnter={onEnterTheme(store)} />
				<Route path="theme-:themeId" component={ThemePreview} onEnter={onEnterTheme(store)} />

				<Route path="backgroundCreation" component={BackgroundCreation} />
				<Route path="backgrounds" component={Backgrounds} />
				<Route path="backgroundEdit-:backgroundId" component={BackgroundEdition} onEnter={onEnterBackground(store)} />
				<Route path="background-:backgroundId" component={BackgroundEditor} onEnter={onEnterBackground(store)} />

				<Route path="products" component={Products} />
				<Route path="orders" component={Orders} />
				<Route path="promoBanners" component={PromoBanners} onEnter={onEnterPromoBanners(store)} />

				<Route path="test" component={Test} />

				<Route path="updateOrderAssets" component={OrdersUpdate} />

				<Route path="endPointImageTest-:imageId" component={EndPointTest} onEnter={onEnterEndPointTest(store)}/>

				<Route path='*' onEnter={onEnterUnknownRoute(store)} />
			</Route>
		</Router>
	);
};

export default Routes;