import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import MUISnackbar from '@material-ui/core/Snackbar';

import SnackbarContentWrapper from '../components/SnackbarContentWrapper';

import { closeError, closeMessage } from '../actions/app';
import {
	getError,
	getMessage, getMessageVariant
} from '../reducers/app';


const SnackBar = () => {
	// dispatch
	const dispatch = useDispatch();

	// selectors
	const message = useSelector(getMessage);
	const error = useSelector(getError);
	const variant = useSelector(getMessageVariant);


  const handleClose = () => {
    batch(() => {
      dispatch(closeMessage());
      dispatch(closeError());
    })
  };

	return (
    <MUISnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={message !== null || error !== null}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContentWrapper
        variant={variant}
        message={message || error || ''}
        onClose={handleClose}
      />
    </MUISnackbar>
	);
};

export default SnackBar;