import React from 'react';
import PropTypes from 'prop-types';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '@material-ui/core';

const AddIconButton = ({ onAdd, color = 'primary', className }) => (
	<IconButton
		aria-label='add'
		onClick={onAdd}
		color={color}
		className={className}
	>
		<AddIcon />
	</IconButton>
);

AddIconButton.propTypes = {
	onAdd: PropTypes.func,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	className: PropTypes.string
}
export default AddIconButton;