import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Column } from "devextreme-react/data-grid";
import { useDispatch } from "react-redux";
import { submit } from "redux-form";

import {
  formatParseObjSelectOptions,
  formatPrice,
  toMoment,
} from "../../utils";
import { validateProductCustomFields } from "../../validation";
import ModalDialog from "../../components/ModalDialog";
import CustomCell from "../../components/devExpressTable/CustomCell";
import CustomImageCell from "../../components/devExpressTable/CustomImageCell";
import TableButtonsAction from "../../components/devExpressTable/TableButtonsAction";
import ProductForm from "./ProductForm";
import ProductCustomFieldsForm from "./ProductCustomFieldsForm";
import {
  addOrUpdateProductCustomFields,
  getTemplatesCountForSelectedProduct,
  getTemplatesForSelectedProduct,
  loadProductsWithPagination,
  showProducts,
  updateTemplatesForProduct,
} from "../../actions/products";
import CustomTableGrid from "../../components/devExpressTable/CustomTableGrid";

const useStyles = makeStyles({
  root: {},
  missingData: {
    color: 'red',
  },
});

const columns = [
  "id",
  "name",
  "image",
  "active",
  "updated",
  "price",
  "templatesCount",
  "data",
];

const ProductsTable = ({ data, templates }) => {
  // styles
  const classes = useStyles();

  const dispatch = useDispatch();

  // state
  const [selectedProduct, setSelectedProduct] = useState(null);
  const refRowsData = useRef([]);

  // row click
  const onRowClick = ({ data }) => {
    setSelectedProduct({
      ...data,
      templates: getTemplatesForSelectedProduct(templates, data?.id),
    });
  };

  //---------------------------//
  //------ dialog actions -----//
  //---------------------------//
  // close dialog
  const handleCloseDialog = () => {
    setSelectedProduct(null);
  };

  // open dialog
  const _openEditDialog = (selectedData) => {
    setSelectedProduct(selectedData);
  };


  //---------------------------//
  //----- initial values ------//
  //---------------------------//
  // custom fields values initialization
  const getInitialProductCustomFieldsValues = useCallback(() => {
    if (!selectedProduct) return;

    // get the custom fields values from swell
    const {
      yourzPromoBannerTitle,
      yourzPromoBannerDesc,
      yourzProductDetails,
      yourzShipping,
      yourzIdealFor,
    } = selectedProduct.data;

    // if (!yourzPromoBannerTitle || !yourz_pPmo_bBner_dDc || !yourzIdealFor) return;

    // set a blank values for the field array (one by default)
    const defaultValues = [{ title: '', desc: '', icon: '' }];
    const defaultIdealForValues = [{ desc: '', icon: '' }];

    return {
      title: yourzPromoBannerTitle,
      desc: yourzPromoBannerDesc,
      details: (!yourzProductDetails || yourzProductDetails.length <= 0) ? defaultValues : yourzProductDetails,
      shipping: (!yourzShipping || yourzShipping.length <= 0) ? defaultValues : yourzShipping,
      idealFor: (!yourzIdealFor || yourzIdealFor.length <= 0) ? defaultIdealForValues : yourzIdealFor,
    };
  }, [selectedProduct]);

  // edit form initilization
  const getInitialFormValues = useCallback(() => {
    if (!selectedProduct) return;

    return {
      templates: formatParseObjSelectOptions(selectedProduct?.templates),
    };
  }, [selectedProduct]);


  //---------------------------//
  //-------- save values ------//
  //---------------------------//
  const _save = async (values) => {
    const templates =
      values.templates && values.templates.length > 0
        ? values.templates.map((value) => value.parseObj)
        : [];

    await dispatch(updateTemplatesForProduct(selectedProduct, templates));
    handleCloseDialog();
    showProducts();
  };

  const _saveProductCustomFields = async (values) => {
    validateProductCustomFields(values)
    if (!selectedProduct) return;

    const newValues = { id: selectedProduct.id, ...values };
    await dispatch(addOrUpdateProductCustomFields(newValues));
    handleCloseDialog();
  };

  //---------------------------//
  //-------- form submit ------//
  //---------------------------//
  const _submit = () => {
    dispatch(submit("productForm"));
  };

  const _submitCustomFields = () => {
    dispatch(submit("productCustomFieldsForm"));
  };

  const loadProductsData = async (loadOptions) => {
		const dataRows = await dispatch(loadProductsWithPagination({limit: loadOptions.take, page: loadOptions.skip / loadOptions.take}));

		const data = dataRows.data.map((product) => ({
      [columns[0]]: product.id,
      [columns[1]]: product.name,
      [columns[2]]:
        product.images &&
        product.images.length > 0 &&
        product.images[0].file.url,
      [columns[3]]: product.active ? "ACTIVE" : "INACTIVE",
      [columns[4]]: product.date_updated,
      [columns[5]]: formatPrice(product.price, product.currency),
      [columns[6]]: getTemplatesCountForSelectedProduct(
        templates,
        product.id
      ),
      [columns[7]]: product,
    }))

    refRowsData.current = data;

		return {
			data: data,
			totalCount: dataRows.totalCount
		}
	}

  return (
    <div className={classes.root}>
      {/* ----------------- Product Table ----------------- */}
      <CustomTableGrid
        reloadDataPagination={(loadOptions) => loadProductsData(loadOptions)}
        showColumnLines={false}
        onRowClick={onRowClick}
        actionRender={(value) => (
          <TableButtonsAction
            onEdit={() => _openEditDialog(value.data)}
            openDialog={
              !!refRowsData.current.find(
                (product) => product.id === selectedProduct?.id
              )
            }
            label={value.data.name}
          />
        )}
      >
        <Column
          dataField={columns[1]}
          caption="Nom"
          cellRender={({ data }) => (
            <CustomCell 
              value={data.name} 
              className={(!data.data.attributes.width || !data.data.attributes.width || !data.data?.attributes.type) && classes.missingData} 
            />
          )}
        />
        <Column
          caption="Image"
          cellRender={({ data }) => <CustomImageCell value={data.image} />}
        />
        <Column
          dataField={columns[3]}
          caption="Statut"
          cellRender={({ data }) => <CustomCell value={data.active} />}
        />
        <Column
          dataField={columns[4]}
          dataType="date"
          caption="Modifié le"
          cellRender={({ data }) => (
            <CustomCell
              value={toMoment(new Date(data.updated))
                .format("DD MMM")
                .toUpperCase()}
            />
          )}
          defaultSortOrder="desc"
        />
        <Column
          dataField={columns[5]}
          caption="Prix"
          alignment="right"
          cellRender={({ data, column }) => (
            <CustomCell value={data.price} alignment={column.alignment} />
          )}
        />
        <Column
          dataField={columns[6]}
          caption="Nb de templates"
          alignment="right"
          cellRender={({ data, column }) => (
            <CustomCell
              value={data.templatesCount}
              alignment={column.alignment}
            />
          )}
        />
      </CustomTableGrid>

      {/* ----------------- Product Dialog ----------------- */}
      <ModalDialog
        title={`Ajouter template dans ${selectedProduct?.name}`}
        content={
          <ProductForm
            onSubmit={_save}
            templates={templates}
            initialValues={getInitialFormValues()}
          />
        }
        isVisible={
          !!refRowsData.current.find((product) => product.id === selectedProduct?.id)
        }
        onConfirm={_submit}
        onClose={handleCloseDialog}
        labelConfirm="Enregistrer"
      />

      {/* ----------------- Product Edit Dialog ----------------- */}
      <ModalDialog
        title={`Modifier ${selectedProduct?.name}`}
        content={
          <ProductCustomFieldsForm
            onSubmit={_saveProductCustomFields}
            initialValues={getInitialProductCustomFieldsValues()}
          />
        }
        isVisible={
          !!refRowsData.current.find((product) => product.id === selectedProduct?.id)
        }
        onConfirm={_submitCustomFields}
        onClose={handleCloseDialog}
        labelConfirm="Enregistrer"
      />
    </div>
  );
};

ProductsTable.propTypes = {
  data: PropTypes.any,
  templates: PropTypes.array,
};
export default ProductsTable;
