import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    marginLeft: 5
	},
	spinner: {
		marginLeft: 10,
		color: 'white'
  }
};

const SpinnerAdornment = withStyles(styles)(props => (
  <CircularProgress
    className={props.classes.spinner}
    size={25}
  />
));

const LoadingButton = (props) => {
  const {
    children,
    loading,
    ...rest
  } = props;
  return (
    <Button {...rest}>
      {children}
      {loading && <SpinnerAdornment {...rest} />}
    </Button>
  )
};

export default withStyles(styles)(LoadingButton);