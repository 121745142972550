import React from 'react';
import { Box, FormLabel, Fab, makeStyles, Typography, IconButton, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import FormField from '../../components/form/FormField';
import DropFileUploader from '../../components/form/DropFileUploader';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    padding: [0, theme.spacing(2), theme.spacing(2)],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  label: {
    alignSelf: 'center',
  },
  blockHeader: {
    composes: 'flexRow spaceBetween center',
  },
}));

const BulletedListField = (props) => {
  // styles
  const classes = useStyles();

  // props
  const { fields, label , meta: { touched, error, submitFailed } } = props;

  return (
    <Box width="100%" mt={2} mb={2}>
      <Box mb={2}>
        <FormLabel>{label}</FormLabel>
      </Box>
        {fields.map((type, index) => (
            <Paper className={classes.card} key={index} variant="outlined" square>
              <Box className={classes.blockHeader}>
                <Typography className={classes.label}>{label} #{index + 1}</Typography>
                <IconButton
                  type="button"
                  title="Remove Member"
                  onClick={() => fields.remove(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
              {/* no title for the idealFor field */}
              {!type.includes('idealFor') && (
                <FormField
                  label="Title"
                  name={`${type}.title`}
                  fullWidth
                />
              )}
              <FormField 
                label="Description" 
                name={`${type}.desc`} 
                fullWidth 
              />
              <FormField
                name={`${type}.icon`}
                label="Icon"
                component={DropFileUploader}
                fullWidth
              />
            </Paper>
          )
        )}
        <Box>
          <Fab 
            onClick={() => fields.push({})}
            color="primary"
            variant="round"
            aria-label="add"
          >
            <AddIcon />
          </Fab>
          {(touched || submitFailed) && error && <span>{error}</span>}
        </Box>
    </Box>

  );
};

export default BulletedListField;