import { getData } from './index';

export default function themes (
	state = {
		theme: null,
		themeLocalVersion: 0,
		themes: [],
		themesLocalVersion: 0
	}, action) {
	switch (action.type) {
		case 'THEME_LOADED':
			return {
				...state,
				theme: action.theme
			};
		case 'REMOVE_THEME':
			return {
				...state,
				theme: null
			};
		case 'THEME_UPDATED':
			return {
				...state,
				theme: action.theme,
				themeLocalVersion: state.themeLocalVersion + 1
			};
		case 'THEMES_LOADED':
			return {
				...state,
				themes: action.themes
			};
		case 'THEMES_UPDATED':
			return {
				...state,
				themes: action.themes,
				themesLocalVersion: state.themesLocalVersion + 1
			};
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getTheme(state, errorIfNotFound = false) {
	return getData(state, "themes.theme", errorIfNotFound && "No theme data found");
}
export function getThemeLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "themes.themeLocalVersion", errorIfNotFound && "No theme data found");
}
export function getThemes(state, errorIfNotFound = false) {
	return getData(state, "themes.themes", errorIfNotFound && "No themes data found");
}
export function getThemesLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "themes.themesLocalVersion", errorIfNotFound && "No themes data found");
}