import { getData } from './index';

export default function templates(
  state = {
    template: null,
    templateLocalVersion: 0,
    templates: [],
    templatesLocalVersion: 0,
    layer: null,
  },
  action
) {
  switch (action.type) {
    case 'TEMPLATE_LOADED':
      return {
        ...state,
        template: action.template,
      };
    case 'REMOVE_TEMPLATE':
      return {
        ...state,
        template: null,
      };
    case 'TEMPLATE_UPDATED':
      return {
        ...state,
        template: action.template,
        templateLocalVersion: state.templateLocalVersion + 1,
      };
    case 'TEMPLATES_LOADED':
      return {
        ...state,
        templates: action.templates,
      };
    case 'TEMPLATES_UPDATED':
      return {
        ...state,
        templates: action.templates,
        templatesLocalVersion: state.templatesLocalVersion + 1,
      };
      case 'LAYER_UPDATED':
        return {
          ...state,
          layer : action.layer
        };
    default:
      return state;
  }
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getTemplate(state) {
  return getData(state, 'templates.template');
}
export function getTemplateLocalVersion(state) {
  return getData(state, 'templates.templateLocalVersion');
}
export function getTemplates(state) {
  return getData(state, 'templates.templates');
}
export function getTemplatesLocalVersion(state) {
  return getData(state, 'templates.templatesLocalVersion');
}
export function getLayer(state){
  return getData(state, 'templates.layer');
}

