import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
	cell: {
		composes: 'flexRow stretch center',
		height: 50,
	},
	right: {
		composes: 'justifyEnd'
	},
});

const CustomCell = (props) => {
	// props
	const { value, alignment, className } = props;

	// styles
	const classes = useStyles();

	return (
		<div 
			className={classNames(
				classes.cell, 
				alignment && alignment === 'right' && classes.right,
				className,
			)}>
			{value}
		</div>
	)
}

CustomCell.propTypes = {
	value: PropTypes.any,
	alignment: PropTypes.any,
	className: PropTypes.string
};
export default CustomCell;