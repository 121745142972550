import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import clone from 'lodash/clone';

import DeleteIconButton from './buttons/DeleteIconButton';
import AddIconButton from './buttons/AddIconButton';
import VisibilityButton from './buttons/VisibilityButton';
import VisibilityOffButton from './buttons/VisibilityOffButton';
import OrderableStackChildren from './OrderableStackChildren';
import LayerIcon from './LayerIcon';

const useStyles = makeStyles((theme) => ({
	root: {
		composes: 'flexColumn stretch',
		width: '100%',
	},
	container: {
		composes: 'flexRow center',
		padding: '5px 5px 0 12px'
	},
	content: {
		display: 'flex',
		alignItems: 'center',
		composes: 'flex1',
		paddingTop: 12,
		paddingBottom: 12,
	},
	deleteIcon: {
		zIndex: 1,
		padding: 5
	},
	lastItem: {
		marginBottom: '0 !important'
	},
	selected: {
		backgroundColor: `${theme.background.blue[570]} !important`
	},
	button:{
		padding: 5
	}
}));

const gap = 10;

const getItemStyle = (isDragging, draggableStyle,type) => ({
	// some basic styles to make the items look a bit nicer
	userSelect: 'none',
	margin: `0 0 ${type==='mask' ? 0 : gap}px 0`,

	// change background colour if dragging
	background: isDragging ? 'lightgreen' : 'azure',

	// styles we need to apply on draggable
	...draggableStyle
});

const getListStyle = isDraggingOver => ({
	background: isDraggingOver ? 'lightblue' : 'lightgrey',
	padding: gap,
	width: '100%'
});

const OrderableStack = props => {
	// props
	const {
		items,
		idProvider = item => item.id,
		contentProvider = item => item.name,
		onSelect, selectedItem,
		withDeleteButton = false,
		onDelete,
		onOpenAddDialog,
		toggleVisibility,
		onOrderChange,
		onOrderItemsMask,
		selectedItemsMask,
		onSelectItemsMask,
		onRemoveItemMask,
	} = props;

	// we have to reverse the items because 0 must be at the bottom of the stack
	const reversedItems = clone(items).reverse();

	const toNonReversedItems = reversedIndex => items.length - 1 - reversedIndex;
	
	// styles
	const classes = useStyles(props);

	// onDragStart listener
	const _onDragStart = (result) => {
		
		const item = items.find((item)=>item.id===result.draggableId);
		onSelect && onSelect(item??null);
	};

	// onDragEnd listener
	const _onDragEnd = (result) => {
		
		//---- dropped outside the list ----//
		if (!result.destination) {
			return;
		}

		//---- really moved ----//
		if (result.source.index === result.destination.index) {
			// not moved
			return;
		}

		onOrderChange(
			toNonReversedItems(result.source.index),
			toNonReversedItems(result.destination.index)
		);
	};

	// select item
	const _onItemSelect = selectedItem => {
		onSelect && onSelect(selectedItem);
	};

	const _onDelete =  (event, index) => {
		event.stopPropagation();
		onDelete(toNonReversedItems(index));
	}

	const _onAddItems = (event, item) => {
		event.stopPropagation();
		onOpenAddDialog(item.id);
	}

	const _onShowLayer = (event, item, index) => {
		event.stopPropagation();
		toggleVisibility(item, true);
	}

	const _onHideLayer = (event, item) => {
		event.stopPropagation();
		toggleVisibility(item, false);
	}

	return (
		<div className={classes.root}>
			<DragDropContext onDragStart={_onDragStart} onDragEnd={_onDragEnd}>
				<Droppable droppableId='droppable'>
					{(provided, snapshot) => (
						<div
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{reversedItems.map((item, index) => (
								<Draggable key={idProvider(item)} draggableId={idProvider(item)} index={index}>
									{(provided, snapshot) => (
										<div>
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style,
												item.type
											)}
											className={
												classNames(
													classes.container, {
														[classes.lastItem]: index === items.length - 1,
														[classes.selected]: selectedItem === item
													})
											}
											onClick={() => _onItemSelect(item)}
										>
											<div
												className={classes.content}
											>
												<LayerIcon layer={item} />
												{contentProvider(item)}
											</div>

											{(item.type === 'mask') && (
												<AddIconButton className={classes.button} onAdd={(event)=> { _onAddItems (event,item)}} />
											)}

											{ !item.hide && <VisibilityButton className={classes.button} onShow={(event)=> {_onShowLayer(event,item)}}/> }
											{ item.hide && <VisibilityOffButton className={classes.button} onHide={(event)=> {_onHideLayer(event,item)}} /> }

											{withDeleteButton && (
												<DeleteIconButton
													onDelete={(event)=> {_onDelete(event,index)}}
													color='secondary'
													className={classes.deleteIcon}
												/>
											)}
											
										</div>
											{(item.type === 'mask') && (
													<OrderableStackChildren
														type={idProvider(item)}
														maskId = {item.id}
														maskIndex = {toNonReversedItems(index)}
														items={item.layers}
														selectedItemsMask = {selectedItemsMask}
														onSelectItemsMask = {onSelectItemsMask}
														onOrderItemsMask = {onOrderItemsMask}
														onRemoveItemMask = {onRemoveItemMask}
													/>
											)}
											{provided.placeholder}
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</div>
	);
};

OrderableStack.propTypes = {
	items: PropTypes.array.isRequired,
	onSelect: PropTypes.func,
	selectedIndex: PropTypes.number,
	withDeleteButton: PropTypes.bool,
	onDelete: PropTypes.func
};
export default OrderableStack;
