import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const FormSection = (props) => {
	// props
	const { title, headerButtons, className, children, fullWidth = false, elevation = 1 } = props;

	return (
		<Card
			classes={
				{
					root: classNames(
						'cardMargin',
						className,
						{ 'stretch': fullWidth }
					)
				}
			}
			elevation={elevation}
		>
			{ ( title || headerButtons ) &&
				<div className="flexRow center">
					<CardHeader title={title} classes={{ root: 'flex1' }}/>
					<div>
						{headerButtons}
					</div>
				</div>
			}
			<CardContent
				classes={
					{
						root: classNames(
							'flexColumn',
							'overflowAuto',
							{ 'stretch': fullWidth }
						)
					}
				}
			>
				{ children }
			</CardContent>
		</Card>
	)
};

FormSection.propTypes = {
	title: PropTypes.string,
	headerButtons: PropTypes.object,
	className: PropTypes.string,
	fullWidth: PropTypes.bool
};

export default FormSection; 