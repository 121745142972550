import Parse from 'parse';

import {toDecimal, getFileObjectFromURL, cmToPx} from '../utils';
import { getValues, setValues } from '../parseUtils';
import {
	onEnter, showParseObj, actionWithLoader,
  push, getPhotoAppURL
} from './utils';
import { showHome } from './app';

import { getTemplate, getTemplates } from '../reducers/templates';

//--------------------------------------------------------//
//------------------ Parse <=> Object --------------------//
//--------------------------------------------------------//
const Template = Parse.Object.extend('Template', {
  getWidthInPx: function() {
    return cmToPx(this.get('width'));
  },
  getHeightInPx: function() {
    return cmToPx(this.get('height'));
  },
  isTransparent: function() {
    return this.get('backgroundColor') === 'transparent';
  }
});

const TEMPLATE_PROPERTIES = new Set([
  'name',
  'width',
  'height',
  'layers',
  'products',
  'themes',
  'image',
  'imageTmp',
  'imageId',
  'backgroundColor',
  'dpi',
  'editor',
  'usedForPrint'
]);

/**
 * get values for current template
 * @param template
 * @returns {Object}
 */
export const getTemplateValues = (template) => {
  return getValues(template, TEMPLATE_PROPERTIES);
}

export const setImageParse = (values) => {
  const parseFile = new Parse.File(values.id, values.imageFile);
	values.imageFile = parseFile;
}

/**
 * set image default layer 
 * @param {object} layer 
 * @param {string} defaultFileName 
 */
export const setDefaultFileLayer = (layer, defaultFileName = 'image.jpg') => {
  const fileURL = `${process.env.PUBLIC_URL}/${defaultFileName}`;

	getFileObjectFromURL(fileURL, (fileObject) => {
			layer.imageFile = fileObject;
	});
}

/**
 * set template values
 * @param template
 * @param values
 */
export const setTemplateValues = (template, values) => {
  const layers = [];

  //-- image layers treatment --//
  if (values.layers && values.layers.length > 0) {
    values.layers.map((layer) => {
      if (layer.imageFile) {
        const parseFile = new Parse.File(layer.id, layer.imageFile);
        layers.push({ ...layer, imageFile: parseFile });
      } else {
        layers.push({ ...layer });
      }

      return layer;
    });
  }

  if (values.image) {
    values.imageTmp = values.image;
    const templateParseFile = new Parse.File(template.id, values.image);
    values.image = templateParseFile;
  }

  const newValues = { ...values, layers };
  setValues(template, newValues, TEMPLATE_PROPERTIES);
}


//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create new template
 * @param values
 * @returns {*}
 */
export const createTemplate = (values) => {

  return actionWithLoader(async (dispatch, getState) => {
    const templates = getTemplates(getState());

    const newThemes = [];
    /** get the parse object selected value instead of string value */
    if (values.themes && values.themes.length > 0) {
      values.themes.map((theme) => newThemes.push(theme.parseObj));
    }
    delete values.themes;
    const newValues = { ...values };

    const template = new Template();

    // ----------------------------------------- //
    // -- add template to the selected themes -- //
    // ----------------------------------------- //
    for (const theme of newThemes) {
      theme.add('templates', template);
      await theme.save();
    }

    // ------------------------------- //
    // -- set and save values to db -- //
    // ------------------------------- //
    await setTemplateValues(template, newValues);


    // --------------------------- //
    // -- save image to storage -- //
    // --------------------------- //
    if (values.image) {
      await template.save(null, { context: { saveToBucket: true } });
    } else {
      await template.save();
    }

		dispatch({
			type: 'TEMPLATE_LOADED',
			template
		});
		dispatch({
      type: 'TEMPLATES_UPDATED',
			templates: [template, ...templates],
    });
	});
}

/**
 * save template layers editor
 * @param template
 * @param layers
 * @returns {*}
 */
export const saveTemplateEditor = (template, layers) => {
  return actionWithLoader(async (dispatch) => {
    template.set('layers', layers);
    await template.save();
    
		dispatch({
			type: 'TEMPLATE_UPDATED',
			template,
		});
	});
}



export const updateLayersTemplate = (newlayer) => {
  return async (dispatch, getState)=>{
    // set preview image
    if (newlayer.imageFile) {
      newlayer.imageTemp = newlayer.imageFile;

      await setImageParse(newlayer);
    }

    const template = getTemplate(getState());
		const originalLayers = template.get('layers');
    const layers = originalLayers.map((layer) => {
      if(layer.id===newlayer.id) return newlayer;
      else return layer;
    });

    template.set('layers',layers);

    dispatch({
			type: 'TEMPLATE_UPDATED',
			template
    });
    dispatch({
			type: 'LAYER_UPDATED',
			layer: newlayer
    });
  }
}

export const updateLayer = (layer) => {
  return async (dispatch) => {
    if (layer.imageFile) {
      const fileType = layer.imageFile instanceof Parse.File;

      if (!fileType ){
        layer.imageTemp = layer.imageFile;
        await setImageParse(layer);
      }
    }

    dispatch({
			type: 'LAYER_UPDATED',
			layer,
    });
  }
}

/**
 * generate and save pdf to gcs
 * @param template
 * @returns {*}
 */
export const regenerateImages = (templateId) => {
	return actionWithLoader(async () => {
		await Parse.Cloud.run('regenerateImages', { templateId });
	});
}

export const copyFileToProd = () => {
  return actionWithLoader(async () => {
		await Parse.Cloud.run('copyTemplatesToProd');
	});
}

export const copyTemplateToProd = (templateId) => {
  return actionWithLoader(async () => {
		await Parse.Cloud.run('copyTemplatesToProd', {templateId});
	});
}



/**
 * recalculate width and height layers after change template dimension
 * @param {object} template
 * @param {number} maxWidth
 * @param {number} maxHeight
 */
export const setMaxLayersDimension = (template, maxWidth, maxHeight) => {
    const layers = template.get('layers');

    const newLayers = layers.map((layer) => {
        //set width to width template when width layer > width template
        if (layer.width > maxWidth) {
           layer.width = maxWidth;
        }
        if (layer.height > maxHeight) {
           layer.height = maxHeight;
        }

        return layer;
    });

    template.set('layers', newLayers);
}

/**
 * update current template
 * @param data
 * @param values
 * @param [withLayers]
 * @returns {*}
 */
export const updateTemplate = ({template}, values) => {
  return actionWithLoader(async (dispatch, getState) => {
    if (!template || !values) return null;

    const newValues = { ...values };
    setMaxLayersDimension(template, cmToPx(values.width), cmToPx(values.height));

    /** No need to update the themes in template selectedLayer edition */
    // if (!withLayers) {
    //   const themesInputValues = values.themes;
    //   let newThemes = [];

    //   // get the parse object selected value instead of string value
    //   if (themesInputValues && themesInputValues.length > 0) {
    //     newThemes = themesInputValues.map((theme) => theme.parseObj);
    //   }

    //   if (themesByTemplate) {
    //     const themesAddedTemplate = intersectionBy(newThemes, xorBy(newThemes, themesByTemplate, 'id'), 'id');
    //     for (const theme of themesAddedTemplate) {
    //       theme.add('templates', template);
    //       await theme.save();
    //     }

    //     const themesRemovedTemplate = xorBy(themesByTemplate, intersectionBy(newThemes, themesByTemplate, 'id'), 'id');
    //     for (const theme of themesRemovedTemplate) {
    //       theme.remove('templates', template);
    //       await theme.save();
    //     }
    //   }
    // }
    delete newValues.themes;

    setTemplateValues(template, newValues);

    await updateTemplateThunk(template)(dispatch, getState);
  });
}

/**
 * update layers
 * @param {*} template 
 * @param {*} values 
 */
export const updateLayers = (template, values) => {
  return actionWithLoader(async (dispatch, getState) => {
    if (!template || !values) return null;

    setTemplateValues(template, values);
    await updateTemplateThunk(template)(dispatch, getState);
  });
}


/**
 * delete current template
 * @param template
 * @returns {*}
 */
export const deleteTemplate = (template, themesByTemplate) => {
  return actionWithLoader(async (dispatch, getState) => {

    const templates = getTemplates(getState());

    const newTemplates = templates.filter(t => t !== template);

    // ------------------------------------------------------ //
    // ----- Remove the current template from its theme ----- //
    // ------------------------------------------------------ //
    if (themesByTemplate) {
      for (const theme of themesByTemplate) {
        theme.remove('templates', template);
        await theme.save();
      }
    }

    template.set('deleted', true);
		const afterDeleted = await template.save();
		
    dispatch({
      type: 'TEMPLATES_UPDATED', // used in templates list
      templates: newTemplates,
    });

    return afterDeleted;
  });
}

/**
 * saves and updates template
 * @param {Object} template
 * @param {boolean} withLayers
 * @returns {function(*): Promise<void>}
 */
export const updateTemplateThunk = (template) => {
  return async (dispatch) => {
    await template.save();
    dispatch({
      type: 'TEMPLATE_UPDATED',
      template,
    });
  };
}

//--------------------------------------------------------//
//------------------ loading template --------------------//
//--------------------------------------------------------//
/**
 * onEnter template preview or edit page
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterTemplate = (store) => {
  return onEnter({
    store,
    actionThunk: (params) => {
      return async (dispatch, getState) => {
        const templateId = params.templateId;
        const template = await loadTemplateThunk(templateId)(
          dispatch,
          getState
        );

        // clean selected selectedLayer
        dispatch({
          type: 'LAYER_LOADED',
          layer: null,
        });

        if (!template) {
          // template not found
          showHome();
        }
      };
    },
  });
}

/**
 * load template into redux
 * @param templateId
 * @returns {function(*, *): Promise<*>}
 */
export const loadTemplateThunk = (templateId) => {
  return async (dispatch, getState) => {
    const currentTemplate = getTemplate(getState());

    if (!currentTemplate || currentTemplate.id !== templateId) {
      // loading template
      const template = await new Parse.Query('Template')
        .equalTo('objectId', templateId)
        .first();

      dispatch({
        type: 'TEMPLATE_LOADED',
        template,
      });

      // load selectedLayer default
      if (template.has('layers') && template.get('layers').length > 0) {
        dispatch({
          type: 'LAYER_LOADED',
          layer: template.get('layers')[0],
        });
      }
      return template;
    }

    return currentTemplate;
  };
}

/**
 * load all templates
 * @returns {Function}
 */
export const loadTemplatesThunk = () => {
  return async (dispatch) => {
    const templates = await new Parse.Query('Template')
      .notEqualTo('deleted', true)
      .limit(1000)
      .find();

    if (templates && Array.isArray(templates)) {
      dispatch({
        type: 'TEMPLATES_LOADED',
        templates,
      });
    }

    return templates;
  };
}

/**
 * load templates with pagination
 * @returns {Function}
 */
export const loadTemplatesPerPage = (limit = 10, skip = 0, filter= '') => {
	return async (dispatch) => {
		const templates = new Parse.Query('Template')
        .notEqualTo('deleted', true)
        .limit(limit)
        .skip(skip)
        .descending('updatedAt')
        .withCount(true);

      // filter
      if (filter !== '') {
        templates.contains('name', filter);
      }
      const results =await templates.find();

      dispatch({
        type: 'TEMPLATES_LOADED',
        templates: results.results,
      });
    
      return {
        data: results.results,
        totalCount: results.count
      };
	}
}

/**
 * onEnter templates
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterTemplates = (store) => {
  return onEnter({
    store,
    actionThunk: () => {
      return async (dispatch, getState) => {
        //const templates = getTemplates(getState());
        const templates = await loadTemplatesThunk()(dispatch, getState);

        if (!templates) {
          showHome();
        }
      };
    },
  });
}


//--------------------------------------------------------//
//------------------- unit conversion --------------------//
//--------------------------------------------------------//
/**
 * convert px to mm
 * @param value
 * @returns {number}
 */
export const pixelsToMM = (value = 0) => {
	return toDecimal(value * 0.264583);
}

export const mmToPixels = (value) => {
  return toDecimal(value/0.264583);
}

/**
 * convert mm to px
 * @param value
 * @returns {number}
 */
export const mmToPx = (value = 0) => {
	// NOTE : 1 mm = 3.77952755905511 px
	return toDecimal(value * 3.77952755905511);
}

/**
 * walk layers 
 * @param {*} layers 
 * @param {*} layersCount 
 * @param {*} callback 
 */
export const walkLayers = (layers, layersCount, callback) => {
  
  layers.forEach(layer => {
    callback(layer, layersCount);

    if (layer.type === 'mask') {
       const maskChildrenLayers = layer.layers;
       walkLayers(maskChildrenLayers, layersCount, callback);
    }
  });
}

/**
 * process count layers by type
 * @param {object} layer 
 * @param {object} layersCount 
 */
 export const processCountTypeLayer = (layer, layersCount) => {

  if (layer.type === 'image') {
    layersCount.image = layersCount.image + 1;
  }

  if (layer.type==='userText') {
    layersCount.userText = layersCount.userText + 1;
  }

  if (layer.type === 'userImage') {
    layersCount.userImage = layersCount.userImage + 1;
  }

  if (layer.type === 'mask') {
    layersCount.mask = layersCount.mask + 1;
  }
}

/**
 * count layers by type
 * @param {*} layers 
 * @returns 
 */
export const countLayersByType = (layers) => {

  // init result count //
  const resultCount = {
    image: 0,
    userText: 0,
    userImage: 0,
    mask: 0
  };

  // walk layers and group count by type
  walkLayers(layers, resultCount, processCountTypeLayer);

  return resultCount;
}

//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show template
 * @param templateId
 * @param fromNewTab
 */
export const showTemplate = (templateId, fromNewTab = false) => {
  if (fromNewTab) {
    const url = getPhotoAppURL() + '/template-' + templateId;
    window.open(url);
    return;
  }
  
  return showParseObj('template', templateId);
}

export const showTemplates = () => {
  return push('/templates');
}
export const showTemplateCreation = () => {
  return push('/templateCreation');
}
export const showTemplateEdit = (templateId) => {
  return push('/templateEdit-' + templateId);
}