import Parse from 'parse';

import { actionWithLoader, getPhotoAppURL, onEnter } from './utils';
import { queryMontageById, showMontage } from './montages';
import { getUrl, isProd } from '../utils';
import { getCurrentUser } from '../reducers/app';
import { showError, showHome } from './app';
import axios from 'axios';

const USER_IMAGE_TEST = 'userImageTest.jpg';
export const MONTAGE_ID_TEST = isProd() ? 'YK11H6TpDy' : 'uNjXiIJqfB';
export const BACKGROUND_ID_TEST = isProd() ? 'CgPJU7GrIp' : 'UdtUrjZ26f';
export const ORDER_ID_TEST = isProd() ? '65713736' : '100025';
export const PRODUCT_ID_TEST = isProd() ? '609a77ce294e90094aa41f8c' : '60757d163d859f5fc634cd0e';
export const PRODUCT_NAME_TEST = isProd() ? 'Prod product test name' : 'Product test name';


/**
 * upload image
 * @returns {Object}
 */
const uploadImage = async (userId) => {
	const fileURL = `${process.env.PUBLIC_URL}/${USER_IMAGE_TEST}`;
	let response = await fetch(fileURL);
	let blob = await response.blob();

	let metadata = {
		type: 'image/png',
	};

	let file = new File([blob], 'test.jpg', metadata);

	const formData = new FormData();
	formData.append('file', file);
	formData.append('type', 'userImage');
	formData.append('userId', userId);
	
	// get server endpoint
	const uploadImageEndpoint = getPhotoAppURL(true) + '/uploadImage';

	const result = await fetch(uploadImageEndpoint, {
		method: 'POST',
		body: formData,
	});

	const data = await result.json();

	if (!data) {
		throw new Error('No image id');
	}

	return data;
};

/**
 * set montage user image layer
 * @param layer
 * @param userId
 */
 const setUserImageLayer = async ({ 
	layer, 
	userId,
	imageTop = -50,
	imageLeft = 100,
	imageHeight = 120,
	imageWidth = 120,
 }) => {
 try {
	 const data = await uploadImage(userId);

	 return {
		 id: layer.id,
		 imageId: data.imageId,
		 imageTop: layer.top + imageTop,
		 imageLeft: layer.left - imageLeft,
		 imageHeight: imageHeight + layer.height,
		 imageWidth: imageWidth + layer.width,

		 type: layer.type,
	 };
 } catch (error) {
	 console.log("error: ", error);
 }
};


/**
 * test upload image endpoint
 * @returns {String}
 */
export const testUploadImage = () => {
	return actionWithLoader(async (dispatch, getState) => {
		const user = getCurrentUser(getState());
		const userId = user.get('swellId');
		const data = await uploadImage(userId);

		if (data.code === 500) {
			dispatch(showError('Error while uploading image : ' + data.error));
			return;
		}

		showEndPointImage(data.imageId);
	});
}

export const goToBackgroundImagePreview = async () => {
	const background = await new Parse.Query('Background')
		.equalTo('objectId', BACKGROUND_ID_TEST)
		.first();

	const imageId = background.get('background').image;
	showEndPointImage(imageId);
}

/**
 * get the parse object type by browser url (imageId)
 * @param {*} imageId 
 * @returns 
 */
export const getObjectType = (imageId) => {
	return imageId.split('-')[0];
}


/**
 *
 * @param store
 * @returns {(function(*, *, *): Promise<void>)|*}
 */
export function onEnterEndPointTest(store) {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch) => {
				const imageId = params.imageId;
				if (!imageId) {
					// montage not found
					showHome();
				}

				dispatch({
					type: 'IMAGE_TEST_LOADED',
					imageId
				});

			}
		}
	});
}



/**
 * get user image url
 * @returns {String}
 */
export const getUserImage2 = async (imageId) => {
	const url = await Parse.Cloud.run('getImageUrl', { imageId });
	return url;
}

export const getImageUrlTest = async ({ imageId, imageType = 'montagePreview', width = 1000 }) => {
	const currentUser = await Parse.User.current();

	const config = {
		headers: {
			'X-Yourz-User':  currentUser.has('swellId') ? currentUser.get('swellId') : currentUser.id,
			'X-Parse-Session-Token': currentUser.getSessionToken(),
		}
	};
	
	const url = getUrl() + '/imageUrl?imageId=' + imageId + '&imageType=' + imageType + '&width=' + width;

	const result = await axios.get(url, config);
	const imageUrl = result.data.url;
	return imageUrl;
}

/**
 * update montage
 * @returns {*}
 */
export const createImageTest = () => {
	return actionWithLoader(async () => {
		const montage = await queryMontageById(MONTAGE_ID_TEST);
		const userId = montage.get('swellUserId');

		const template = montage.get('template');

		// -------------------------------------- //
		// ------------ image upload ------------ //
		// -------------------------------------- //

    if (template.has('layers')) {
      const montageLayers = [];

      for (const layer of template.get('layers')) {
        if (layer.type === 'userImage') {
					const montageLayer = await setUserImageLayer({ layer, userId, imageHeight: 100, imageLeft: 299 });

          montageLayers.push(montageLayer);
        }

        if (layer.type === 'userText') {
          montageLayers.push({
            id: layer.id,
            text: 'Montage Text',
            font: layer.font,
            color: layer.color,
            size: layer.size,
            type: layer.type,
          });
        }

        if (layer.type === 'mask') {
          const childLayers = [];

          for (const childLayer of layer.layers) {
            if (childLayer.type === 'userImage') {
							const montageLayer = await setUserImageLayer({ layer: childLayer, userId });

              childLayers.push(montageLayer);
            }

            if (childLayer.type === 'userText') {
              childLayers.push({
                id: childLayer.id,
                text: 'Montage mask text',
                font: childLayer.font,
                color: childLayer.color,
                size: childLayer.size,
                type: childLayer.type,
              });
            }
          }

          montageLayers.push(childLayers);
        }
      }
			const newMontageLayers = montageLayers.flat(2);

      montage.set('layers', newMontageLayers);
    }

		// ------------------------------------ //
		// --------- end image upload --------- //
		// ------------------------------------ //

		await montage.save();

    // open montage preview in a new tab
		showMontage(montage.id, true);
	});
}

/**
 * update montage
 * @param {String} location
 * @param {String} imageId
 * @returns {*}
 */
export const openNewTab = (location, imageId) => {
	const url = getPhotoAppURL() + '/' + location +'-'+imageId;
	window.open(url);
}

export const showEndPointImage = (imageId) => openNewTab('endPointImageTest', imageId);
