import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import DataGrid, { SearchPanel, Grouping, Pager, Paging, Column, Selection, RemoteOperations } from 'devextreme-react/data-grid';
import classNames from 'classnames';

const useStyles = makeStyles({
	grid: {
		'& td div, td': {
			fontSize: 15,
			fontWeight: 400,
		},
		'& td': {
			padding: '6px 20px !important',
		},
		'& td .dx-button-content': {
			padding: 5,
			marginRight: 5, 
			marginLeft: 5, 
		}
	},
	selectableRow: {
		'& tr': {
			cursor: 'pointer', 
		}
	}
});



const Table = (props) => {
	// props
	const {
		dataSource,
		showBorders = true,
		showRowLines = true,
		actionRender,
		rowAlternationEnabled = true,
		defaultPageSize = 10,
		className,
		showColumnLines = true,
		onSelectionChanged,
		// selection,
		actionColumnWidth,
		pageSizes = [10, 25, 50, 100, 150, 200],
		children,
		onRowClick,
		invisibleInputSearch=false,
		onPageIndexChange
	} = props;

	// styles
	const classes = useStyles();

	return (
    <DataGrid
			dataSource={dataSource}
			showBorders={showBorders}
			rowAlternationEnabled={rowAlternationEnabled}
			showRowLines={showRowLines}
			showColumnLines={showColumnLines}
			className={classNames(classes.grid, className, onRowClick && classes.selectableRow)}
			onSelectionChanged={onSelectionChanged}
			// selection={selection}
			onRowClick={onRowClick}
    >
			{onSelectionChanged && (<Selection mode="multiple" />)}
      {/* columns */}
      {children}

      {/* the buttons action column (preview, deete, edit) */}
      { actionRender &&
        <Column
          width={actionColumnWidth || 80}
          dataField="Actions"
          type="buttons"
          cellRender={actionRender}
        />
      }
	  <RemoteOperations
          sorting={true}
          paging={true}
        />
      <SearchPanel visible={invisibleInputSearch ? false : true} highlightCaseSensitive />
      <Grouping autoExpandAll={false} />
      <Pager allowedPageSizes={pageSizes} showPageSizeSelector showNavigationButtons={true}/>
      <Paging defaultPageSize={defaultPageSize} onPageIndexChange={onPageIndexChange} pageSize={defaultPageSize ? 15 : null}/>
    </DataGrid>
	)
}

Table.propTypes = {
	dataSource: PropTypes.array,
	showBorders: PropTypes.bool,
	showRowLines: PropTypes.bool,
	rowAlternationEnabled: PropTypes.bool,
	showColumnLines: PropTypes.bool,
	defaultPageSize: PropTypes.number,
	actionRender: PropTypes.func,
	onSelectionChanged: PropTypes.func,
	className: PropTypes.string,
	// selection: PropTypes.object,
	pageSizes: PropTypes.arrayOf(PropTypes.number),
	children: PropTypes.node,
	onRowClick: PropTypes.func,
};
export default Table;