import React  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PhotoIcon from '@material-ui/icons/Photo';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import AccountBoxIcon from '@material-ui/icons/AccountBox';

const useStyles = makeStyles({
	container:{
		marginRight: 10,
		color: '#3486de'
	},
	icon: {
		width: 20,
	}
});

const LayerIcon = (props) => {
	const classes = useStyles(props);

	const { layer } = props;
	if (!layer) return null;

	return (
	<div className={classes.container}>
		{ layer.type === 'mask' && (
			<img
				alt="" 
				src={`${process.env.PUBLIC_URL}/${layer.usedForPrint ? 'maskIconForPrint': 'maskIcon'}.svg`} className={classes.icon} 
			/>
		)} 
		{ layer.type === 'image' && <PhotoIcon /> }
		{ layer.type === 'userImage' &&  <AccountBoxIcon/> }
		{ layer.type === 'userText'  && <TextFieldsIcon /> }
	</div>
	);
}
export default LayerIcon;