import React from 'react';
import PropTypes from 'prop-types';
import ViewListIcon from '@material-ui/icons/ViewList';
import { IconButton } from '@material-ui/core';

const ListIconButton = (props) => {
	// props
	const { onShowList } = props;

	return (
		<IconButton
			aria-label='view-list'
			onClick={onShowList}
		>
			<ViewListIcon />
		</IconButton>
	);
}

ListIconButton.propTypes = {
	onShowList: PropTypes.func
}
export default ListIconButton;