import React from 'react';
import Parse from 'parse';
import { render } from 'react-dom';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router';
import { reducer as formReducer } from 'redux-form';
import reduxDevToolsComposeEnhancer from './reduxDevTools';

import { ThemeProvider } from '@material-ui/core/styles';
import { DEFAULT } from './theme';

import {
	showError, loginSuccess, showLogin,
	retrieveUserFromLocalStorage, clearUserIntoLocalStorage
} from './actions/app';

import appReducers from './reducers';

import Routes from './Routes';

import '../src/index.css';

const init = async() => {
	const location = window.location;
	// LOCAL can also mean "accessed by a remote machine (like a Mac) on the local dev network"
	const hostName = location.hostname;
	window.LOCAL = hostName.indexOf('yourz') === -1 && hostName.indexOf('starry-embassy-283615') === -1;
	window.PRODUCTION = hostName.indexOf('bo.yourz.me') !== -1;

	//---- reducer ----//
	const reducer = combineReducers({
		...appReducers,
		form: formReducer,
	});

	//-------------------------------------------------------------------------------//
	//-------------------------- store and middleware -------------------------------//
	//-------------------------------------------------------------------------------//
	// taken from https://redux.js.org/advanced/middleware
	const crashReporter = store => next => action => {
		//console.log('crashReporter');
		try {
			return next(action);
		} catch (error) {
			console.error(error);
			showError(error.message)(store.dispatch);
		}
	};
	const nullMiddleware = store => next => action => {
		if (action == null) {
			return;
		}
		return next(action);
	};

	const store = createStore(reducer, reduxDevToolsComposeEnhancer(applyMiddleware(crashReporter, nullMiddleware, thunk)));

	//---------------------------------------------------//
	//------------------- Parse init --------------------//
	//---------------------------------------------------//
	const portWithColon = location.hostname.includes('localhost') ? ':1337' : '';
	Parse.initialize('iota');
	Parse.serverURL = location.protocol + '//' + location.hostname + portWithColon + '/parse';


	//---- loading user and checking token validity ----//
	let currentUser;
	const userFromLocalStorage = retrieveUserFromLocalStorage(); // it should be before Parse.User.Current()

	try {
		currentUser = Parse.User.current(); // it can throw (rarely)
		if (currentUser) {
			// is the session valid ? (some issues might happen)
			try {
				await Parse.Session.current();
			} catch (err) {
				console.log('Error retrieving the session object => 400 but, in fact, invalid token => logout');
				currentUser = null;
				throw new Error('Bad session');
			}
		}
	} catch (err) {
		const invalidSession = err.message === 'Bad session';
		if (invalidSession) {
			//---- bad session ----//
			// clean up user into localStorage
			clearUserIntoLocalStorage();
		} else {
			//---- error while checking Parse.User.Current ----//
			try {
				let parseUserError;
				if (!currentUser && userFromLocalStorage) {
					currentUser = userFromLocalStorage;
					parseUserError = 'Parse.User.current() throws an error.'
				}
				const installationId = localStorage.getItem('Parse/iota/installationId');
				await Parse.Cloud.run('loginError', { installationId, error: parseUserError || err });
			} catch (e) {
				console.log('Error retrieving currentUser : ' + e.message);
			}
		}

		if ((currentUser && !currentUser.equals(userFromLocalStorage)) || invalidSession) {
			try {
				await Parse.User.logOut();

			} catch (err2) {
				// might happen, but swallowed
			}
		}
	}

	if (!currentUser) {
		showLogin();
	} else {
		await loginSuccess()(store.dispatch, store.getState);
	}

	render(
		<Provider store={store}>
			<ThemeProvider theme={DEFAULT}>
				<Routes history={browserHistory} store={store}/>
			</ThemeProvider>
		</Provider>,
		document.getElementById('root')
	);
};

init();