import React from 'react';
import PropTypes from 'prop-types';
import { normalizeNumber,formatNumber } from '../../utils';
import FormField from './FormField';

const NumberField = (props) => {
  // props
  const { name, label,authorizeNegatif = false, ...otherProps } = props;

  return (
    <FormField
      name={name}
      label={label}
      type='number'
      step='0'
      format= {(value)=>formatNumber(value,authorizeNegatif)}
      normalize={(value) =>normalizeNumber(value,authorizeNegatif)}
      {...otherProps}
    />
  );
};

NumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};
export default NumberField;
