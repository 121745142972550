import { useRef } from 'react';
import {getFileObjectFromURL} from '../utils';

/**
 * object file default 
 */
export const useFileDefault = (defaultFileName='image.jpg') => {
	const fileURL = `${process.env.PUBLIC_URL}/${defaultFileName}`;
	const ref = useRef(null);
	getFileObjectFromURL(fileURL, fileObject => {
			ref.current = fileObject;
	});
	return ref.current;
}