import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism.css';

const useStyles = makeStyles({
	containerEditorArea: {
		tabSize: '4ch',
		maxHeight: ({ scrollable }) => scrollable ? 650 : 'initial',
		overflow: 'auto',
	},
	containerEditor: {
		fontSize: ({ size }) => size || 13,
		fontVariantLigatures: 'common-ligatures',
		backgroundColor: '#fafafa',
		borderRadius: 3,
		fontFamily: '"Fira code", "Fira Mono", monospace',
		'& textarea': {
			outline: 0,
		}
	}
});

const CodeEditor = (props) => {

	// props
	const { value, language, scrollable = true, readOnly = true } = props;

	// style
	const classes = useStyles({ scrollable });


	// state
  const [code, setCode] = useState('');
	
	// useEffect
  useEffect(() => {
    setCode(value);
  }, [value])

	// change text value
	const handleChange = (value) => setCode(value);

	return (
		<div className={classes.containerEditorArea}>
			<Editor
				value={code}
				onValueChange={handleChange}
				highlight={code => highlight(code, languages[language])}
				padding={24}
				className={classes.containerEditor}
				readOnly={readOnly}
			/>			
		</div>

	);
};

CodeEditor.propTypes = {
  value: PropTypes.string,
  scrollable: PropTypes.bool,
  language: PropTypes.oneOf(['js', 'jsx', 'json'])
};

export default CodeEditor;