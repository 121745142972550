import React, { useState, useRef, useEffect } from 'react';
import Moveable from "react-moveable";
import { makeStyles } from '@material-ui/core/styles';

import { getGCPUrl } from '../../utils';

const useStyles = makeStyles({
	greenBorder: {
		'--moveable-color': '#34c924 !important'
	},
	img: {
		width: '100%',
		height: '100%',
		objectFit: 'contain'
		// objectFit: 'cover'
	},
	imgMask: {
		width: '100%',
		height: '100%'
	}
});


const BackgroudItemPreview  = (props)=> {

	const {
		layer,
		maxWidth,
		maxHeight,
		onMoveableLayerPreview,
		type='background',
		backgroundId='',
		zoom=1
	} = props;

	//style 
	const classes = useStyles(props);

	// state react moveable
	const [frame, setFrame] = useState({
		translate: [layer.left ?? 0, layer.top ?? 0]
	});

	// target and moveable ref
	const targetRef = useRef(null);
	const moveableRef = useRef(null);

	// re-draw react moveable after change properties form
	useEffect(()=>{
		moveableRef.current.updateRect();
	}, [layer]);


	// render background image 
	const renderBackgroundImage = () => {
		const imagePath = layer.imageFileTemp ? URL.createObjectURL(layer.imageFileTemp) : (layer.image ? `${getGCPUrl('background')}${backgroundId.toLowerCase()}/${layer.image}` : '');
		if (imagePath === '') return;
		return (
			<img
				className={classes.img}
				src={imagePath}
				alt=""
				draggable={false}
			/>
		);
	}

	const renderMaskImageContent = () => {
		const imagePath = layer.maskImageFileTemp ? URL.createObjectURL(layer.maskImageFileTemp) : (layer.imageMask ? `${getGCPUrl('background')}${backgroundId.toLowerCase()}/${layer.imageMask}` : '');
		if (imagePath === '') return;
		return (
			<img
				className={classes.imgMask}
				src={imagePath}
				alt=""
				draggable={false}
			/>
		);
	}

	return (
		<>
			<div 
				ref={targetRef}
				style={{
					width: layer.width,
					height: layer.height,
					position:'absolute',
					transform: `translate(${layer.left}px, ${layer.top}px)`,
					cursor: 'move'
				}}
			>
				{type === 'background' && renderBackgroundImage()}
				{type === 'content' && renderMaskImageContent()}
			</div>
			<Moveable
				ref={moveableRef}
				target={targetRef}
				className={type === 'background' ? classes.greenBorder : ''}
				resizable={true}
				keepRatio={false}
				draggable={true}
				throttleDrag={0}
				throttleResize={0}
				pinchable={true}
				renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
				edge={false}
				zoom={zoom}
				origin={true}
				padding={{"left": 0, "top": 0, "right": 0,"bottom": 0}}
				onResizeStart={e => {
					e.setOrigin(["%", "%"]);
					e.dragStart && e.dragStart.set(frame.translate);
					
				}}
				onResize={e => {
					const beforeTranslate = e.drag.beforeTranslate;
					
					setFrame(prev => ({...prev, translate: beforeTranslate}));

					// calculate dimension 
					//-> width, height, left and top allowed movement 
					let width = e.width;
					let height = e.height;
					let transX = beforeTranslate[0];
					let transY = beforeTranslate[1];

					if (transX < 0) {
						transX = 0;
					}

					if (transY < 0) {
						transY = 0;
					}

					if (width+transX > maxWidth) {
						width = maxWidth-transX;
					}

					if (height+transY > maxHeight) {
						height = maxHeight-transY;
					}

					// update target style 
					e.target.style.width = `${width}px`;
					e.target.style.height = `${height}px`;
					e.target.style.transform = `translate(${transX}px, ${transY}px)`;
				
					// update background properties form 
					onMoveableLayerPreview(type, {...layer, width: width, height: height, left: transX, top: transY});
				}}
				onDragStart={e => {
					e.set(frame.translate);
				}}
				onDrag={e => {

					setFrame(prev => ({...prev, translate: e.beforeTranslate}));
					let transX = e.beforeTranslate[0];
					let transY = e.beforeTranslate[1];

					// don't move layer outside the background container
					// calculate left and top position to allow movement
					if (transX < 0) {
						transX = 0;
					}

					if (transY < 0) {
						transY = 0;
					}

					if (transX+layer.width > maxWidth) {
						transX = maxWidth-layer.width;
					}

					if (transY+layer.height > maxHeight) {
						transY = maxHeight-layer.height;
					}
					
					e.target.style.transform = `translate(${transX}px, ${transY}px)`;
		
					// update background properties form 
					onMoveableLayerPreview(type, {...layer, left: transX, top: transY});
					
				}}
			/>
		</>
	)
}

export default BackgroudItemPreview;
