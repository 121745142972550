import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { submit } from 'redux-form';

import { formatParseObjSelectOption, toMoment } from '../../utils';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import ModalDialog from '../../components/ModalDialog';
import MontageForm from './MontageForm';
import { deleteMontage, getMontageValues, loadMontagesPerPage, showMontage, showMontages, updateMontage } from '../../actions/montages';
import CustomCell from '../../components/devExpressTable/CustomCell';

const columns = ['id', 'template', 'updatedAt', 'data'];

const MontagesTable = ({isReloaded, onReload }) => {

	// state
	const [selectedMontage, setSelectedMontage] = useState(null);
	const refRowsData = useRef([]);

	// dispatch
	const dispatch = useDispatch();

	// get initial values
	const getInitialValues = useCallback(() => {
		if (!selectedMontage) return;
		const montageValues = getMontageValues(selectedMontage);
		return {
			...montageValues,
			template: formatParseObjSelectOption(montageValues.template),
		};
	}, [selectedMontage]);

	// row click
	const onRowClick = ({ data }) => {
		if (!data.data) return;
		
		const montageId = data.data.id;
		showMontage(montageId);
	}

	// open dialog
	const _openEditDialog = (selectedData) => {
		if (selectedData?.data) {
			setSelectedMontage(selectedData.data);
		}
	}

	// close dialog
	const handleCloseDialog = () => {
		setSelectedMontage(null);
	}

	// save change
	const _save = async (values) => {
		dispatch(updateMontage(selectedMontage, values)).then(()=> {
			onReload(!!!isReloaded);
		});
		handleCloseDialog();
		showMontages();
	};

	// delete a montage
	const _handleDelete = (montageId) => {
		const deletedMontage = refRowsData.current.find((row) => row.data.id === montageId);
		dispatch(deleteMontage(deletedMontage.data)).then(() => {
			onReload(!!!isReloaded);
		});
	}

	// submit change
	const _submit = () => {
		dispatch(submit('montageForm'))
	};

	const loadData = async (loadOptions) => {
		let searchValue;

		if (loadOptions.filter) {
			searchValue = loadOptions.filter[0].filterValue
		}

		const dataRows = await dispatch(loadMontagesPerPage(loadOptions.take, loadOptions.skip, searchValue));

		const data = dataRows.data.map((montage) => ({
			[columns[0]]: montage.id,
			[columns[1]]: montage.get('template') ? montage.get('template').get('name'): 'No Template',
			[columns[2]]: montage.updatedAt,
			[columns[3]]: montage,
		}));

		refRowsData.current = data;

		return {
			data: data,
			totalCount: dataRows.totalCount
		}
	}


	return (
		<>
			{/* ----------------- Table ----------------- */}
			<CustomTableGrid
				actionColumnWidth={120}
				onRowClick={onRowClick}
				reloadDataPagination={(loadOptions) => loadData(loadOptions)}
				reloaded={isReloaded}
				actionRender={(value) => (
					<TableButtonsAction 
						onEdit={() => _openEditDialog(value.data)}
						onDelete={() => _handleDelete(value.data.data.id)}
						openDialog={!!refRowsData.current.find(montage => montage.data.id === selectedMontage?.id)}
						label={value.data.data.id}
					/>
				)}
			>
				<Column 
					dataField={columns[0]}
					caption="ID"
					cellRender={({ data }) => <CustomCell value={data.id} />}
				/>
				<Column 
					dataField={columns[1]}
					caption="Template"
					cellRender={({ data }) => <CustomCell value={data.template} />}
				/>
				<Column 
					dataField={columns[2]}
          dataType="date"
					caption="Date Modification" 
					defaultSortOrder="desc"
					cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.updatedAt)).format('YYYY-MM-DD HH:mm')} />}
				/>
			</CustomTableGrid>

			{/* ----------------- Montage Edit Dialog ----------------- */}
			<ModalDialog
				title={`Modifier ${selectedMontage?.id}`}
				content={<MontageForm onSubmit={_save} initialValues={getInitialValues()} />}
				isVisible={!!refRowsData.current.find(montage => montage.data.id === selectedMontage?.id)}
				onConfirm={_submit}
				onClose={handleCloseDialog}
				labelConfirm="Enregistrer"
			/>
		</>
	)
}

MontagesTable.propTypes = {
	rows: PropTypes.array
};
export default MontagesTable;