import Parse from 'parse';

import { getValues, setValues } from '../parseUtils';
import { onEnter, showParseObj, actionWithLoader, push, getPhotoAppURL } from './utils';
import { showHome } from './app';

import { getBackground, getBackgrounds } from '../reducers/backgrounds';
import { formatSavedParseObjSelectOption } from '../utils';


//--------------------------------------------------------//
//------------------ Parse <=> Object --------------------//
//--------------------------------------------------------//
const Background = Parse.Object.extend('Background');

const BACKGROUND_PROPERTIES = new Set([
	'width', 'height', 'background', 'template', 'content',
]);

/**
 * set content and image default values
 * @param {Object} values
 * @returns {Object}
 */
 const setBackgroundDefaultValues = (values) => {
	if (values.template) {
		values.content = {
			width: values.template.get('width'),
			height: values.template.get('height'),
		};
	}

	const newValues = { ...values, background: { ...values.background, width: values.width, height: values.height } };

	return newValues;
}

/**
 * get the values to save to the db
 * @param {Object} values
 * @returns {Object}
 */
const backgroundFormattedValues = (values) => {
	const newValues = { ...values };
    
	if (newValues.imageFile) {
		newValues.background = { imageFile: values.imageFile };
		delete newValues.imageFile;
	}

	return newValues;
}

/**
 * get values for current background
 * @param background
 * @returns {Object}
 */
export const getBackgroundValues = (background) => {
	return getValues(background, BACKGROUND_PROPERTIES);
}
/**
 * set background values
 * @param background
 * @param values
 */
export const setBackgroundValues = (background, values) => {
	const newValues = { ...values };

	if (values?.background?.imageFile) {
		const { imageFile } = values.background;
		const parseFile = new Parse.File(imageFile.lastModified, imageFile);
		newValues.background.imageFile = parseFile;
	}

	if (values?.content?.maskImageFile) {
		const { maskImageFile } = values.content;
		const parseMaskFile = new Parse.File(maskImageFile.lastModified, maskImageFile);
		newValues.content.maskImageFile = parseMaskFile;
	}

	setValues(background, newValues, BACKGROUND_PROPERTIES);
}
//--------------------------------------------------------//
//--------------------- CRUD actions ---------------------//
//--------------------------------------------------------//
/**
 * create new background
 * @param values
 * @returns {*}
 */
export const createBackground = (values) => {
	return actionWithLoader(async (dispatch, getState) => {
		const backgrounds = getBackgrounds(getState());
		const formattedValues = backgroundFormattedValues(values);

		const newValues = setBackgroundDefaultValues(formattedValues);

		const background = new Background();

		await setBackgroundValues(background, newValues);

		if (newValues.background?.imageFile){
      await background.save(null, { context: { saveToBucket: true } });
    } else {
			await background.save();
		}

		dispatch({
			type: 'BACKGROUND_LOADED',
			background
		});
		dispatch({
			type: 'BACKGROUNDS_UPDATED',
			backgrounds: [background, ...backgrounds],
		});
	});
}

/**
 * update current background
 * @param background
 * @param values
 * @returns {*}
 */
export const updateBackground = (background, values) => {
	const formattedValues = backgroundFormattedValues(values);

	const newValues = formatSavedParseObjSelectOption(formattedValues, 'template');

	return actionWithLoader(async (dispatch, getState) => {
		setBackgroundValues(background, newValues);
		await updateBackgroundThunk(background, values)(dispatch, getState);
	});
}

/**
 * delete current background
 * @param background
 * @returns {*}
 */
export const deleteBackground = (background) => {
	return actionWithLoader(async (dispatch, getState) => {
		const backgrounds = getBackgrounds(getState());
		const newBackgrounds = backgrounds.filter((m) => m !== background);
		background.set('deleted', true);
		await background.save();

		dispatch({
			type: 'BACKGROUNDS_UPDATED', // used in backgrounds list
			backgrounds: newBackgrounds
		});

	});
}


/**
 * saves and updates background
 * @param {Object} background
 */
export function updateBackgroundThunk(background, values) {
	return async (dispatch) => {

		if (values.background?.imageFile){
      await background.save(null, { context: { saveToBucket: true } });
    } else {
			await background.save();
		}

		dispatch({
			type: 'BACKGROUND_UPDATED',
			background
		});
	}
}

/**
 * load all templates
 * @returns {Function}
 */
export const loadBackgroundsThunk = () => {
	return async (dispatch) => {
		const backgrounds = await new Parse.Query('Background')
			.include('template')
			.notEqualTo('deleted', true)
			.limit(1000)
			.find();

		if (backgrounds && Array.isArray(backgrounds)) {
			dispatch({
				type: 'BACKGROUNDS_LOADED',
				backgrounds
			});
		}
		return backgrounds;
	}
}

/**
 * onEnter backgrounds
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterBackgrounds = (store) => {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				//const backgrounds = getBackgrounds(getState());
				const backgrounds = await loadBackgroundsThunk()(dispatch, getState);
				if (!backgrounds) {
					showHome();
				}
			}
		}
	});
}

//--------------------------------------------------------//
//----------------- loading background -------------------//
//--------------------------------------------------------//
/**
 * onEnter template preview or edit page
 * @param store
 * @returns {function(*, *, *): Promise<undefined>}
 */
export const onEnterBackground = (store) => {
	return onEnter({
		store,
		actionThunk: params => {
			return async (dispatch, getState) => {
				const backgroundId = params.backgroundId;
				const background = await loadBackgroundThunk(backgroundId)(dispatch, getState);
				if (!background) {
					// template not found
					showHome();
				}
			}
		}
	});
}

/**
 * load template into redux
 * @param templateId
 * @returns {function(*, *): Promise<*>}
 */
export const loadBackgroundThunk = (backgroundId) => {
	return async (dispatch, getState) => {
		const currentBackground = getBackground(getState());
		if (!currentBackground || currentBackground.id !== backgroundId) {
			// loading template
			const background = await new Parse.Query('Background')
				.include('template')
				.equalTo('objectId', backgroundId)
				.first();

			dispatch({
				type: 'BACKGROUND_LOADED',
				background
			});
			return background;
		}

		return currentBackground;
	}
}


/**
 * load backgrounds with pagination
 * @returns {Function}
 */
 export const loadBackgroundsPerPage = (limit = 10, skip = 0, filter = '') => {
	return async (dispatch) => {
		const backgrounds = new Parse.Query('Background')
		.include('template')
		.notEqualTo('deleted', true)
		.limit(limit)
		.skip(skip)
		.descending('updatedAt')
		.withCount(true);

		if (filter) {
			backgrounds.startsWith('template.name', filter.toString());
		}

		const result = await backgrounds.find();

		dispatch({
			type: 'BACKGROUNDS_LOADED',
			backgrounds: result.results
		});

		return {
			data: result.results,
			totalCount: result.count
		};
	}
}

/**
 * update background propertiesValues
 * @param {*} backgroundPropertiesValues 
 * @returns 
 */
export function updateBackgroundPropertiesValues(backgroundPropertiesValues){
  return async (dispatch) => {

		//-----------------------------------------------------------------------------------------------------//
		//---------------------- Temporary file for Background image and mask content -------------------------//
		//-----------------------------------------------------------------------------------------------------//

		if (backgroundPropertiesValues?.background?.imageFile) {
				const hasParseFile = backgroundPropertiesValues.background.imageFile instanceof Parse.File;
				if (!hasParseFile) {
					backgroundPropertiesValues.background.imageFileTemp = backgroundPropertiesValues.background.imageFile;
				}
		}

		if (backgroundPropertiesValues?.content?.maskImageFile) {
			const hasParseFile = backgroundPropertiesValues.content.maskImageFile instanceof Parse.File;
			if (!hasParseFile) {
				backgroundPropertiesValues.content.maskImageFileTemp = backgroundPropertiesValues.content.maskImageFile;
			}
	}

		dispatch({
			type: 'BACKGROUND_PROPERTIES_UPDATED',
			backgroundPropertiesValues
    });
   
  }
}

/**
 * save backgroud
 * @param {*} background 
 * @param {*} values 
 * @returns 
 */
export const updateBackgroundEditor = (background, values) => {

	return actionWithLoader(async (dispatch, getState) => {
		setBackgroundValues(background, values);
		await updateBackgroundThunk(background, values)(dispatch, getState);
	});
}


//--------------------------------------------------------//
//---------------------- Routing -------------------------//
//--------------------------------------------------------//
/**
 * show background
 * @param backgroundId
 * @param fromNewTab
 */
export function showBackground(backgroundId, fromNewTab= false) {
	if (fromNewTab) {
		const url = getPhotoAppURL() + '/background-' + backgroundId
		window.open(url);
		return;
	}
	return showParseObj('background', backgroundId);
}

export function showBackgroundCreation() {
	return push('/backgroundCreation');
}

export function showBackgrounds() {
	return push('/backgrounds');
}
export function showBackgroundEdit(backgroundId) {
	return push('/backgroundEdit-' + backgroundId)
}