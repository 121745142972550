import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import ListIconButton from '../../components/buttons/ListIconButton';
import MontageForm from './MontageForm';
import { createMontage, showMontages } from '../../actions/montages';

const MontageCreation = () => {
	// dispatch
	const dispatch = useDispatch();

	// save change
	const _createMontage = async(values) => {
		const newValues = { ...values };

		if (values?.template?.parseObj) {
			newValues.template = values.template.parseObj;
		}
		// create new montages
		await dispatch(createMontage(newValues));
	};

	const _submit = () => {
		dispatch(submit('montageForm'));
		dispatch(showMontages());
	};

	const _showMontages = () => {
		dispatch(showMontages());
	};

	return (
		<CustomCard
			fullScreen
			title='Nouveau montage'
			content={
				<MontageForm
					onSubmit={_createMontage}
				/>
			}
			okLabel="Enregistrer"
			okAction={_submit}
			actionHeaderButtons={<ListIconButton onShowList={_showMontages} />}
		/>
	)
}

export default MontageCreation;