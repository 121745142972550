import React from 'react';
import { round } from 'lodash';
import PropTypes from 'prop-types';

import ZoomOutButton from './buttons/ZoomOutButton';
import AddIconButton from './buttons/AddIconButton';

const ZoomButtons = ({ value, onChange, className }) => {

	const zoomOut = () => {
		onChange(Math.max(0.1, round(value - 0.1, 1)));
	};

	const zoomIn = () => {
		onChange(round(value + 0.1, 1));
	};

	return (
		<div className={className}>
			<div>
				<ZoomOutButton onRemove={zoomOut} color='default'/>
				<AddIconButton onAdd={zoomIn} color='default'/>
			</div>
		</div>
	)
}

ZoomButtons.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.any,
	className: PropTypes.string,
}

export default ZoomButtons;