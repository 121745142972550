import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

const EditIconButton = (props) => {
	// props
	const { onEdit } = props;

	return (
		<IconButton
			aria-label='view-edition'
			onClick={onEdit}
		>
			<EditIcon />
		</IconButton>
	);
}

EditIconButton.propTypes = {
	onEdit: PropTypes.func
}
export default EditIconButton;