import React from 'react';
import { useSelector } from 'react-redux';

import CustomCard from '../../components/CustomCard';
import ProductsTable from './ProductsTable';

import { getProducts } from '../../reducers/products';
import { getTemplates } from '../../reducers/templates';

const Products = () => {
	// selectors
	const products = useSelector(getProducts) || [];
	const templates = useSelector(getTemplates);
	
	return (
		<CustomCard
			title='Liste des produits'
			content={<ProductsTable data={products} templates={templates} />}
			withActionButtons={false}
			fullScreen
		/>
	)
}

export default Products;