import { useState, useMemo, useEffect } from 'react';

/**
 * get zoom, borderWidth
 * @param {parseObject} parseObject 
 * @param {number} defaultBorderWidth 
 */
export const useZoom = (parseObject, defaultBorderWidth = 2 ) => {

	const [zoom, setZoom] = useState(1);

	// set zoom to   
	useEffect(() => {
		if (parseObject.has('editor')) {
			const editor = parseObject.get('editor');
			setZoom(editor.zoom ?? 1);
		}

	}, [parseObject]);

	// border width

	const borderWidth = useMemo(() => {
		// use Arithmetic Progression to calculate borderWidth
		const term = Math.ceil((1-zoom)/0.1) ;
		// use this Un = U0 + n*r 
		//-> r = common difference = 0.35
		//-> u0 = defaultBorderWidth
		const border = Math.max(defaultBorderWidth, defaultBorderWidth + term*0.35);
		return border;
	}, [zoom, defaultBorderWidth]);

	return {
		zoom,
		borderWidth,
		setZoom,
	}
}