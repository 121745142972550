import React from 'react';
import { useSelector } from 'react-redux';

import CustomCard from '../../components/CustomCard';
import OrdersTable from './OrdersTable';

import { getOrders } from '../../reducers/orders';

const Orders = () => {
	// selectors
	const orders = useSelector(getOrders) || [];
	
	return (
		<CustomCard
			title='Liste des commandes'
			content={<OrdersTable data={orders} />}
			withActionButtons={false}
			fullScreen
		/>
	)
}

export default Orders;