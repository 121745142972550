import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Column } from 'devextreme-react/data-grid';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from 'draft-js';

import { toMoment } from '../../utils';
import ModalDialog from '../../components/ModalDialog';
import Table from '../../components/devExpressTable/Table';
import CustomCell from '../../components/devExpressTable/CustomCell';
import CustomImageCell from '../../components/devExpressTable/CustomImageCell';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import PromoBannerForm from './PromoBannerForm';
import { deletePromoBanner, showPromoBanners, createOrUpdatePromoBanner } from '../../actions/promoBanners';
import { validatePromoBanner } from '../../validation';


const useStyles = makeStyles({
	root: {},
});

const columns = ['id', 'image', 'title', 'desc', 'tag', 'createdAt', 'descLong'];

const PromoBannersTable = ({ data }) => {

	// styles
	const classes = useStyles();

	const dispatch = useDispatch();

	// state
	const [selectedPromoBanner, setSelectedPromoBanner] = useState(null);

	// row click
	const onRowClick = ({ data }) => {
		setSelectedPromoBanner({
			...data,
		});
	}
	
	// close dialog
	const handleCloseDialog = () => {
		setSelectedPromoBanner(null);
	}

	// initial value for editing
	const getInitialFormValues = useCallback(() => {
		if (!selectedPromoBanner) return;

		const initialValue = { ...selectedPromoBanner };
		if (selectedPromoBanner.descLong) {
			const contentBlock = htmlToDraft(selectedPromoBanner.descLong);
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			initialValue.descLong = editorState;
		}

		return {
			...initialValue,
		};
	}, [selectedPromoBanner]);

	// table data sources
	const getDataSource = useCallback(() =>
		data.results.map((promoBanner) => ({
			[columns[0]]: promoBanner.id,
			[columns[1]]: promoBanner.image.url,
			[columns[2]]: promoBanner.title,
			[columns[3]]: promoBanner.desc,
			[columns[4]]: promoBanner.tag,
			[columns[5]]: promoBanner.createdAt,
			[columns[6]]: promoBanner.descLong,
		}))
	, [data]);

	// delete a montage
	const _handleDelete = (promoBannerId) => {
		dispatch(deletePromoBanner(promoBannerId));
	}

	// save change
	const _updatePromoBanner = async (values) => {
		// true if it's an update
		validatePromoBanner(values, true);

		dispatch(createOrUpdatePromoBanner({ id: selectedPromoBanner.id, ...values }));
		
		handleCloseDialog();
		showPromoBanners();
	};

	const _submit = () => {
		dispatch(submit('promoBannerForm'));
	};

	return (
		<div className={classes.root}>
			{/* ----------------- PromoBanner Table ----------------- */}
			<Table
				dataSource={getDataSource()}
				showColumnLines={false}
				onRowClick={onRowClick}
				actionRender={(value) => (
					<TableButtonsAction 
						onDelete={() => _handleDelete(value.data.id)}
						openDialog={!!data.results.find(promoBanner => promoBanner.id === selectedPromoBanner?.id)}
						label={value.data.title}
					/>
				)}
			>
				<Column 
					dataField={columns[1]}
					caption="Image"
					cellRender={({ data }) => <CustomImageCell value={data.image} />}
				/>
				<Column
					dataField={columns[2]}
					caption="Titre" 
					cellRender={({ data }) => <CustomCell value={data.title} />}
					/>
				<Column
					dataField={columns[3]}
					caption="Description"
					cellRender={({ data }) => <CustomCell value={data.desc} />}
				/>
				<Column
					dataField={columns[4]}
					alignment="right"
					caption="Tag"
					cellRender={({ data }) => <CustomCell value={data.tag || '-'} alignment="right" />}
				/>
				<Column
					dataField={columns[5]}
          dataType="date"
					caption="Ajouté le"
					cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.createdAt)).format('YYYY-MM-DD')} />}
					defaultSortOrder="desc"
				/>
			</Table>

			{/* ----------------- PromoBanner Dialog ----------------- */}
				<ModalDialog
					title={`Modifier ${selectedPromoBanner?.name}`}
					content={
						<PromoBannerForm 
							onSubmit={_updatePromoBanner} 
							initialValues={getInitialFormValues()}
						/>
					}
					isVisible={!!data.results.find(promoBanner => promoBanner.id === selectedPromoBanner?.id)}
					onConfirm={_submit}
					onClose={handleCloseDialog}
					labelConfirm="Enregistrer"
				/>
		</div>
	)
}

PromoBannersTable.propTypes = {
	data: PropTypes.any,
};
export default PromoBannersTable;