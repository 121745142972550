import { getData } from './index';

export default function promoBanners (
	state = {
		promoBanner: null,
		promoBannerLocalVersion: 0,
		promoBanners: null,
		promoBannersLocalVersion: 0
	}, action) {
	switch (action.type) {
		case 'PROMOBANNER_LOADED':
			return {
				...state,
				promoBanner: action.promoBanner
			};
		case 'REMOVE_PROMOBANNER':
			return {
				...state,
				promoBanner: null
			};
		case 'PROMOBANNER_UPDATED':
			return {
				...state,
				promoBanner: action.promoBanner,
				promoBannerLocalVersion: state.promoBannerLocalVersion + 1
			};
		case 'PROMOBANNERS_LOADED':
			return {
				...state,
				promoBanners: action.promoBanners
			};
		case 'PROMOBANNERS_UPDATED':
			return {
				...state,
				promoBanners: action.promoBanners,
				promoBannersLocalVersion: state.promoBannersLocalVersion + 1
			};
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getPromoBanner(state, errorIfNotFound = false) {
	return getData(state, "promoBanners.promoBanner", errorIfNotFound && "No promoBanner data found");
}
export function getPromoBannerLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "promoBanners.promoBannerLocalVersion", errorIfNotFound && "No promoBanner data found");
}
export function getPromoBanners(state, errorIfNotFound = false) {
	return getData(state, "promoBanners.promoBanners", errorIfNotFound && "No promoBanners data found");
}
export function getPromoBannersResults(state, errorIfNotFound = false) {
	return getData(state, "promoBanners.promoBanners.results", errorIfNotFound && "No promoBanners data found");
}
export function getPromoBannersLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "promoBanners.promoBannersLocalVersion", errorIfNotFound && "No promoBanners data found");
}