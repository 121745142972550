import React from 'react';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';

import { makeStyles } from '@material-ui/core/styles';

import CustomCard from '../../components/CustomCard';
import BackgroundForm from './BackgroundForm';

import { createBackground, showBackgrounds } from '../../actions/backgrounds';

const useStyles = makeStyles({
	root: {
		minWidth: '70vw',
		maxWidth: '95vw'
	},
	content: {
		minWidth: '60vw',
		maxHeight: '75vh',
		overflow: 'auto'
	}
});

const BackgroundCreation = (props) => {
	// styles
	const classes = useStyles(props);

	// dispatch
	const dispatch = useDispatch();

	// save change
	const _createBackground = async(values) => {
		const newValues = { ...values };

		if (values?.template?.parseObj) {
			newValues.template = values.template.parseObj;
		}
		// create new montages
		await dispatch(createBackground(newValues));
	};

	const _cancelAction = () => {
		dispatch(showBackgrounds());
	};

	const _submit = () => dispatch(submit('backgroundForm'));

	return (
		<CustomCard
			fullScreen
			title='Nouveau fond '
			content={
				<BackgroundForm
					onSubmit={_createBackground}
				/>
			}
			okLabel="Enregistrer"
			okAction={_submit}
			cancelAction={_cancelAction}
			contentClassName={classes.content} 
		/>
	)
}

export default BackgroundCreation;