import { getData } from './index';

export default function products (
	state = {
		product: null,
		productLocalVersion: 0,
		products: null,
		productsLocalVersion: 0
	}, action) {
	switch (action.type) {
		case 'PRODUCT_LOADED':
			return {
				...state,
				product: action.product
			};
		case 'REMOVE_PRODUCT':
			return {
				...state,
				product: null
			};
		case 'PRODUCT_UPDATED':
			return {
				...state,
				product: action.product,
				productLocalVersion: state.productLocalVersion + 1
			};
		case 'PRODUCTS_LOADED':
			return {
				...state,
				products: action.products
			};
		case 'PRODUCTS_UPDATED':
			return {
				...state,
				products: action.products,
				productsLocalVersion: state.productsLocalVersion + 1
			};
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getProduct(state, errorIfNotFound = false) {
	return getData(state, "products.product", errorIfNotFound && "No product data found");
}
export function getProductLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "products.productLocalVersion", errorIfNotFound && "No product data found");
}
export function getProducts(state, errorIfNotFound = false) {
	return getData(state, "products.products", errorIfNotFound && "No products data found");
}
export function getProductsResults(state, errorIfNotFound = false) {
	return getData(state, "products.products.results", errorIfNotFound && "No products data found");
}
export function getProductsLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "products.productsLocalVersion", errorIfNotFound && "No products data found");
}