import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { submit } from 'redux-form';

import CustomCard from '../../components/CustomCard';
import PreviewIconButton from '../../components/buttons/PreviewIconButton';
import ListIconButton from '../../components/buttons/ListIconButton';
import DeleteIconButton from '../../components/buttons/DeleteIconButton';
import BackgroundForm from './BackgroundForm';

import { deleteBackground, getBackgroundValues, showBackground, showBackgrounds, updateBackground } from '../../actions/backgrounds';
import { getBackground } from '../../reducers/backgrounds';
import { formatParseObjSelectOption } from '../../utils';

const BackgroundEdition = () => {
	// selectors
	const background = useSelector(getBackground);

	// dispatch
	const dispatch = useDispatch();

	const getInitialValues = useCallback(() => {
		const newBackground = getBackgroundValues(background);
		return {
			...newBackground,
			template: formatParseObjSelectOption(newBackground.template),
		};
	}, [background]);

	if (!background) return null;
	const backgroundId = background.id;

	// save change
	const _save = async (values) => {
		await dispatch(updateBackground(background, values));
		dispatch(showBackgrounds());
	};
	
	const _submit = () => dispatch(submit('backgroundForm'));
	
	const handleDelete = (background) => {
		dispatch(deleteBackground(background));
		showBackgrounds();
	};

	const actionHeaderButtons = (
		<div>
			<PreviewIconButton onShowPreview={() => showBackground(background)} />
			<ListIconButton onShowList={showBackgrounds} />
			<DeleteIconButton onDelete={() => handleDelete(background)} />
		</div>
	);

	return (
		<CustomCard
			fullScreen
			title={'Editer le background ' + backgroundId}
			content={
				<BackgroundForm
					backgroundId={backgroundId}
					onSubmit={_save}
					initialValues={getInitialValues()}
				/>
			}
			okLabel="Enregistrer la modification"
			okAction={_submit}
			actionHeaderButtons={actionHeaderButtons}
		/>

	)
}

BackgroundEdition.propTypes = {
	background: PropTypes.object
};
export default BackgroundEdition;