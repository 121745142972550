import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'devextreme-react/data-grid';
import { submit } from 'redux-form';

import { toMoment } from '../../utils';
import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';
import ModalDialog from '../../components/ModalDialog';
import BackgroundForm, {ToBackgroundProperties, valuesFromBackground} from './BackgroundForm';
import { deleteBackground, loadBackgroundsPerPage, showBackground, showBackgrounds, updateBackground } from '../../actions/backgrounds';
import CustomCell from '../../components/devExpressTable/CustomCell';
import { validateBackground } from '../../validation';
import { getBackgrounds } from '../../reducers/backgrounds';

const columns = ['id', 'template', 'width', 'height', 'updatedAt', 'data'];

const BackgroundsTable = ({isReloaded, onReload}) => {

	// state
	const [selectedBackground, setSelectedBackground] = useState(null);
	const [rows, setRows] = useState([]);

	// selector
	const backgrounds = useSelector(getBackgrounds);

	// dispatch
	const dispatch = useDispatch();


	const formInitialValues = useMemo(() => {
		if (!selectedBackground) {
			return {};
		}
		const values = valuesFromBackground(selectedBackground);
		return values;

	}, [selectedBackground]);

	// row click
	const onRowClick = ({ data }) => {
		if (!data.data) return;
		
		const backgroundId = data.data.id;
		showBackground(backgroundId);
	}

	// open dialog
	const _openEditDialog = (selectedData) => {
		if (selectedData?.data) {
			setSelectedBackground(selectedData.data);
		}
	}

	// close dialog
	const handleCloseDialog = () => {
		setSelectedBackground(null);
	}

	// save change
	const _editBackground = async (values) => {
	  validateBackground(values);
	
		const newBackgroudValues = ToBackgroundProperties(values);
		await dispatch(updateBackground(selectedBackground, newBackgroudValues)).then(()=> {
			onReload(!!!isReloaded);
		});
		handleCloseDialog();
		showBackgrounds();
	};

	// delete a background
	const _handleDelete = (backgroundId) => {
		const deletedBackground = rows.find((row) => row.data.id === backgroundId);
		dispatch(deleteBackground(deletedBackground.data)).then(() => {
			onReload(!!!isReloaded);
		});
	}

	// submit change
	const _submit = () => dispatch(submit('backgroundForm'));

	// load the table source data
	const loadData = useCallback(async (loadOptions) => {
			let searchValue;

			if (loadOptions.filter) {
				searchValue = loadOptions.filter[0].filterValue;
			}
	
			const dataRows = await dispatch(loadBackgroundsPerPage(loadOptions.take, loadOptions.skip, searchValue));
	
			const data = dataRows.data.map((background) => ({
				[columns[0]]: background.id,
				[columns[1]]: background.get('template') ? background.get('template').get('name'): 'No Template',
				[columns[2]]: background.get('width'),
				[columns[3]]: background.get('height'),
				[columns[4]]: background.updatedAt,
				[columns[5]]: background,
			}));
	
			setRows(data);
	
			return {
				data: data,
				totalCount: dataRows.totalCount
			}
	// need the backgrounds from store to re-render the table when adding a new one
	// because of the devexpress table pagination we do not load the data from store
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgrounds, dispatch]);


	return (
		<>
			{/* ----------------- Table ----------------- */}
			<CustomTableGrid
				actionColumnWidth={120}
				onRowClick={onRowClick}
				reloadDataPagination={loadData}
				reloaded={isReloaded}
				actionRender={(value) => (
					<TableButtonsAction 
						onEdit={() => _openEditDialog(value.data)}
						onDelete={() => _handleDelete(value.data.data.id)}
						openDialog={!!rows.find(background => background.data.id === selectedBackground?.id)}
						label={value.data.data.id}
					/>
				)}
			>
				<Column 
					dataField={columns[0]}
					caption="Id"
					cellRender={({ data }) => <CustomCell value={data.id} />}
				/>
				<Column 
					dataField={columns[1]}
					caption="Template"
					cellRender={({ data }) => <CustomCell value={data.template} />}
				/>
				<Column 
					dataField={columns[2]}
					caption="Largeur"
					cellRender={({ column, data }) => <CustomCell value={data.width} alignment={column.alignment} />}
				/>
				<Column 
					dataField={columns[3]}
					caption="Hauteur"
					alignment="right"
					cellRender={({ column, data }) => <CustomCell value={data.height} alignment={column.alignment} />}
				/>
				<Column 
					dataField={columns[4]}
          dataType="date"
					caption="Date Modification" 
					defaultSortOrder="desc"
					cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.updatedAt)).format('YYYY-MM-DD')} />}
				/>
			</CustomTableGrid>

			{/* ----------------- Background Edit Dialog ----------------- */}
			<ModalDialog
				title={`Modifier ${selectedBackground?.id}`}
				content={<BackgroundForm onSubmit={_editBackground} initialValues={formInitialValues} />}
				isVisible={!!rows.find(background => background.data.id === selectedBackground?.id)}
				onConfirm={_submit}
				onClose={handleCloseDialog}
				labelConfirm="Enregistrer"
			/>
		</>
	)
}

export default BackgroundsTable;