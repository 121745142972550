import React from 'react';
import PropTypes from 'prop-types';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { IconButton } from '@material-ui/core';

const VisibilityOffButton = (props) => {
	// props
	const { onHide, color = 'primary', className } = props;

	return (
		<IconButton
			aria-label='add'
			onClick={onHide}
			color={color}
			className={className}
		>
			<VisibilityOffIcon />
		</IconButton>
	);
}

VisibilityOffButton.propTypes = {
	onHide: PropTypes.func,
	color: PropTypes.oneOf(['default', 'primary', 'secondary']),
	className: PropTypes.string
}
export default VisibilityOffButton;