import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { submit } from 'redux-form';
import { Column } from 'devextreme-react/data-grid';

import { validateTheme } from '../../validation';
import { formatParseObjSelectOptions, toMoment } from '../../utils';

import TableButtonsAction from '../../components/devExpressTable/TableButtonsAction';
import ModalDialog from '../../components/ModalDialog';
import CustomCell from '../../components/devExpressTable/CustomCell';
import ThemeForm from './ThemeForm';

import { deleteTheme, getThemeValues, loadThemesPerPage, updateTheme} from '../../actions/themes';
import CustomTableGrid from '../../components/devExpressTable/CustomTableGrid';


// default columns
const columns = ['id', 'name', 'updatedAt', 'templatesCount', 'templates', 'data'];

const ThemesTable = ({ rows }) => {
	// state
	const [selectedTheme, setSelectedTheme] = useState(null);
	const refRowsData = useRef([])


	// dispatch
	const dispatch = useDispatch();

		// get initial values
		const getInitialValues = useCallback(() => {
			if (!selectedTheme) return;

			const themeValues = getThemeValues(selectedTheme);
			return {
				...themeValues,
				/** format the templates into react select options */
				templates: formatParseObjSelectOptions(themeValues.templates),
			};

		}, [selectedTheme]);

	// delete theme
	const handleDelete = (themeId) => {
		const deletedTheme = refRowsData.current.find((row) => row.id === themeId);
		dispatch(deleteTheme(deletedTheme.data));
	}

	// row click
	const onRowClick = ({ data }) => {
		if (!data.data) return;
		setSelectedTheme(data.data);
	}
	
	// close dialog
	const handleCloseDialog = () => {
		setSelectedTheme(null);
	}

	// save edition
	const _save = async (values) => {
		validateTheme(values);

		await dispatch(updateTheme(selectedTheme, values));
		handleCloseDialog();
	};
	
	// submit change
	const _submit = () => dispatch(submit('themeForm'));

	// Customized load data 
	const loadThemesData = async (loadOptions) => {
		const dataRows = await dispatch(loadThemesPerPage(loadOptions.take, loadOptions.skip));

		// retrieving data
		const data = dataRows.data.map((theme) => ({
			[columns[0]]: theme.id,
			[columns[1]]: theme.get('name'),
			[columns[2]]: theme.updatedAt,
			[columns[3]]: theme.has('templates') ? theme.get('templates').map(t => t.get('name')).join(', ') : '',
			[columns[4]]: theme.has('templates') ? theme.get('templates') : [],
			[columns[5]]: theme,
		}));

    	refRowsData.current = data;

		return {
			data: data,
			totalCount: dataRows.totalCount
		}
	}

	return (
		<>
			<CustomTableGrid
				reloadDataPagination={(loadOptions) => loadThemesData(loadOptions)}
				onRowClick={onRowClick}

				actionRender={value => (
					<TableButtonsAction 
						onDelete={() => handleDelete(value.data.id)}
						name={value.data.name}
					/>
				)}
			>
				<Column
					dataField={columns[1]}
					caption="Nom"
					cellRender={({ data }) => <CustomCell value={data.name} />}
				/>
				<Column
					dataField={columns[2]}
          dataType="date"
					caption="Date Modification" 
					defaultSortOrder="desc"
					cellRender={({ data }) => <CustomCell value={toMoment(new Date(data.updatedAt)).format('YYYY-MM-DD')} />}
				/>
				<Column
					dataField={columns[3]}
					caption="Templates"
					cellRender={({ data }) => <CustomCell value={data.templatesCount} />}
				/>
			</CustomTableGrid>

			{/* ----------------- Theme Edit Dialog ----------------- */}
			<ModalDialog
				title={`Modifier -  ${selectedTheme?.get('name')}`}
				content={<ThemeForm onSubmit={_save} initialValues={getInitialValues()} />}
				isVisible={!!refRowsData.current.find(theme => theme.id === selectedTheme?.id)}
				onClose={handleCloseDialog}
				onConfirm={_submit}
				labelConfirm="Enregistrer"
			/>
		</>
	)
}

ThemesTable.propTypes = {
	rows: PropTypes.array
};
export default ThemesTable;