import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		composes: 'flexColumn stretch',
	},
	container: {
		composes: 'flexColumn center',
		padding: '0 20px 15px 20px'
	},
	btn: {
		margin: theme.spacing(1),
		marginTop: 45
	},
	summary: {
		composes: 'flex1'
	}
}));

const Home = (props) => {
	// styles
	const classes = useStyles(props);

	return (
		<div className={classes.root}>
			
		</div>
	);
};

export default Home;