import React from 'react';
import PropTypes from 'prop-types';

import CurrentUserIcon from '@material-ui/icons/Person';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ImageIcon from '@material-ui/icons/Image';
import CategoryIcon from '@material-ui/icons/Category';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BrandingWatermarkIcon from '@material-ui/icons/BrandingWatermark';
import DvrIcon from '@material-ui/icons/Dvr';

const MenuItemIcon = (props) => {
	const { name } = props;

	let icon = null;
	switch (name) {
		case 'user':
			icon = <CurrentUserIcon />; break;
		case 'scan':
		case 'templates':
			icon = <ImageIcon />; break;
		case 'montages':
			icon = <AspectRatioIcon />; break;
		case 'backgrounds':
			icon = <CategoryIcon />; break;
		case 'products':
			icon = <BurstModeIcon />; break;
		case 'orders':
			icon = <ShoppingBasketIcon />; break;
		case 'promoBanners':
			icon = <BrandingWatermarkIcon />; break;
		case 'test':
			icon = <FileCopyIcon />; break;

		case 'updateOrderAssets':
			icon = <DvrIcon />; break;
		case 'logout':
			icon = <LogoutIcon />; break;
		default:
			break;
	}

	return icon;
};

MenuItemIcon.propTypes = {
	name: PropTypes.string
};

export default MenuItemIcon;