import { getData } from './index';

export default function montages (
	state = {
		montage: null,
		montageLocalVersion: 0,
		montages: [],
		montagesLocalVersion: 0,
		count: 0,
		imageId:null
	}, action) {
	switch (action.type) {
		case 'MONTAGE_LOADED':
			return {
				...state,
				montage: action.montage
			};
		case 'REMOVE_MONTAGE':
			return {
				...state,
				montage: null
			};
		case 'MONTAGE_UPDATED':
			return {
				...state,
				montage: action.montage,
				montageLocalVersion: state.montageLocalVersion + 1
			};
		case 'MONTAGES_LOADED':
			return {
				...state,
				montages: action.montages,
				count: action.count
			};
		case 'MONTAGES_UPDATED':
			return {
				...state,
				montages: action.montages,
				montagesLocalVersion: state.montagesLocalVersion + 1
			};
		case 'IMAGE_TEST_LOADED':
			return {
				...state,
				imageId: action.imageId
			}
		default:
			return state;
	}
}

//------------------------------------------------------------------//
//--------------------------- Selectors ----------------------------//
//------------------------------------------------------------------//
export function getMontage(state, errorIfNotFound = false) {
	return getData(state, "montages.montage", errorIfNotFound && "No montage data found");
}
export function getMontageLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "montages.montageLocalVersion", errorIfNotFound && "No montage data found");
}
export function getMontages(state, errorIfNotFound = false) {
	return getData(state, "montages.montages", errorIfNotFound && "No montages data found");
}
export function getMontagesLocalVersion(state, errorIfNotFound = false) {
	return getData(state, "montages.montagesLocalVersion", errorIfNotFound && "No montages data found");
}
export function getMontageCount(state, errorIfNotFound = false) {
	return getData(state, "montages.count", errorIfNotFound && "No montages data found");
}
export function getImageIdTest(state, errorIfNotFound = false) {
	return getData(state, "montages.imageId", errorIfNotFound && "No montages data found");
}