import React from 'react';

import {  getPathImageGCP} from '../../utils';

import LayerPreview from './layers/LayerPreview';

const MaskPreview = ( props ) => {

	const { 
		mask,
		templateId,
		selectedItem,
		onUpdateItemMaskPreview,
		maxWidth,
		maxHeight,
		scale,
		border,
		selected,
		childrenSelected,
		onSelection,
	} = props;

	const isSelected = (item) => {
		return selectedItem ? item.id===selectedItem.id : false;
	}

	//------------------------ Get Path Mask -----------------------------------//
	
	const maskPath = mask.imageTemp ? URL.createObjectURL(mask.imageTemp) : ( mask.imageId ? getPathImageGCP(templateId,mask.imageId)  : '');

  return (
				<LayerPreview 	
					layer={mask} 
					maxWidth={maxWidth}
					maxHeight={maxHeight}
					scale={scale}
					maskPath={ selected ? maskPath : '' }
					selected={selected}
					childrenSelected={childrenSelected}
					onUpdatePreview={onUpdateItemMaskPreview}
					border={border}
					onSelection={onSelection}
				>
					<div 
						style={{
							width:'100%',
							height:'100%',
							position : 'relative',
							maskImage :  `url(${maskPath})`, 
							WebkitMaskImage : `url(${maskPath})`,
							maskSize: '100% 100%', 
							WebkitMaskSize : '100% 100%',
							maskRepeat: 'no-repeat' ,
							WebkitMaskRepeat  : 'no-repeat',
							maskMode:'alpha',
							WebkitMaskMode : 'alpha',
						}}
					>
				{!selected && mask.layers.map((item, index)=>(
						<LayerPreview
							layer={item}
							key={index}
							selected={isSelected(item)}
							templateId={templateId}
							maxWidth={mask.width}
							maxHeight={mask.height} 
							scale={scale}
							border={border}
							maskPath = {maskPath}
							onUpdatePreview={onUpdateItemMaskPreview}
						/>
				))}
				</div>
				</LayerPreview>
		
  );

}

export default MaskPreview;