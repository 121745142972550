import React, { useEffect,useMemo } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector,change } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';
import { batch, useDispatch, useSelector } from 'react-redux';

import FormField from '../../components/form/FormField';
import FormSection from '../../components/form/FormSection';
import ReduxFormReactSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactSelect';
import DropFileUploader from '../../components/form/DropFileUploader';
import ReduxFormInputColor from '../../components/reduxFormMUIWrapper/ReduxFormInputColor';
import ReduxFormCheckbox from '../../components/reduxFormMUIWrapper/ReduxFormCheckbox';

import { loadProductsThunk } from '../../actions/products';
//import { loadThemesThunk } from '../../actions/themes';

import { getProducts } from '../../reducers/products';
import {getTemplateValues} from '../../actions/templates';
//import { getThemes } from '../../reducers/themes';

const useStyles = makeStyles({
	root: {
		composes: 'flexColumn stretch'
	},
	section: {
		width: '100%'
	},
	row: {
		composes: 'flexRow spaceAround',
		width: '100%'
	},
	fieldArrayContainer: {},
	fieldArrayHeader: {
		display: 'flex'
	},
	containerColor:{
		position: 'absolute',
    zIndex: 100,
    bottom: 0,
	},
	inputColor:{
		marginTop: 8,
	}
});

const createDimensionOption = dimension => {
	return {
		label: `${dimension.totalWidth}x${dimension.totalHeight}`,
		value: dimension
	};
}

/**
 * @param dimensions an array of objects containing width, height, lostBorder, totalWidth and totalHeight (
 * @returns {*[]|*}
 */
const createDimensionsOptions = dimensions => {
	if (!dimensions || dimensions?.length <= 0) {
		return [];
	}
	return dimensions.map(createDimensionOption);
}

const validate = values =>{
	const errors = {};
	
	if(!values.dimension){
		errors.dimension =  'required';
	}
	if(!values.name){
		errors.name =  'required';
	}
	return errors;
}

const TemplateForm = (props) => {
	// state
	
	// props
	const { handleSubmit } = props;

	// dispatch
	const dispatch = useDispatch();

	// selectors
	const products = useSelector(getProducts);
	//const themes = useSelector(getThemes);

	// styles
	const classes = useStyles(props);


	// form values that must trigger a render
	const selector = formValueSelector('templateForm');
	const dimension = useSelector(state => selector(state, 'dimension'));
	const transparent  = useSelector(state => selector(state, 'transparent'));

	const _onCheck =  (value) => {
		if (value) {
			dispatch(change('templateForm', 'backgroundColor', '#ffffff' ));
		}
	}

	const filterProducts = results => {
		if (!results || !dimension?.value) return [];
		const filteredProducts = [];
		const { totalWidth, totalHeight } = dimension.value;
		results.forEach(result => {
			const { totalWidth: resultWidth, totalHeight: resultHeight } = result.attributes;
			if (resultWidth === totalWidth && resultHeight === totalHeight) {
				filteredProducts.push(result);
			}
		});

		return filteredProducts;
	}

	const filteredProducts = useMemo(()=>{
		return filterProducts(products?.results);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dimension]);

	useEffect(() => {
		batch(() => {
			dispatch(loadProductsThunk({ limit: 1000, fields: 'name, attributes' })); // max value authorized by Swell
			// dispatch(loadThemesThunk());
		});
	}, [dispatch]);

	

	return (
		<form className={classes.root} onSubmit={handleSubmit}>
			<FormSection title='Généralité' fullWidth className={classes.section}>
				<FormField label='Nom' name='name' fullWidth/>
				<FormField
					label='Dimensions'
					name='dimension'
					fullWidth
					component={ReduxFormReactSelect}
					options={createDimensionsOptions(products?.dimensions)}
					isOptionSelected={ (option, selectValue) => {
						// selected value null in created form 
						if (selectValue.length === 0) return false; 
						const dim = selectValue[0].value;
						const optionValue = option.value;
						return dim.totalWidth === optionValue.totalWidth && dim.totalHeight === optionValue.totalHeight;
					}}
				/>
				<FormField
					label='Produits'
					name='products'
					fullWidth
					isMulti
					component={ReduxFormReactSelect}
					options={toProductOptions(filteredProducts)}
				/>
				{/* <FormField
					label='Thèmes'
					name='themes'
					fullWidth
					isMulti
					component={ReduxFormReactSelect}
					options={formatParseObjSelectOptions(themes)}
				/> */}

				<FormField
					fullWidth
					name='transparent'
					label='Couleur de fond'
					onChange= {_onCheck}
					labelCheckbox='Transparente'
					component={ReduxFormCheckbox}
					colorClassName={classes.containerColor}
					rootClassName={classes.inputColor}
				/>
				{<FormField
					fullWidth
					name='backgroundColor'
					label='Couleur de fond'
					isDisabled = {transparent}
					component={ReduxFormInputColor}
					colorClassName={classes.containerColor}
					rootClassName={classes.inputColor}
					hideLabel
				/>}
			
			</FormSection>
			<FormSection title='Image' fullWidth className={classes.section}>
				<FormField
					// label='image'
					name='image'
					component={DropFileUploader}
					fullWidth
				/>
			</FormSection>
			<input type='submit'/>
		</form>
	);
};

TemplateForm.propTypes = {
	onSubmit: PropTypes.func,
	resetForm: PropTypes.bool
};
export default reduxForm({
	form: 'templateForm',
	asyncValidating  : true,
	validate
})(TemplateForm);


//-------------------------------------------------------------------//
//--------------------- From and to template ------------------------//
//-------------------------------------------------------------------//
export function valuesFromTemplate(template) {
	const values = getTemplateValues(template);
	const transparent = values.backgroundColor === 'transparent';

	return {
		...values,
		transparent,
		backgroundColor: transparent ? '#ffffff' :  values.backgroundColor,
		dimension: createDimensionOption({
			totalWidth: values.width,
			totalHeight: values.height
		}),
		/** format the template products into the react select options */
		products: toProductOptions(values.products)
		// themes: formatParseObjSelectOptions(selectedTemplate.themes),
	};
}

export function ToTemplateProperties(values) {
	const { dimension: { value: { totalWidth, totalHeight }}, products, transparent } = values;

	const newValues = {
		...values,
		products: fromProductOptions(products),
		dpi: 150
	};

	//---- dimension ----//
	// Note that dimension doesn't contain lostBorder.
	// It's normal, we don't store lostBorder in the template. It's only a rectangle with layers in it.
	newValues.width = totalWidth;
	newValues.height = totalHeight;
	delete newValues.dimension;

	//---- transparent background ----//
	if (transparent) {
		newValues.backgroundColor = 'transparent';
	}
	delete newValues.transparent;

	return newValues;
}


//---------------------------------------//
//------------- Products ----------------//
//---------------------------------------//
/**
 * @param products the products as stored in the db as Template.products
 * @returns {Array}
 */
const toProductOptions = products => {
	if (!Array.isArray(products)) {
		return [];
	}

	return products.map(item => ({
		label: item.name || item.get("name"),
		value: item.id,
	}));
};

/**
 * format products values before save
 * @param products
 * @returns {Array}
 */
export const fromProductOptions = products => {
	if (!Array.isArray(products)) {
		return [];
	}
	return products.map(product => ({
		id: product.value,
		name: product.label,
	}));
};