import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
	container: (styles, state) => {
		const {selectProps: { width, fullWidth } } = state;

		return {
			...styles,
			width: fullWidth ? '100%' : width,
			marginTop: 10,
		}
	},
	control: (styles, state) => {
		const {selectProps: { width, fullWidth }, isFocused } = state;
		return {
			...styles,
			width: fullWidth ? '100%' : width,
			outline: isFocused ? '1px solid #fff' : 'none',
			border:  'none',
			borderRadius: 0,
			borderBottom: '1px solid #949494',
			':hover': {
				outline: '1px solid #fff',
				borderBottom: '1px solid #949494',
			}
		}
	},
	menu: () => ({
		zIndex: 100,
	}),
	option: (styles, state) => ({
		...styles,
		color: state.isSelected ? '#fff' : 'blue',
		padding: '14px 20px',
    	width: '100%',
	}),
	singleValue: (styles, state) => {
	  const opacity = state.isDisabled ? 0.5 : 1;
	  const transition = 'opacity 300ms';
  
	  return { ...styles, opacity, transition };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: '3px 5px 3px 8px',
      fontSize: 18,
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    marginLeft: 8,
    ':hover': {
      opacity: 0.5,
      cursor: 'pointer',
    },
  }),
}

const ReduxFormReactSelect = (props) => {
	// props
	const { input, options, width, fullWidth, isMulti, isClearable, meta: { touched, error, warning }, styles, ...otherProps } = props;

	return (
		<>
	  <Select 
			{...input}
			onChange={value => input.onChange(value)}
			onBlur={() => input.onBlur ? input.onBlur(input.value): {}}
			options={options}
			styles={styles || customStyles}
			width={width}
			fullWidth={fullWidth}
			isMulti={isMulti}
			isClearable={isClearable}
			{...otherProps}
	  />
		{touched &&
        ((error && <div style={{color:'#f44336',fontSize:'0.75rem',marginTop:10}}>{error}</div>) ||
          (warning && <div style={{color:'#f44336',fontSize:'0.75rem',marginTop:10}}>{warning}</div>))}
		</>
	)
}

ReduxFormReactSelect.propTypes = {
	input: PropTypes.any,
	options: PropTypes.any,
	width: PropTypes.number,
	fullWidth: PropTypes.bool,
	isMulti: PropTypes.bool,
	isClearable: PropTypes.bool,
	styles: PropTypes.object
};

export default ReduxFormReactSelect;