import React from 'react';
import { useDispatch } from 'react-redux';

import { Box, makeStyles, Typography, Link } from '@material-ui/core';
import {
	ORDER_ID_TEST,
	PRODUCT_ID_TEST, PRODUCT_NAME_TEST,
	createImageTest, testUploadImage, goToBackgroundImagePreview
} from '../../actions/test';
import { downloadMontagePdf } from '../../actions/montages';

const useStyles = makeStyles({
	link: {
		color: '#0097a7',
		cursor: 'pointer'
	},
});

const LinksTest = () => {

	// style
	const classes = useStyles();

	const dispatch = useDispatch();

	const _createImageTest = async () => {
		await dispatch(createImageTest());
	}

	const handlePdfTest = () => {
		dispatch(downloadMontagePdf({
			orderId: ORDER_ID_TEST, 
			productId: PRODUCT_ID_TEST, 
			name: PRODUCT_NAME_TEST
		}));
	}

	const handleUploadImage = async () => {
		await dispatch(testUploadImage());
	}

	const handleImageUrlEndpointForBackground = async () => {
		goToBackgroundImagePreview();
	}
	return(
		<Box>
			<Typography variant='h6' >
				<Link onClick={_createImageTest} target="_blank" className={classes.link}>
					Création image
				</Link>
			</Typography>
			<Typography variant='h6'>
				<Link  onClick={handlePdfTest} className={classes.link}>
					Création pdf
				</Link>
			</Typography>
			<Typography variant='h6'>
				<Link onClick={handleUploadImage} className={classes.link}>
					Endpoint image
				</Link>
			</Typography>
			<Typography variant='h6'>
				<Link onClick={handleImageUrlEndpointForBackground} className={classes.link}>
					Endpoint image for background
				</Link>
			</Typography>
		</Box>
	)
}

export default LinksTest;