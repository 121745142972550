import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import FormField from '../../components/form/FormField';
import DropFileUploader from '../../components/form/DropFileUploader';
import ReduxFormTextEditor from '../../components/reduxFormMUIWrapper/ReduxFormTextEditor';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch center',
  },
  selectField: {
    width: 160,
  },
});

const PromoBannerForm = (props) => {

  // props
  const { handleSubmit } = props;
  
  // styles
  const classes = useStyles(props);

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormField
        label='Title'
        name='title'
        fullWidth
      />
      <FormField
        label='Description'
        name='desc'
        fullWidth
      />
      <FormField
        label='Longue Description'
        name='descLong'
        component={ReduxFormTextEditor}
        fullWidth
      />
      <FormField
        name='image'
        label="Image"
        component={DropFileUploader}
        fullWidth
      />
      <FormField
        name='tag'
        label="Tag"
        fullWidth
      />
    
      <input type='submit' />
    </form>
  );
};

PromoBannerForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
export default reduxForm({
  form: 'promoBannerForm',
})(PromoBannerForm);
