import React from 'react';

import OrdersUpdateContent from './OrdersUpdateContent';
import CustomCard from '../../components/CustomCard';
const OrdersUpdate = () => {

	return (
		<CustomCard
			title='Récuperation commandes'
			content={<OrdersUpdateContent />}
			withActionButtons={false}
			fullScreen
		/>
	)
}

export default OrdersUpdate;