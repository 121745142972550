import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { createStyles, fade, FormControl, InputBase } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const BootstrapInput = withStyles((theme) =>
  createStyles({
    input: {
      marginRight: theme.spacing(8),
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

const FilterForm = (props) => {

  // props
  const { filterByPaid, filterByNumber } = props;

  // state
  const [state, setState] = useState({
    paid: false,
    number: '',
  });

  // input change
  const handleChange = (event) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const value = event.target.value;

    switch (name) {
      case 'number':
        setState({ ...state, [name]: value });
        filterByNumber(value);
      break;
      default:
        filterByPaid(checked);
        setState({ ...state, [name]: checked });
      break;
    }
  };

  return (
    <FormGroup row>
      <FormControl>
        <BootstrapInput
          value={state.number} 
          onChange={handleChange}
          id="bootstrap-input"
          name="number" 
          placeholder="Numéro de commande" 
        />
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={state.paid}
            onChange={handleChange}
            name="paid"
            color="primary"
          />
        }
        label="Payé"
      />
    </FormGroup>
  );
}

FilterForm.propTypes = {
	filterByPaid: PropTypes.func, 
  filterByNumber: PropTypes.func,
};

export default FilterForm;