import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { makeStyles } from '@material-ui/core/styles';

import FormField from '../../components/form/FormField';
import ReduxFormReactSelect from '../../components/reduxFormMUIWrapper/ReduxFormReactSelect';

import { formatParseObjSelectOptions } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { getTemplates } from '../../reducers/templates';
import { loadTemplatesThunk } from '../../actions/templates';

const useStyles = makeStyles({
  root: {
    composes: 'flexColumn stretch center',
  },
  selectField: {
    width: 160,
  },
});

const ThemeForm = (props) => {
  // props
  const { handleSubmit } = props;
  
  const templates = useSelector(getTemplates);

  const dispatch = useDispatch();

  // load the templates
  useEffect(() => {
    dispatch(loadTemplatesThunk());
  }, [dispatch]);
  // styles
  const classes = useStyles(props);

  return (
    <form className={classes.root} onSubmit={handleSubmit}>
      <FormField label='Nom' name='name' fullWidth />
      <FormField
        label='Templates'
        name='templates'
        fullWidth
        isMulti
        component={ReduxFormReactSelect}
        options={formatParseObjSelectOptions(templates)}
      />
      <input type='submit' />
    </form>
  );
};

ThemeForm.propTypes = {
  onSubmit: PropTypes.func,
  resetForm: PropTypes.bool,
};
export default reduxForm({
  form: 'themeForm',
  enableReinitialize : true
})(ThemeForm);
